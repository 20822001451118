import React, { useState, useEffect, useMemo, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Drawer, Typography, brandColors, Flex, IconShop, Button, grid } from '@aceandtate/ds';
import { ServicesContext } from 'services/context';
import { useGetStoreStockAvailability, StoreWithStockAvailabilityProps } from 'services/storeService';
import LoaderIcon from 'components/Loaders/LoaderIcon';
import { ProductVariant, ProductType } from 'types/torii';
import { getPreferredLocalStoreCode } from 'utils/helpers/preferredStore';
import messages from './messages';
import * as Styles from './styles';
import { StoresList, StoreDetails, StockIndicator } from './partials';
import { trackPageEvent } from 'tracking/helpers';

export enum DRAWER_STATE {
  STORES_LIST = 'stores_list',
  STORE_DETAILS = 'store_details'
}

export type StoreStock = {
  stockCount: number;
  storeCode: string;
};

export type StoreStockProps = {
  productType: ProductType;
  variant: ProductVariant;
};

export default function StoreStockAvailability(props: StoreStockProps) {
  const { variant, productType } = props;
  const {
    locale: { country }
  } = useContext(ServicesContext);
  const storesWithStockAvailabilityData = useGetStoreStockAvailability({
    productType: productType,
    sku: variant.sku
  });

  const [drawerState, setDrawerState] = useState(null);
  const [currentStore, setCurrentStore] = useState<StoreWithStockAvailabilityProps>(null);
  const [drawerIndex, setDrawerIndex] = useState(0);
  const [backButtonVisible, setBackButtonVisible] = useState(false);

  const preferredStoreCode = getPreferredLocalStoreCode(country);

  const preferredStore = useMemo(
    () =>
      storesWithStockAvailabilityData &&
      storesWithStockAvailabilityData.find(
        (store: StoreWithStockAvailabilityProps) => store.universal_store_id === preferredStoreCode
      ),
    [preferredStoreCode, storesWithStockAvailabilityData]
  );

  useEffect(() => {
    const storedPreferredStore = storesWithStockAvailabilityData?.find(
      (store: StoreWithStockAvailabilityProps) => store.universal_store_id === preferredStoreCode
    );
    if (storesWithStockAvailabilityData && preferredStoreCode) {
      setCurrentStore(storedPreferredStore);
    }

    if (!storedPreferredStore) {
      setCurrentStore(null);
    }
  }, [storesWithStockAvailabilityData, preferredStoreCode]);

  useEffect(() => {
    if (preferredStoreCode) {
      trackPageEvent('preferredStoreKnown');
    } else {
      trackPageEvent('preferredStoreUnknown');
    }
  }, [preferredStoreCode]);

  const { isAvailableOnline: hasStockAvailability, isTradeInStoreOnly } = variant.availability; // isAvailableOnline actually means that we have stock somewhere either in the warehouse or in stores and is able to be sold online, if isTradeInStoreOnly is false.
  const isAvailableOnline = hasStockAvailability && !isTradeInStoreOnly;

  const isAvailableInStores = useMemo(
    () => storesWithStockAvailabilityData?.some((store: StoreWithStockAvailabilityProps) => store.stockCount > 0),
    [storesWithStockAvailabilityData]
  );

  function handleStockIndicatorClick() {
    setDrawerState(DRAWER_STATE.STORE_DETAILS);
    setCurrentStore(preferredStore);
    setDrawerIndex(1);
    setBackButtonVisible(false);
  }

  function handleChangePreferredStore() {
    setDrawerState(DRAWER_STATE.STORES_LIST);
    setDrawerIndex(0);
    setBackButtonVisible(false);
  }

  return (
    <Flex flexDirection='column'>
      <Flex alignItems='self-start' gap={grid[4]}>
        <Typography as='span'>
          <IconShop color={brandColors.accent} fontSize={20} style={{ verticalAlign: 'text-top' }} />
        </Typography>
        {!isAvailableInStores ? (
          <Typography as='span'>
            <FormattedMessage {...messages.storeAvailability} />
          </Typography>
        ) : (
          <Button
            onClick={() => handleChangePreferredStore()}
            variant='link'
            data-testid='storeStock.changeButton'
            style={{ textAlign: 'left' }}
          >
            <Typography as='span'>
              {preferredStore ? (
                <FormattedMessage {...messages.checkOtherStoresAvailability} />
              ) : (
                <FormattedMessage {...messages.checkInStoreAvailability} />
              )}
            </Typography>
          </Button>
        )}
      </Flex>
      <Styles.ButtonRow>
        <>
          {!storesWithStockAvailabilityData ? (
            <LoaderIcon size={28} />
          ) : (
            (!isAvailableInStores || (!isAvailableInStores && !isAvailableOnline)) && (
              <Flex alignItems='center' gap={grid[8]}>
                <Styles.StockIndicatorIcon size='8px' />
                <Typography
                  variant='bodyS'
                  style={{ display: 'block' }}
                  data-testid={
                    !isAvailableInStores && !isAvailableOnline
                      ? 'storeStock.outOfStock'
                      : 'storeStock.onlyAvailableOnline'
                  }
                >
                  {!isAvailableInStores && !isAvailableOnline ? (
                    <FormattedMessage {...messages.outOfStock} />
                  ) : (
                    <FormattedMessage {...messages.onlyAvailableOnline} />
                  )}
                </Typography>
              </Flex>
            )
          )}
          {isAvailableInStores && preferredStore && hasStockAvailability && (
            <Typography
              as='button'
              variant='bodyS'
              onClick={() => handleStockIndicatorClick()}
              data-testid={`storeStock.stockIndicator.${preferredStore?.universal_store_id}`}
              textAlign='left'
              style={{
                background: 'none',
                border: 'none',
                color: brandColors.dark,
                cursor: 'pointer',
                padding: 0
              }}
            >
              <StockIndicator
                isAvailable={preferredStore.stockCount > 0}
                streetName={preferredStore.street}
                storeCode={preferredStore.universal_store_id}
                direction='reverse'
              />
            </Typography>
          )}
        </>
      </Styles.ButtonRow>

      <Drawer open={!!drawerState} position='right' onClose={() => setDrawerState(null)}>
        <Styles.SlideAnimationWrapper>
          <Styles.SlideAnimationInnerScroll stepIndex={drawerIndex}>
            <Styles.Slide>
              <StoresList
                storesWithStockAvailability={storesWithStockAvailabilityData}
                setCurrentStore={setCurrentStore}
                preferredStore={preferredStore}
                setDrawerState={setDrawerState}
                drawerIndex={drawerIndex}
                setDrawerIndex={setDrawerIndex}
                setBackButtonVisible={setBackButtonVisible}
                backButtonVisible={backButtonVisible}
                onClose={() => {
                  setDrawerState(null);
                  setDrawerIndex(null);
                }}
              />
            </Styles.Slide>
            <Styles.Slide>
              <StoreDetails
                productType={productType}
                currentStore={currentStore}
                drawerIndex={drawerIndex}
                setDrawerIndex={setDrawerIndex}
                backButtonVisible={backButtonVisible}
                setBackButtonVisible={setBackButtonVisible}
                onClose={() => setDrawerState(null)}
              />
            </Styles.Slide>
          </Styles.SlideAnimationInnerScroll>
        </Styles.SlideAnimationWrapper>
      </Drawer>
    </Flex>
  );
}
