import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useRouter } from 'next/router';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Sentry from '@sentry/nextjs';
import { Cell, grid } from '@aceandtate/ds';
import useAddToCart from 'utils/hooks/useAddToCart';
import useAddToHtoCart from 'utils/hooks/useAddToHtoCart';
import { replaceEntities } from 'utils/helpers/strings';
import getProductMetaContent from 'utils/helpers/getProductMetaContent';
import { getProductImages } from 'utils/product/productHelpers';
import CustomHead from 'blocks/CustomHead';
import { useMultipleProducts, useProductWithAvailability } from 'services/productsService';
import { ProductInfo, RecommendedProducts, BenefitsBlockDesktop, BenefitsBlockMobile, ProductTag } from './partials';
import { SWRCacheKeys } from 'config/cache';
import { GENDER } from 'globalConstants';
import { navHeightDesktop } from 'styles';
import * as Styles from './styles';
import type { ProductVariant, CliponProduct, Product, ContactLensProduct } from 'types/torii';
import type { ColorProps } from 'types/colorSwatches';
import type { ProductImage } from 'types/images';
import { trackArtificialPageView } from 'tracking/helpers';
import { pdpEvents } from 'tracking';
import DraftModeProductTag from 'components/DraftModeNotice/DraftModeProductTag';
import { captureException } from '@sentry/nextjs';
import taxonsFn from 'utils/product/productTaxonomies';
import MobileCarousel from './partials/ImageCarousel/MobileCarousel';
import DesktopCarousel from './partials/ImageCarousel/DesktopCarousel';
import { ConfiguratorProvider } from 'components/Configurators/FrameConfigurator/configuratorStore';
import OOSModal from 'components/OOSModal';
import PdpBlockRenderer from 'blocks/Contentful/PdpBlockRenderer';
import { ProductDetailProps } from 'types/path';
import giftcardMessages from 'messages/giftcard';
import productMessages from './messages';

import ProductSpecificationsTable from './partials/ProductSpecificationsTable';

export default function ProductDetail(props: ProductDetailProps) {
  const { webStore, product: initialProduct, contentful } = props;

  const productQuery = useProductWithAvailability(initialProduct.currentVariant.sku, {
    fallbackData: initialProduct,
    revalidateOnFocus: false,
    revalidateOnMount: true
  });
  const product = productQuery.data;
  const intl = useIntl();
  const addToCart = useAddToCart();
  const addToHtoCart = useAddToHtoCart();
  const router = useRouter();
  const [currentVariant, setCurrentVariant] = useState<Product['currentVariant']>(product.currentVariant);
  const [isLoading, setIsLoading] = useState(false);
  const [imageHeadGender, setImageHeadGender] = useState<string>(GENDER.WOMEN);
  const [showOOSModal, setShowOOSModal] = useState<boolean>(false);

  const structuredData = useMemo(() => {
    try {
      return JSON.stringify({
        '@context': 'https://schema.org/',
        '@type': 'Product',
        brand: {
          '@type': 'Brand',
          name: 'Ace & Tate'
        },
        color: currentVariant.displayAttributes.color || undefined,
        description: currentVariant.displayAttributes.description || undefined,
        image: currentVariant.images.flat?.url || currentVariant.images.front?.url || undefined,
        name: `${currentVariant.name} ${currentVariant.displayAttributes.color}`,
        offers: {
          '@type': 'Offer',
          availability: currentVariant.availability.isAvailableOnline
            ? 'https://schema.org/InStock'
            : 'https://schema.org/OutOfStock',
          itemCondition: 'https://schema.org/NewCondition',
          price: currentVariant.price.value,
          priceCurrency: currentVariant.price.currency,
          url: `https://www.aceandtate.com/${router.locale}/${currentVariant.sku}`
        },
        sku: currentVariant.sku
      });
    } catch (error) {
      captureException(error, { extra: { currentVariant, message: 'Error generating structured product page data' } });
      return null;
    }
  }, [currentVariant, router.locale]);

  const recommendedProductsQuery = useMultipleProducts(
    currentVariant.relatedProducts?.recommended?.skus,
    SWRCacheKeys.recommendedProducts
  );
  const recommendedCliponsQuery = useMultipleProducts(
    currentVariant.relatedProducts?.clipOns?.skus,
    SWRCacheKeys.recommendedClipons
  );
  const { metaTitle, metaDescription } = useMemo(
    () => getProductMetaContent(product, currentVariant, intl),
    [currentVariant, product]
  );

  const currentCFContent = contentful.variants[currentVariant.sku];

  const recommendedProducts = recommendedProductsQuery.data?.filter(
    recommendedProduct => recommendedProduct.currentVariant.availability.isAvailableOnline
  );
  const recommendedClipons = recommendedCliponsQuery.data?.sort(
    (a, b) => a.currentVariant.upsellPosition - b.currentVariant.upsellPosition
  ) as CliponProduct[];

  const isSkiGoggles = taxonsFn.isSkiGoggles(currentVariant);
  const isSportsGlasses = taxonsFn.isSportsGlasses(currentVariant);

  const colorPickerRef = useRef(null);
  const ctaRef = useRef<HTMLInputElement>(null);

  /* pdp section navigation */
  const mobileSpecificationsRef = useRef(null);
  const desktopSpecificationsRef = useRef(null);

  const mobileBenefitsBlockRef = useRef(null);
  const desktopBenefitsBlockRef = useRef(null);

  const mobileRecommendedProductsRef = useRef(null);
  const desktopRecommendedProductsRef = useRef(null);

  const desktopNavigation = useMemo(() => {
    return [
      {
        refCurrent: desktopSpecificationsRef.current,
        title: <FormattedMessage {...productMessages.productSpecifications} />
      },
      {
        refCurrent: desktopBenefitsBlockRef.current,
        title: <FormattedMessage {...productMessages.productBenefits} />
      },
      {
        refCurrent: desktopRecommendedProductsRef.current,
        title: <FormattedMessage {...productMessages.productRecommendations} />
      }
    ].filter(x => !!x.refCurrent);
  }, [desktopSpecificationsRef?.current, desktopBenefitsBlockRef?.current, desktopRecommendedProductsRef?.current]);

  const mobileNavigation = useMemo(() => {
    return [
      {
        refCurrent: mobileSpecificationsRef.current,
        title: <FormattedMessage {...productMessages.productSpecifications} />
      },
      {
        refCurrent: mobileBenefitsBlockRef.current,
        title: <FormattedMessage {...productMessages.productBenefits} />
      },
      {
        refCurrent: mobileRecommendedProductsRef.current,
        title: <FormattedMessage {...productMessages.productRecommendations} />
      }
    ].filter(x => !!x.refCurrent);
  }, [mobileSpecificationsRef?.current, mobileBenefitsBlockRef?.current, mobileRecommendedProductsRef?.current]);

  const handleScroll = ref => {
    ref &&
      window.scrollTo({
        top: ref.offsetTop,
        left: 0,
        behavior: 'smooth'
      });
  };
  /* end pdp section navigation */

  // updating current variant data after availability has updated
  useEffect(() => {
    if (currentVariant.sku === product.currentVariant.sku) {
      setCurrentVariant(product.currentVariant);
    }
  }, [product]);

  useEffect(() => {
    const gender = (typeof router.query?.gender === 'string' && router.query?.gender) || GENDER.WOMEN;
    setImageHeadGender(gender);
    if (currentVariant) {
      pdpEvents.productDetail(currentVariant, product);
    }
  }, []);

  const images = getProductImages({
    gender: imageHeadGender,
    intl,
    productType: product.productType,
    variant: currentVariant
  });

  const sliderImages = images.map((img: ProductImage) => ({
    alt: img.alt,
    isFrame: img.isFrame,
    src: img.src,
    title: img.title,
    type: img.type
  }));

  function handleChangeVariant(color: ColorProps) {
    const matchedVariant = (product.variants as ProductVariant[]).find(variant => variant.id === color.id);
    if (matchedVariant) {
      setCurrentVariant(matchedVariant);
      router.replace(color.href, undefined, { shallow: true });
      trackArtificialPageView(matchedVariant.displayAttributes.color, 'color');
      pdpEvents.productDetail(matchedVariant, product);
    } else {
      const err = new Error(
        `Error when changing variant from color picker on PDP. Product: ${product.name}, color: ${JSON.stringify(
          color
        )}`
      );
      Sentry.captureException(err);
    }
  }

  const attemptAddToHtoCart = async () => {
    if (!currentVariant.availability.isAvailableHTO) {
      setShowOOSModal(true);
      return;
    }
    setIsLoading(true);

    await addToHtoCart({ currentVariant });

    setIsLoading(false);
  };

  // continue to the cart step inside the configurator
  const shouldRedirectToCart = product.productType !== 'frame' || currentVariant.availability.isOffTheShelf;

  async function attemptAddToCart({
    additionalProducts = null,
    polarised = null,
    /** @deprecated */
    premiumLenses = null,
    user_entered_price = null,
    gift_card_personalisation = null,
    prescriptionType = null,
    lensColor = null,
    lenses_production_type = null,
    lens_upgrade_type = null
  }): Promise<void> {
    if (!currentVariant.availability.isAvailableOnline) {
      setShowOOSModal(true);
      return;
    }

    await addToCart({
      additionalProducts,
      gift_card_personalisation,
      lenses_production_type,
      lens_upgrade_type,
      polarised,
      /** @deprecated */
      premiumLenses,
      prescriptionType,
      productType: product.productType,
      redirectToCart: shouldRedirectToCart,
      lensColor,
      user_entered_price,
      variant: currentVariant
    });
  }

  return (
    <>
      <CustomHead
        title={metaTitle}
        description={metaDescription}
        image={currentVariant.images.front?.url}
        noIndex={product.productType === 'service_item'}
      >
        {structuredData && product.productType !== 'service_item' && (
          <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: structuredData }} />
        )}
      </CustomHead>
      {showOOSModal && (
        <OOSModal isOpen={showOOSModal} onClose={() => setShowOOSModal(false)} sku={currentVariant.sku} />
      )}

      <Styles.PageGrid verticalAlign='top' data-cs-signal-enabled>
        <Cell columns={{ laptop: 12 / 8, mobile: 1 }}>
          <Styles.MobileWrapper>
            <Styles.MobileProductViewContainer>
              <ProductTag currentVariant={currentVariant} />
              <Styles.MobileImageCarouselWrapper>
                <MobileCarousel images={sliderImages} />
              </Styles.MobileImageCarouselWrapper>
            </Styles.MobileProductViewContainer>
            {currentVariant.draftMode && <DraftModeProductTag />}
            <ConfiguratorProvider>
              <ProductInfo
                ctaRef={ctaRef}
                colorPickerRef={colorPickerRef}
                isLoading={isLoading}
                product={product as Exclude<Product, ContactLensProduct>}
                currentVariant={currentVariant as ProductVariant}
                onChangeVariant={handleChangeVariant}
                addToCart={attemptAddToCart}
                addToHtoCart={attemptAddToHtoCart}
                webStore={webStore}
                recommendedClipons={recommendedClipons}
              />
            </ConfiguratorProvider>
            {/* only show navigation if there is more than one item in navigation */}
            {mobileNavigation.length > 1 && (
              <Styles.SectionNavigationMobile>
                {mobileNavigation.map((navItem, index) => {
                  return (
                    <Styles.SectionNavigationButton
                      key={index}
                      onClick={() => {
                        handleScroll(navItem.refCurrent);
                      }}
                    >
                      {navItem.title}
                    </Styles.SectionNavigationButton>
                  );
                })}
              </Styles.SectionNavigationMobile>
            )}
            <Styles.ProductDescription variant='h4' forwardedAs='p' serif data-testid='pdp-product-description'>
              {currentVariant.sku === 'giftcard-variable-price' ? (
                <FormattedMessage {...giftcardMessages.description} />
              ) : (
                <> {replaceEntities(currentVariant.displayAttributes.description)}</>
              )}
            </Styles.ProductDescription>
            <ProductSpecificationsTable
              currentVariant={currentVariant as ProductVariant}
              product={product as Exclude<Product, ContactLensProduct>}
              specificationsRef={mobileSpecificationsRef}
            />
            {isSkiGoggles && <BenefitsBlockMobile isSkiGoggles benefitsBlockRef={mobileBenefitsBlockRef} />}
            {isSportsGlasses && <BenefitsBlockMobile isSportsGlasses benefitsBlockRef={mobileBenefitsBlockRef} />}
            <PdpBlockRenderer content={currentCFContent} />
            {product.productType === 'frame' && (
              <RecommendedProducts
                recommendedProductsRef={mobileRecommendedProductsRef}
                parentSku={currentVariant.sku}
                products={recommendedProducts}
                gender={imageHeadGender}
              />
            )}
          </Styles.MobileWrapper>
          <Styles.DesktopWrapper>
            <Styles.DesktopProductViewContainer>
              <ProductTag currentVariant={currentVariant} />
              <DesktopCarousel images={sliderImages} />
            </Styles.DesktopProductViewContainer>
            {/* only show navigation if there is more than one item in navigation */}
            {desktopNavigation.length > 1 && (
              <Styles.SectionNavigationDesktop>
                {desktopNavigation.map((navItem, index) => {
                  return (
                    <Styles.SectionNavigationButton
                      key={index}
                      onClick={() => {
                        handleScroll(navItem.refCurrent);
                      }}
                    >
                      {navItem.title}
                    </Styles.SectionNavigationButton>
                  );
                })}
              </Styles.SectionNavigationDesktop>
            )}
            <Styles.FlexWrapper gap={grid[40]} flexDirection='column'>
              <Styles.ProductDescription
                variant='h4'
                forwardedAs='p'
                serif
                data-testid='pdp-product-description-desktop'
              >
                {currentVariant.sku === 'giftcard-variable-price' ? (
                  <FormattedMessage {...giftcardMessages.description} />
                ) : (
                  <> {replaceEntities(currentVariant.displayAttributes.description)}</>
                )}
              </Styles.ProductDescription>

              <ProductSpecificationsTable
                currentVariant={currentVariant as ProductVariant}
                product={product as Exclude<Product, ContactLensProduct>}
                specificationsRef={desktopSpecificationsRef}
              />
              {isSkiGoggles && <BenefitsBlockDesktop isSkiGoggles benefitsBlockRef={desktopBenefitsBlockRef} />}
              {isSportsGlasses && <BenefitsBlockDesktop isSportsGlasses benefitsBlockRef={desktopBenefitsBlockRef} />}
              <PdpBlockRenderer content={currentCFContent} />
              {product.productType === 'frame' && (
                <RecommendedProducts
                  recommendedProductsRef={desktopRecommendedProductsRef}
                  parentSku={currentVariant.sku}
                  products={recommendedProducts}
                  gender={imageHeadGender}
                />
              )}
            </Styles.FlexWrapper>
          </Styles.DesktopWrapper>
        </Cell>
        <Styles.ProductInfoSidebar columns={{ laptop: 12 / 4, mobile: 1 }}>
          <Styles.StyledStickyBox offsetTop={navHeightDesktop}>
            {currentVariant.draftMode && (
              <Styles.PositionDraftModeTag>
                <DraftModeProductTag />
              </Styles.PositionDraftModeTag>
            )}
            <ConfiguratorProvider>
              <ProductInfo
                isLoading={isLoading}
                product={product as Exclude<Product, ContactLensProduct>}
                currentVariant={currentVariant as ProductVariant}
                onChangeVariant={handleChangeVariant}
                addToCart={attemptAddToCart}
                addToHtoCart={attemptAddToHtoCart}
                webStore={webStore}
                recommendedClipons={recommendedClipons}
              />
            </ConfiguratorProvider>
          </Styles.StyledStickyBox>
        </Styles.ProductInfoSidebar>
      </Styles.PageGrid>
    </>
  );
}
