import { Cell, Grid, breakpointRules, grid } from '@aceandtate/ds';
import styled from 'styled-components';

export const SidebarImageWrapper = styled.div`
  display: flex;
  height: 500px;
  justify-content: center;
  position: relative;
  width: 100%;

  @media ${breakpointRules.laptop} {
    position: sticky;
    top: ${grid[40]};
  }
`;

export const StyledGrid = styled(Grid)`
  align-items: stretch;

  @media ${breakpointRules.laptop} {
    flex-direction: row-reverse;
  }
`;

export const SidebarCell = styled(Cell)`
  flex: 1;
  display: flex;
  align-items: stretch;
`;

export const ImageSidebar = styled.aside<{ background: string }>`
  flex: 1;
  background-color: ${({ background }) => background};
  padding: ${grid[40]};
  margin: 0 calc(${grid.padding} * -1);

  @media ${breakpointRules.laptop} {
    margin: 0;
    margin-right: calc(${grid.padding} * -1);
  }
`;

export const ContentContainer = styled.div`
  padding: ${grid[40]} 0 ${grid[120]};

  @media ${breakpointRules.laptop} {
    padding: ${grid[40]} ${grid[40]} ${grid[120]} 0;
  }
`;

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BannerContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 10em;

  @media ${breakpointRules.tablet} {
    height: 15em;
  }
`;
