import React, { useState } from 'react';
import Image from 'next/image';
import Swiper from 'components/Swiper/Swiper';
import { Flex, breakpointRules, grid } from '@aceandtate/ds';
import DesktopZoom from '../ImageZoom/DesktopZoom';
import { getOptimizedProductUrl } from 'utils/cloudinary';
import { ProductImage } from 'types/images';

export default function DesktopCarousel({
  images
}: {
  images: Pick<ProductImage, 'alt' | 'isFrame' | 'src' | 'title' | 'type'>[];
}) {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isInZoomView, setIsInZoomView] = useState(false);

  function handleClick(i: number) {
    if (i === activeSlide) {
      setIsInZoomView(true);
    }
  }
  return (
    <>
      <Swiper
        slideToClickedSlide
        loop={images.length > 3}
        slidesPerView={1.6}
        spaceBetween={24}
        onSlideChangeTransitionEnd={swiper => {
          setActiveSlide(swiper.realIndex);
        }}
        centerInsufficientSlides
      >
        {images.length > 1 && <SlideNavigation />}
        {images.map((img, i) => (
          <Swiper.SwiperSlide key={i} style={{ cursor: 'grab', userSelect: 'none' }} onClick={() => handleClick(i)}>
            <div style={{ paddingBottom: '100%' }}>
              <Image
                src={img.src}
                alt={img.alt}
                loading='lazy'
                loader={({ src, width, quality }) =>
                  getOptimizedProductUrl(src, {
                    height: width,
                    isFrame: img.isFrame,
                    quality,
                    type: img.type,
                    width
                  })
                }
                fill
                sizes={`${breakpointRules.laptop} 50vw, 10px`}
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center'
                }}
              />
            </div>
          </Swiper.SwiperSlide>
        ))}
      </Swiper>
      <DesktopZoom
        images={images}
        setActiveIndex={setActiveSlide}
        activeIndex={activeSlide}
        isInZoomView={isInZoomView}
        setIsInZoomView={setIsInZoomView}
      />
    </>
  );
}

export function SlideNavigation() {
  return (
    <Flex
      style={{ alignItems: 'center', bottom: '0', gap: grid[24], padding: grid[24], position: 'absolute', zIndex: 1 }}
    >
      <Flex gap={grid[16]}>
        <Swiper.PreviousButton />
        <Swiper.NextButton />
      </Flex>
      <Swiper.NavigationDots />
    </Flex>
  );
}
