import React from 'react';
import V2Contentful from 'blocks/V2Contentful';
import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
import { BasePageFragment, UrlRouteCollectionQuery } from 'services/generated/graphql/graphql';
import { EntryTypeQuery } from 'services/contentful/graphql';
import CustomHead from 'blocks/CustomHead';
import { getMetaImageUrl, makeAlternateHrefs } from 'services/contentful/helpers';
import { CommonPageProps } from 'types/path';

type V2LandingPageProps = {
  draftMode: boolean;
  urlRouteCollection: UrlRouteCollectionQuery;
} & CommonPageProps;

export default function V2LandingPage(props: V2LandingPageProps) {
  const data = useContentfulLiveUpdates(props.urlRouteCollection, { query: EntryTypeQuery });
  const urlRouteData = data.urlRouteCollection.items[0];
  const pageData = (urlRouteData.countryOverrideReferencesCollection.items[0] ||
    urlRouteData.reference) as BasePageFragment;

  return (
    <>
      <CustomHead
        image={pageData.metaImage ? getMetaImageUrl(pageData.metaImage) : null}
        description={pageData.metaDescription}
        title={pageData.metaTitle}
        noIndex={urlRouteData.noindex}
        alternatePaths={makeAlternateHrefs(urlRouteData)}
      />
      <V2Contentful blocks={pageData.blocksCollection} draftMode={props.draftMode} />
    </>
  );
}
