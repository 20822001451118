/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  fragment urlRoute on UrlRoute {\n    sys {\n      id\n    }\n    path\n  }\n": types.UrlRouteFragmentDoc,
    "\n  fragment asset on Asset {\n    sys {\n      id\n    }\n    title\n    description\n    width\n    height\n    contentType\n    url\n    size\n    fileName\n  }\n": types.AssetFragmentDoc,
    "\n  fragment text on ComponentText {\n    __typename\n    internalName\n    sys {\n      id\n    }\n    editor\n    viewport\n    referencesCollection(limit: 3) {\n      items {\n        sys {\n          id\n        }\n        ...urlRoute\n      }\n    }\n  }\n": types.TextFragmentDoc,
    "\n  fragment image on ComponentImage {\n    sys {\n      id\n    }\n    name\n    laptop {\n      url\n    }\n    mobile {\n      url\n    }\n  }\n": types.ImageFragmentDoc,
    "\n  fragment faqQuestion on FaqQuestion {\n    sys {\n      id\n    }\n    slug\n    question\n    body {\n      json\n      links {\n        entries {\n          inline {\n            sys {\n              id\n            }\n          }\n          block {\n            sys {\n              id\n            }\n          }\n          hyperlink {\n            sys {\n              id\n            }\n            ... on UrlRoute {\n              path\n            }\n          }\n        }\n        assets {\n          hyperlink {\n            sys {\n              id\n            }\n          }\n          block {\n            sys {\n              id\n            }\n            url\n          }\n        }\n      }\n    }\n  }\n": types.FaqQuestionFragmentDoc,
    "\n  fragment faqBlock on LandingPageFaqBlockV2 {\n    sys {\n      id\n    }\n    __typename\n    title\n    questionsCollection(limit: 10) {\n      items {\n        ...faqQuestion\n      }\n    }\n  }\n": types.FaqBlockFragmentDoc,
    "\n  fragment contentBlockLevel3 on ContentBlockLevel3 {\n    __typename\n    sys {\n      id\n    }\n    internalName\n    layout\n    viewport\n    action\n    referencesCollection(limit: 3) {\n      items {\n        sys {\n          id\n        }\n        ...urlRoute\n        ...image\n      }\n    }\n    assetsCollection(limit: 3) {\n      items {\n        ...asset\n      }\n    }\n    blocksCollection(limit: 5) {\n      items {\n        ...text\n        ...image\n      }\n    }\n  }\n": types.ContentBlockLevel3FragmentDoc,
    "\n  fragment contentBlockLevel2 on ContentBlockLevel2 {\n    __typename\n    sys {\n      id\n    }\n    layout\n    internalName\n    viewport\n    action\n    referencesCollection(limit: 3) {\n      items {\n        sys {\n          id\n        }\n        ...urlRoute\n        ...image\n      }\n    }\n    assetsCollection(limit: 3) {\n      items {\n        ...asset\n      }\n    }\n    blocksCollection(limit: 5) {\n      items {\n        ...text\n        ...image\n        ...contentBlockLevel3\n      }\n    }\n  }\n": types.ContentBlockLevel2FragmentDoc,
    "\n  fragment contentBlockLevel1 on ContentBlockLevel1 {\n    __typename\n    sys {\n      id\n    }\n    layout\n    internalName\n    viewport\n    action\n    referencesCollection(limit: 3) {\n      items {\n        sys {\n          id\n        }\n        ...urlRoute\n        ...image\n      }\n    }\n    assetsCollection(limit: 3) {\n      items {\n        ...asset\n      }\n    }\n    blocksCollection(limit: 5) {\n      items {\n        ...text\n        ...image\n        ...faqBlock\n        ...contentBlockLevel2\n      }\n    }\n  }\n": types.ContentBlockLevel1FragmentDoc,
    "\n  fragment basePage on BasePage {\n    __typename\n    sys {\n      id\n    }\n    contentfulMetadata {\n      tags {\n        id\n        name\n      }\n    }\n    internalName\n    metaTitle\n    metaDescription\n    metaImage {\n      ...asset\n    }\n    blocksCollection(limit: 10) {\n      items {\n        ...contentBlockLevel1\n      }\n    }\n  }\n": types.BasePageFragmentDoc,
    "\n  query GetEntryTags($entryId: String!, $isPreview: Boolean) {\n    entryCollection(preview: $isPreview, where: { sys: { id: $entryId } }, limit: 1) {\n      items {\n        sys {\n          id\n        }\n        contentfulMetadata {\n          tags {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n": types.GetEntryTagsDocument,
    "\n  fragment basePageTags on BasePage {\n    sys {\n      id\n    }\n    contentfulMetadata {\n      tags {\n        id\n        name\n      }\n    }\n  }\n": types.BasePageTagsFragmentDoc,
    "\n  query UrlRouteCollectionBasePageInstances($entryId: String!, $isPreview: Boolean) {\n    urlRouteCollection(preview: $isPreview, where: { sys: { id: $entryId } }) {\n      items {\n        __typename\n        sys {\n          id\n        }\n        reference {\n          ...basePageTags\n        }\n        countryOverrideReferencesCollection {\n          items {\n            ...basePageTags\n          }\n        }\n      }\n    }\n  }\n": types.UrlRouteCollectionBasePageInstancesDocument,
    "\n  query UrlRouteCollection($isPreview: Boolean, $path: [String], $lang: String, $country: [String]) {\n    urlRouteCollection(\n      preview: $isPreview\n      locale: $lang\n      where: {\n        AND: [\n          { OR: [{ reference_exists: true }, { countryOverrideReferencesCollection_exists: true }] }\n          { intlPaths_contains_some: $path }\n        ]\n      }\n      limit: 1\n    ) {\n      items {\n        __typename\n        sys {\n          id\n        }\n        path\n        intlPaths\n        noindex\n        redirectType\n        reference {\n          __typename\n          ...basePage\n        }\n        countryOverrideReferencesCollection(\n          limit: 1\n          where: { contentfulMetadata: { tags: { id_contains_some: $country } } }\n        ) {\n          items {\n            ...basePage\n          }\n        }\n      }\n    }\n  }\n": types.UrlRouteCollectionDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment urlRoute on UrlRoute {\n    sys {\n      id\n    }\n    path\n  }\n"): (typeof documents)["\n  fragment urlRoute on UrlRoute {\n    sys {\n      id\n    }\n    path\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment asset on Asset {\n    sys {\n      id\n    }\n    title\n    description\n    width\n    height\n    contentType\n    url\n    size\n    fileName\n  }\n"): (typeof documents)["\n  fragment asset on Asset {\n    sys {\n      id\n    }\n    title\n    description\n    width\n    height\n    contentType\n    url\n    size\n    fileName\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment text on ComponentText {\n    __typename\n    internalName\n    sys {\n      id\n    }\n    editor\n    viewport\n    referencesCollection(limit: 3) {\n      items {\n        sys {\n          id\n        }\n        ...urlRoute\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment text on ComponentText {\n    __typename\n    internalName\n    sys {\n      id\n    }\n    editor\n    viewport\n    referencesCollection(limit: 3) {\n      items {\n        sys {\n          id\n        }\n        ...urlRoute\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment image on ComponentImage {\n    sys {\n      id\n    }\n    name\n    laptop {\n      url\n    }\n    mobile {\n      url\n    }\n  }\n"): (typeof documents)["\n  fragment image on ComponentImage {\n    sys {\n      id\n    }\n    name\n    laptop {\n      url\n    }\n    mobile {\n      url\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment faqQuestion on FaqQuestion {\n    sys {\n      id\n    }\n    slug\n    question\n    body {\n      json\n      links {\n        entries {\n          inline {\n            sys {\n              id\n            }\n          }\n          block {\n            sys {\n              id\n            }\n          }\n          hyperlink {\n            sys {\n              id\n            }\n            ... on UrlRoute {\n              path\n            }\n          }\n        }\n        assets {\n          hyperlink {\n            sys {\n              id\n            }\n          }\n          block {\n            sys {\n              id\n            }\n            url\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment faqQuestion on FaqQuestion {\n    sys {\n      id\n    }\n    slug\n    question\n    body {\n      json\n      links {\n        entries {\n          inline {\n            sys {\n              id\n            }\n          }\n          block {\n            sys {\n              id\n            }\n          }\n          hyperlink {\n            sys {\n              id\n            }\n            ... on UrlRoute {\n              path\n            }\n          }\n        }\n        assets {\n          hyperlink {\n            sys {\n              id\n            }\n          }\n          block {\n            sys {\n              id\n            }\n            url\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment faqBlock on LandingPageFaqBlockV2 {\n    sys {\n      id\n    }\n    __typename\n    title\n    questionsCollection(limit: 10) {\n      items {\n        ...faqQuestion\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment faqBlock on LandingPageFaqBlockV2 {\n    sys {\n      id\n    }\n    __typename\n    title\n    questionsCollection(limit: 10) {\n      items {\n        ...faqQuestion\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment contentBlockLevel3 on ContentBlockLevel3 {\n    __typename\n    sys {\n      id\n    }\n    internalName\n    layout\n    viewport\n    action\n    referencesCollection(limit: 3) {\n      items {\n        sys {\n          id\n        }\n        ...urlRoute\n        ...image\n      }\n    }\n    assetsCollection(limit: 3) {\n      items {\n        ...asset\n      }\n    }\n    blocksCollection(limit: 5) {\n      items {\n        ...text\n        ...image\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment contentBlockLevel3 on ContentBlockLevel3 {\n    __typename\n    sys {\n      id\n    }\n    internalName\n    layout\n    viewport\n    action\n    referencesCollection(limit: 3) {\n      items {\n        sys {\n          id\n        }\n        ...urlRoute\n        ...image\n      }\n    }\n    assetsCollection(limit: 3) {\n      items {\n        ...asset\n      }\n    }\n    blocksCollection(limit: 5) {\n      items {\n        ...text\n        ...image\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment contentBlockLevel2 on ContentBlockLevel2 {\n    __typename\n    sys {\n      id\n    }\n    layout\n    internalName\n    viewport\n    action\n    referencesCollection(limit: 3) {\n      items {\n        sys {\n          id\n        }\n        ...urlRoute\n        ...image\n      }\n    }\n    assetsCollection(limit: 3) {\n      items {\n        ...asset\n      }\n    }\n    blocksCollection(limit: 5) {\n      items {\n        ...text\n        ...image\n        ...contentBlockLevel3\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment contentBlockLevel2 on ContentBlockLevel2 {\n    __typename\n    sys {\n      id\n    }\n    layout\n    internalName\n    viewport\n    action\n    referencesCollection(limit: 3) {\n      items {\n        sys {\n          id\n        }\n        ...urlRoute\n        ...image\n      }\n    }\n    assetsCollection(limit: 3) {\n      items {\n        ...asset\n      }\n    }\n    blocksCollection(limit: 5) {\n      items {\n        ...text\n        ...image\n        ...contentBlockLevel3\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment contentBlockLevel1 on ContentBlockLevel1 {\n    __typename\n    sys {\n      id\n    }\n    layout\n    internalName\n    viewport\n    action\n    referencesCollection(limit: 3) {\n      items {\n        sys {\n          id\n        }\n        ...urlRoute\n        ...image\n      }\n    }\n    assetsCollection(limit: 3) {\n      items {\n        ...asset\n      }\n    }\n    blocksCollection(limit: 5) {\n      items {\n        ...text\n        ...image\n        ...faqBlock\n        ...contentBlockLevel2\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment contentBlockLevel1 on ContentBlockLevel1 {\n    __typename\n    sys {\n      id\n    }\n    layout\n    internalName\n    viewport\n    action\n    referencesCollection(limit: 3) {\n      items {\n        sys {\n          id\n        }\n        ...urlRoute\n        ...image\n      }\n    }\n    assetsCollection(limit: 3) {\n      items {\n        ...asset\n      }\n    }\n    blocksCollection(limit: 5) {\n      items {\n        ...text\n        ...image\n        ...faqBlock\n        ...contentBlockLevel2\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment basePage on BasePage {\n    __typename\n    sys {\n      id\n    }\n    contentfulMetadata {\n      tags {\n        id\n        name\n      }\n    }\n    internalName\n    metaTitle\n    metaDescription\n    metaImage {\n      ...asset\n    }\n    blocksCollection(limit: 10) {\n      items {\n        ...contentBlockLevel1\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment basePage on BasePage {\n    __typename\n    sys {\n      id\n    }\n    contentfulMetadata {\n      tags {\n        id\n        name\n      }\n    }\n    internalName\n    metaTitle\n    metaDescription\n    metaImage {\n      ...asset\n    }\n    blocksCollection(limit: 10) {\n      items {\n        ...contentBlockLevel1\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetEntryTags($entryId: String!, $isPreview: Boolean) {\n    entryCollection(preview: $isPreview, where: { sys: { id: $entryId } }, limit: 1) {\n      items {\n        sys {\n          id\n        }\n        contentfulMetadata {\n          tags {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetEntryTags($entryId: String!, $isPreview: Boolean) {\n    entryCollection(preview: $isPreview, where: { sys: { id: $entryId } }, limit: 1) {\n      items {\n        sys {\n          id\n        }\n        contentfulMetadata {\n          tags {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment basePageTags on BasePage {\n    sys {\n      id\n    }\n    contentfulMetadata {\n      tags {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment basePageTags on BasePage {\n    sys {\n      id\n    }\n    contentfulMetadata {\n      tags {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UrlRouteCollectionBasePageInstances($entryId: String!, $isPreview: Boolean) {\n    urlRouteCollection(preview: $isPreview, where: { sys: { id: $entryId } }) {\n      items {\n        __typename\n        sys {\n          id\n        }\n        reference {\n          ...basePageTags\n        }\n        countryOverrideReferencesCollection {\n          items {\n            ...basePageTags\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query UrlRouteCollectionBasePageInstances($entryId: String!, $isPreview: Boolean) {\n    urlRouteCollection(preview: $isPreview, where: { sys: { id: $entryId } }) {\n      items {\n        __typename\n        sys {\n          id\n        }\n        reference {\n          ...basePageTags\n        }\n        countryOverrideReferencesCollection {\n          items {\n            ...basePageTags\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UrlRouteCollection($isPreview: Boolean, $path: [String], $lang: String, $country: [String]) {\n    urlRouteCollection(\n      preview: $isPreview\n      locale: $lang\n      where: {\n        AND: [\n          { OR: [{ reference_exists: true }, { countryOverrideReferencesCollection_exists: true }] }\n          { intlPaths_contains_some: $path }\n        ]\n      }\n      limit: 1\n    ) {\n      items {\n        __typename\n        sys {\n          id\n        }\n        path\n        intlPaths\n        noindex\n        redirectType\n        reference {\n          __typename\n          ...basePage\n        }\n        countryOverrideReferencesCollection(\n          limit: 1\n          where: { contentfulMetadata: { tags: { id_contains_some: $country } } }\n        ) {\n          items {\n            ...basePage\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query UrlRouteCollection($isPreview: Boolean, $path: [String], $lang: String, $country: [String]) {\n    urlRouteCollection(\n      preview: $isPreview\n      locale: $lang\n      where: {\n        AND: [\n          { OR: [{ reference_exists: true }, { countryOverrideReferencesCollection_exists: true }] }\n          { intlPaths_contains_some: $path }\n        ]\n      }\n      limit: 1\n    ) {\n      items {\n        __typename\n        sys {\n          id\n        }\n        path\n        intlPaths\n        noindex\n        redirectType\n        reference {\n          __typename\n          ...basePage\n        }\n        countryOverrideReferencesCollection(\n          limit: 1\n          where: { contentfulMetadata: { tags: { id_contains_some: $country } } }\n        ) {\n          items {\n            ...basePage\n          }\n        }\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;