import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { ActionProps } from '../Action';
import { breakpointRules } from '@aceandtate/ds';
import RenderContext from 'blocks/V2Contentful/RenderContext';
import {
  infoBarHeightDesktop,
  infoBarHeightMobile,
  navHeightDesktop,
  navHeightMobile,
  navHeightSecondaryMobile
} from 'styles';
type CombinedProps = {
  layout: Layout;
  action: ActionProps;
  references?: any[]; // TODO Type this
  href?: string;
  depth: number;
  children: React.ReactNode[];
};

type Layout = {
  flexFlow: 'column' | 'row';
  width: string;
  height: string;
  columnGap: string;
  rowGap: string;
  paddingTop: string;
  paddingBottom: string;
  paddingLeft: string;
  paddingRight: string;
  backgroundColor: string;
  backgroundImage?: string;
  justifyContent?: string;
  alignItems?: string;
  backgroundEntity?: {
    link: string;
    type: string;
    label: string;
  };
};

type LayoutProps = {
  layout?: Layout;
  depth: number;
};

const defaultLayout: Layout = {
  flexFlow: 'row',
  width: '100%',
  height: 'auto',
  columnGap: '16px',
  rowGap: '16px',
  paddingTop: '16px',
  paddingBottom: '16px',
  paddingLeft: '16px',
  paddingRight: '16px',
  backgroundColor: '#eee',
  justifyContent: 'flex-start',
  alignItems: 'flex-end'
} as const;

const StyledAnchorElm = styled.a<LayoutProps>`
  position: relative;
  pointer-events: 'none';
  display: flex;
  flex-flow: column;
  z-index: ${({ depth }) => depth};

  ${({ layout }) => renderElementLayout(layout)}
`;

const StyledDivElm = styled.div<LayoutProps>`
  position: relative;
  pointer-events: 'none';
  display: flex;
  flex-flow: column;
  z-index: ${({ depth }) => depth + 1};
  ${({ layout }) => renderElementLayout(layout)}
`;

export const LayoutElement = ({ href, layout, children, action, depth, references, ...props }: CombinedProps) => {
  const { isContentfulPreview } = useContext(RenderContext);

  // Position determines stacking order relative to parent
  // In preview/action mode, force higher z-index for element selection
  const layerIndex = action || isContentfulPreview ? depth : 0;

  if (layout?.backgroundEntity) {
    const reference = references.find(x => x.sys?.id === layout.backgroundEntity.link);
    if (reference) {
      layout.backgroundImage = `url("${reference.laptop.url}")`;
    }
  }
  if (href) {
    return (
      <StyledAnchorElm layout={layout} depth={layerIndex} href={href} {...props}>
        {children}
      </StyledAnchorElm>
    );
  }
  return (
    <StyledDivElm layout={layout} depth={layerIndex} {...props}>
      {children}
    </StyledDivElm>
  );
};

function applyDefaultLayout(layout: Layout) {
  if (!layout) {
    return { ...defaultLayout };
  }

  const completeLayout = { ...layout };
  for (const key in defaultLayout) {
    if (completeLayout[key] === undefined || completeLayout[key] === null) {
      completeLayout[key] = defaultLayout[key];
    }
  }

  return completeLayout;
}

function renderElementLayout(layout: Layout) {
  layout = applyDefaultLayout(layout);

  return css`
    ${layout.width.includes('px') &&
    css`
      flex-shrink: 0;
    `}
    width: ${layout.width};
    height: ${layout.height};
    max-width: 100%;
    max-height: 100%;
    padding: ${layout.paddingTop} ${layout.paddingRight} ${layout.paddingBottom} ${layout.paddingLeft};
    background: ${layout.backgroundImage || layout.backgroundColor};
    background-position: center;
    background-size: cover;
    ${layout.height === '100vh' &&
    // first element needs reduced height to account for menu
    css`
      &:first-of-type {
        height: calc(100vh - ${navHeightMobile}px - ${infoBarHeightMobile}px - ${navHeightSecondaryMobile}px);
      }
      @media ${breakpointRules.laptop} {
        &:first-of-type {
          height: calc(100vh - ${navHeightDesktop}px - ${infoBarHeightDesktop}px);
        }
      }
    `}
  `;
}

function renderContainerLayout(layout: Layout) {
  layout = applyDefaultLayout(layout);
  return css`
    flex-flow: ${layout.flexFlow};
    justify-content: ${layout.justifyContent};
    align-items: ${layout.alignItems};
    gap: ${layout.columnGap} ${layout.rowGap};
    height: 100%;
  `;
}

export const LayoutContainer = styled.div<LayoutProps>`
  display: flex;

  ${({ layout }) => renderContainerLayout(layout)}
`;
