import React, { useEffect, useState } from 'react';
import BlockRenderer from './BlockRenderer';
import { BasePageFragment } from 'services/generated/graphql/graphql';
import { useRouter } from 'next/router';
import RenderContext from './RenderContext';
type Props = {
  blocks: BasePageFragment['blocksCollection'];
  animateTypographies?: boolean;
  draftMode: boolean;
};

function collectActions(element: any): any[] {
  let hasLink = false;

  if (element.__typename === 'ComponentText') {
    if (element.editor?.root?.children) {
      for (const paragraph of element.editor.root.children) {
        for (const node of paragraph.children) {
          if (node.type === 'at-link' || node.type === 'at-button') {
            hasLink = true;
            break;
          }
        }
        if (hasLink) {
          break;
        }
      }
    }
  }

  const actions = hasLink ? ['textLink'] : element.action ? [element.action] : [];

  if (element.blocksCollection?.items?.length) {
    return actions.concat(element.blocksCollection.items.flatMap(collectActions));
  }

  return actions;
}

function mapActionsToElements(elements: any[]): any[][] {
  return elements.map(collectActions);
}

export default function V2Contentful(props: Props) {
  const { blocks, animateTypographies = false } = props;
  const [isContentfulPreview, setIsContentfulPreview] = useState(false);
  const [isInspectorActive, setIsInspectorActive] = useState(false);

  const router = useRouter();

  // We need to disable all internal and external links during contentful preview mode
  // Otherwise the editor will behave in strange and unpredictable ways.
  useEffect(() => {
    const inIframe = window.self !== window.top;

    function handleClick(e: MouseEvent) {
      if ((e.target as HTMLElement).closest('a')) {
        e.preventDefault();
      }
    }

    function handleRouteChange() {
      router.events.emit('routeChangeError');
      throw `routeChange aborted. This error can be safely ignored - https://github.com/zeit/next.js/issues/2476.`; //eslint-disable-line
    }

    if (inIframe) {
      setIsContentfulPreview(true);

      // preventing clicks on regular links
      document.body.addEventListener('click', handleClick);
      // preventing clicks on NEXT links
      router.events.on('routeChangeStart', handleRouteChange);
    }

    return () => {
      document.body.removeEventListener('click', handleClick);
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('message', event => {
      if (event.data.action === 'INSPECTOR_MODE_CHANGED') {
        setIsInspectorActive(event.data.isInspectorActive);
      }
    });
  }, []);

  // Determine anchor rendering strategy:
  // 1. Overlay: Sibling to children (for nested links)
  // 2. Standard: Actual anchor element
  // This approach enables editors to create nested links, which are typically invalid in HTML

  const actionsWithFlags = mapActionsToElements(blocks.items);
  actionsWithFlags.forEach(actionGroup => {
    if (actionGroup.length > 1) {
      actionGroup.slice(0, -1).forEach(action => {
        // textLink components will always be at the lowest level inside of the text blocks,
        // they should never (and do not need to) be rendered as overlay
        if (action !== 'textLink') {
          action.shouldRenderAsOverlay = true;
        }
      });
    }
  });

  return (
    <RenderContext.Provider value={{ isContentfulPreview, isInspectorActive }}>
      <main>
        <BlockRenderer blocks={blocks.items} animateTypographies={animateTypographies} />
      </main>
    </RenderContext.Provider>
  );
}
