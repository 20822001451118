import React, { ReactNode } from 'react';
import { BLOCKS, INLINES, Block, Inline, Document, Text } from '@contentful/rich-text-types';
import { documentToReactComponents, NodeRenderer } from '@contentful/rich-text-react-renderer';
import Media from 'components/Media';
import { Button, FontVariant, Typography } from '@aceandtate/ds';
import * as Sentry from '@sentry/nextjs';

import * as Styles from '../../../utils/contentful/stylesRichTextV2';
import { transform } from 'utils/images';
import Image from 'next/image';
import Link from 'next/link';
import { AssetFragment, FaqQuestionFragment, UrlRouteFragment } from 'services/generated/graphql/graphql';

type AllowedLinks = FaqQuestionFragment['body']['links'];

export type HeaderType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

function renderHeading(node: Block, children: ReactNode, renderAs?: string) {
  try {
    // nodeTypes look like: "heading-3"
    const headerType = `h${node.nodeType.toString().split('-').pop()}` as HeaderType;
    return (
      <Typography variant={headerType} gutterBottom as={renderAs || headerType}>
        {children}
      </Typography>
    );
  } catch (err) {
    return undefined;
  }
}

function renderCaption(node: Block, children: ReactNode) {
  return (
    <Styles.RichTextTypographyV2Caption variant='bodyXS' color='inherit' gutterBottom>
      {children}
    </Styles.RichTextTypographyV2Caption>
  );
}

function renderBody(node: Block, children: any[], props?: { hideMobile?: boolean; variant?: FontVariant }) {
  return (
    <Styles.RichTextTypographyV2Body
      hideMobile={props?.hideMobile}
      variant={props?.variant || 'bodyM'}
      color='inherit'
      gutterBottom
    >
      {React.Children.map(children, child => {
        return child;
      })}
    </Styles.RichTextTypographyV2Body>
  );
}

function renderMedia(asset: AssetFragment) {
  if (asset.contentType.includes('image/')) {
    return (
      <Styles.RichTextV2ImageWrapper>
        <Image
          alt={asset.title}
          title={asset.title}
          width={asset.width}
          height={asset.height}
          src={`https:${asset.url}`}
          quality={75}
          loader={({ width, quality, src }) => transform(src, { quality, width })}
          sizes={'70vw'}
          style={{
            width: '100%',
            height: 'auto'
          }}
        />
      </Styles.RichTextV2ImageWrapper>
    );
  } else {
    return (
      <Media
        landscape={{
          title: asset.title,
          description: asset.description,
          file: {
            url: asset.url,
            details: { size: asset.size },
            fileName: asset.fileName,
            contentType: asset.contentType
          }
        }}
      />
    );
  }
}

function renderListItem(node: Block, children: ReactNode) {
  // const childNodeVariant = node.nodeType === 'list-item' && nodeTypeMap[node.content?.[0].nodeType];
  return (
    <Typography variant={'bodyM'} as='li'>
      {children}
    </Typography>
  );
}

function guardException(fn: (...args: any[]) => any) {
  return (...args: any[]) => {
    try {
      return fn(...args);
    } catch (err) {
      Sentry.captureException(err, { extra: { args, fn: fn.name } });
      return null;
    }
  };
}

function renderOptions(links?: AllowedLinks): {
  renderNode: { [key: string]: NodeRenderer };
} {
  // create an asset map
  const assetMap: Map<AssetFragment['sys']['id'], AssetFragment> = new Map();
  // loop through the block assets and add them to the map
  if (links?.assets?.block) {
    for (const asset of links.assets.block) {
      assetMap.set(asset.sys.id, asset);
    }
  }

  // loop through the hyperlink assets and add them to the map
  if (links?.assets?.hyperlink) {
    for (const asset of links.assets.hyperlink) {
      assetMap.set(asset.sys.id, asset);
    }
  }

  // create an entry map
  // This should be Entry, but we need to check the type of the entry and Entry is not typed with the possible fields
  const entryMap: Map<
    UrlRouteFragment['sys']['id'],
    AllowedLinks['entries']['hyperlink'][0] | AllowedLinks['entries']['block'][0] | AllowedLinks['entries']['inline'][0]
  > = new Map();

  // loop through the block linked entries and add them to the map
  if (links?.entries?.block) {
    for (const entry of links.entries.block) {
      entryMap.set(entry.sys.id, entry);
    }
  }

  // loop through the inline linked entries and add them to the map
  if (links?.entries?.inline) {
    for (const entry of links.entries.inline) {
      entryMap.set(entry.sys.id, entry);
    }
  }

  // loop through the hyperlink linked entries and add them to the map
  if (links?.entries?.hyperlink) {
    for (const entry of links.entries.hyperlink) {
      entryMap.set(entry.sys.id, entry);
    }
  }

  return {
    renderNode: {
      [INLINES.ASSET_HYPERLINK]: (_node: Inline, _children: ReactNode) => {
        // NOT SUPPORTED
        return <></>;
      },
      [INLINES.EMBEDDED_ENTRY]: (_node: Inline, _children: ReactNode) => {
        // NOT SUPPORTED
        return <></>;
      },
      [INLINES.ENTRY_HYPERLINK]: guardException((node: Inline, children: ReactNode) => {
        // console.log({ node });

        // find the entry in the entryMap by ID
        const entry = entryMap.get(node.data.target.sys.id);
        const href = 'path' in entry ? entry.path : '#';

        const innerContent = node.content.length === 1 && (node.content[0] as Text);
        const isButton = innerContent.marks.some(mark => mark.type === 'bold' || mark.type === 'italic');
        const buttonVariant: React.ComponentProps<typeof Button>['variant'] = innerContent.marks.some(
          mark => mark.type === 'italic'
        )
          ? 'outlined'
          : 'default';

        if (isButton) {
          return (
            <Styles.ButtonWrapperV2>
              <Link href={href || ''} passHref>
                <Button color='primary' variant={buttonVariant}>
                  {children}
                </Button>
              </Link>
            </Styles.ButtonWrapperV2>
          );
        }
        return (
          <Styles.LinkWrapperV2>
            <Link href={href}>{children}</Link>
          </Styles.LinkWrapperV2>
        );
      }),
      [INLINES.HYPERLINK]: (node: Inline, children: ReactNode) => {
        // NOT SUPPORTED
        const href = node.data.uri || '#';

        const innerContent = node.content.length === 1 && (node.content[0] as Text);
        const isButton = innerContent.marks.some(mark => mark.type === 'bold' || mark.type === 'italic');
        const buttonVariant: React.ComponentProps<typeof Button>['variant'] = innerContent.marks.some(
          mark => mark.type === 'italic'
        )
          ? 'outlined'
          : 'default';

        if (isButton) {
          return (
            <Styles.ButtonWrapperV2>
              <Link href={href || ''} passHref>
                <Button color='primary' variant={buttonVariant}>
                  {children}
                </Button>
              </Link>
            </Styles.ButtonWrapperV2>
          );
        }

        return (
          <Styles.LinkWrapperV2>
            <Link href={href}>{children}</Link>
          </Styles.LinkWrapperV2>
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (_node: Block, _children: ReactNode) => {
        // NOT SUPPORTED
        return <></>;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: Block, _children: ReactNode) => {
        // find the asset in the assetMap by ID
        const asset = assetMap.get(node.data.target.sys.id);

        // render the asset accordingly
        return renderMedia(asset);
      },
      [BLOCKS.HEADING_1]: renderHeading,
      [BLOCKS.HEADING_2]: renderHeading,
      [BLOCKS.HEADING_3]: renderHeading,
      [BLOCKS.HEADING_4]: renderHeading,
      [BLOCKS.HEADING_5]: renderHeading,
      [BLOCKS.HEADING_6]: renderCaption,
      [BLOCKS.HR]: (_node: Block, _children: ReactNode) => {
        // NOT SUPPORTED
        return <></>;
      },
      [BLOCKS.LIST_ITEM]: renderListItem,
      [BLOCKS.OL_LIST]: (_node: Block, children: ReactNode) => {
        // NOT SUPPORTED
        return <ol>{children}</ol>;
      },
      [BLOCKS.UL_LIST]: (_node: Block, children: ReactNode) => {
        return <ul>{children}</ul>;
      },
      [BLOCKS.PARAGRAPH]: renderBody,
      [BLOCKS.QUOTE]: (_node: Block, _children: ReactNode) => {
        // NOT SUPPORTED
        return <></>;
      },
      [BLOCKS.TABLE]: (_node: Block, _children: ReactNode) => {
        // NOT SUPPORTED
        return <></>;
      },
      [BLOCKS.TABLE_CELL]: (_node: Block, _children: ReactNode) => {
        // NOT SUPPORTED
        return <></>;
      },
      [BLOCKS.TABLE_HEADER_CELL]: (_node: Block, _children: ReactNode) => {
        // NOT SUPPORTED
        return <></>;
      },
      [BLOCKS.TABLE_ROW]: (_node: Block, _children: ReactNode) => {
        // NOT SUPPORTED
        return <></>;
      },
      [BLOCKS.DOCUMENT]: (_node: Block, children: ReactNode) => {
        // NOT SUPPORTED
        return <>{children}</>;
      }
    }
  };
}

export default function RichTextRenderer(document: Document, links?: AllowedLinks) {
  return documentToReactComponents(document, renderOptions(links));
}
