import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Flex, IconInfo, Modal, Typography, brandColors, grid } from '@aceandtate/ds';
import HardCaseEOLModal from './HardCase/HardCaseEOLModal';
import HardCaseMaterialsModal from './HardCase/HardCaseMaterialsModal';
import EyeDropsIngredientsModal from './EyeDrops/EyeDropsIngredientsModal';
import CleaningKitIngredientsModal from './CleaningKit/CleaningKitIngredientsModal';
import EyeDropsInstructionsModal from './EyeDrops/EyeDropsInstructionsModal';
import OrigamiCaseDescriptionModal from './OrigamiCase/OrigamiCaseDescriptionModal';
import SkiGooglesWarningsModal from './SkiGoggles/SkiGooglesWarningsModal';

const modalMap = {
  cleaningKitIngredients: CleaningKitIngredientsModal,
  eyeDropsIngredients: EyeDropsIngredientsModal,
  eyeDropsInstructions: EyeDropsInstructionsModal,
  origamiCaseDescription: OrigamiCaseDescriptionModal,
  recycledHardCaseEOL: HardCaseEOLModal,
  recycledHardCaseMaterials: HardCaseMaterialsModal,
  skiGogglesWarnings: SkiGooglesWarningsModal
};

type Props = { type: keyof typeof modalMap };

export default function ProductInformationModal({ type }: Props) {
  const [openModalType, setOpenModalType] = useState<typeof type>(null);

  if (type && modalMap[type]) {
    const Component = modalMap[type];
    const { buttonText } = Component;

    return (
      <>
        <Flex alignItems='self-start' gap={grid[4]}>
          <Typography variant='bodyM' as='span'>
            <IconInfo color={brandColors.dark50} fontSize={18} style={{ verticalAlign: 'sub' }} />
          </Typography>
          <Button onClick={() => setOpenModalType(type)} variant='link' style={{ textAlign: 'left' }}>
            <Typography variant='bodyM' as='span'>
              <FormattedMessage {...buttonText} />
            </Typography>
          </Button>
        </Flex>
        <Modal open={!!openModalType} onClose={() => setOpenModalType(null)} content={<Component />} />
      </>
    );
  } else {
    return null;
  }
}
