import React from 'react';
import { Accordion } from '@aceandtate/ds';
import { FaqQuestionFragment } from 'services/generated/graphql/graphql';
import RichTextRenderer from 'blocks/V2Contentful/RickTextRenderer';

type Props = {
  toggleOpen: (title: string) => void;
  open: boolean;
  [props: string]: any;
} & FaqQuestionFragment;

export default function FAQQuestion({ question, toggleOpen, slug, open, ...props }: Props) {
  return (
    <Accordion
      title={question}
      AdditionalProps={{
        AccordionProps: {
          'data-slug': slug,
          itemProp: 'mainEntity',
          itemScope: true,
          itemType: 'https://schema.org/Question'
        },
        BodyProps: {
          itemProp: 'acceptedAnswer',
          itemScope: true,
          itemType: 'https://schema.org/Answer'
        },
        TitleProps: {
          itemProp: 'name'
        }
      }}
      data-slug={slug}
      open={open}
      onClickItem={() => toggleOpen(slug)}
    >
      <div itemProp='text'>{props.body && RichTextRenderer(props.body.json, props.body.links)}</div>
    </Accordion>
  );
}
