export const productFilters = [
  {
    groupKey: 'styles' as const,
    options: [
      'angular',
      'aviator',
      'bold',
      'cat_eye',
      'classic',
      'clubmaster',
      'flat_top',
      'oversized',
      'rimless',
      'wayfarer'
    ]
  },
  {
    groupKey: 'materials' as const,
    options: ['acetate', 'bio_acetate', 'renew_bio_acetate', 'combi', 'metal', 'titanium', 'recycled']
  },
  {
    groupKey: 'colorPatterns' as const,
    options: ['pattern', 'gradient_or_two_tone', 'solid', 'transparent']
  },
  {
    groupKey: 'prices' as const,
    groupLabel: 'Price from' as const,
    options: ['basePrice', 'limitedEditionPrice', 'premiumPrice']
  },
  {
    groupKey: 'widths' as const,
    options: ['narrow', 'medium', 'wide']
  },
  {
    groupKey: 'colors' as const,
    options: [
      'beige',
      'black',
      'blue',
      'brown',
      'clear',
      'gold',
      'green',
      'grey',
      'orange',
      'pink',
      'purple',
      'red',
      'silver',
      'white',
      'yellow'
    ]
  },
  {
    groupKey: 'shapes' as const,
    options: ['hexagonal', 'oval', 'round', 'square', 'rectangular']
  }
];

const productFiltersKeys = ['styles', 'materials', 'colorPatterns', 'prices', 'widths', 'colors', 'shapes'] as const;
type ProductFiltersKey = typeof productFiltersKeys[number];

function getProductFiltersByKeys(keys: ProductFiltersKey[]) {
  return keys
    .map(key => {
      return productFilters.find(filter => filter.groupKey === key);
    })
    .filter(Boolean);
}

/** Product Filters Instances */
const laptopFiltersKeys = productFiltersKeys;
const mobileFiltersKeys = ['shapes', 'widths', 'colors', 'styles', 'materials', 'colorPatterns', 'prices'] as const;
const expandedFilterKeys = ['shapes', 'widths', 'colors'] as const;

export const laptopFilters = getProductFiltersByKeys(laptopFiltersKeys as unknown as LaptopFiltersKeys[]);
export const mobileFilters = getProductFiltersByKeys(mobileFiltersKeys as unknown as MobileFiltersKeys[]);
export const expandedFilters = getProductFiltersByKeys(expandedFilterKeys as unknown as ExpandedFiltersKey[]);

type LaptopFiltersKeys = typeof laptopFiltersKeys[number];
type MobileFiltersKeys = typeof mobileFiltersKeys[number];
type ExpandedFiltersKey = typeof expandedFilterKeys[number];
