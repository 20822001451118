import { grid, breakpointRules } from '@aceandtate/ds';
import styled, { css } from 'styled-components';
import { alignItemsMap, justifyContentMap, positionContentMap, textAlignMap } from '.';
import { VideoWrapper } from 'components/VideoPlayer/styles';

export const AspectRatioBox = styled.div<{ paddingBottom?: string }>`
  width: 100%;
  padding-bottom: ${props => props.paddingBottom};
  position: relative;
`;

const AbsolutePositioning = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

/** Ideally the value would be dynamic to fit the margins used by the parents */
const PdPBlockPadding = css`
  padding-block: ${grid[20]};
  padding-inline: var(--ds-grid-padding);

  @media ${breakpointRules.laptop} {
    padding-block: ${grid[40]};
  }
`;

export const BackgroundVideo = styled.div`
  ${AbsolutePositioning}

  & > ${VideoWrapper} {
    object-fit: cover;
    position: initial;
    padding-bottom: initial;
  }
`;

export const BackgroundImage = styled.div`
  ${AbsolutePositioning}
`;

export const BackgroundColor = styled.div<{ backgroundColor }>`
  ${AbsolutePositioning}
  background-color: ${props => props.backgroundColor};
`;

export const Content = styled.div<{
  position: (typeof positionContentMap)[keyof typeof positionContentMap];
  justifyContent: (typeof justifyContentMap)[keyof typeof justifyContentMap];
  alignItems: (typeof alignItemsMap)[keyof typeof alignItemsMap];
  textAlign?: (typeof textAlignMap)[keyof typeof textAlignMap];
  width?: string;
}>`
  position: ${({ position }) => position};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};

  /** Ideally the value would be dynamic to fit the margins used by the parents */
  ${PdPBlockPadding}

  & > * {
    width: auto;
    text-align: ${({ textAlign }) => textAlign};

    @media ${breakpointRules.laptop} {
      width: ${({ width }) => width};
    }
  }
`;
