import { defineMessages } from 'react-intl';

export default defineMessages({
  caseAndClothModalText: {
    defaultMessage:
      "Your Ace & Tate glasses come with a case and lens cleaning cloth — you know, to keep them in mint condition. We made the base from water-based PU, without glue, meaning it's recyclable, soft and light. Only the best for you, pal.",
    id: 'pdp.usps.included.case.modal.text'
  },
  caseAndClothModalTitle: {
    defaultMessage: 'On the case',
    id: 'pdp.usps.included.case.modal.title'
  }
});
