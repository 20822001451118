import React, { useContext } from 'react';
import { Typography } from '@aceandtate/ds';
import ecommerceMessages from 'messages/ecommerce';
import { FormattedMessage } from 'react-intl';
import { Store } from 'types/solidus';
import getWeekDayFromIndex from 'utils/helpers/getWeekDayFromIndex';
import { ServicesContext } from 'services/context';
import { StoreWithStockAvailabilityProps } from 'services/storeService';
import * as Styles from './styles';
import { getHoursWithExceptions } from './utils/getHoursWithHoliday';

type Props = {
  store:
    | Pick<Store, 'opening_hours' | 'holiday_hours'>
    | Pick<StoreWithStockAvailabilityProps, 'opening_hours' | 'holiday_hours'>;
};

export default function StoreOpeningHours({ store, ...props }: Props) {
  const { locale } = useContext(ServicesContext);

  const hoursWithExceptions = getHoursWithExceptions(store.opening_hours, store.holiday_hours);

  return (
    <Styles.OpeningHoursWrapper {...props}>
      <Styles.OpeningHoursColumn>
        {hoursWithExceptions.map(openingTime => {
          const dayOfTheWeek = getWeekDayFromIndex(openingTime.day_of_the_week, locale.lang);
          const isToday = new Date().getDay() === openingTime.day_of_the_week;

          return (
            <Styles.DayOfTheWeek fontWeight={isToday ? 'bold' : 'light'} key={openingTime.day_of_the_week}>
              {dayOfTheWeek}
            </Styles.DayOfTheWeek>
          );
        })}
      </Styles.OpeningHoursColumn>
      <Styles.OpeningHoursColumn>
        {hoursWithExceptions.map(openingTime => {
          const isToday = new Date().getDay() === openingTime.day_of_the_week;

          return (
            <div key={openingTime.day_of_the_week + '-time'}>
              {openingTime.closed > 0 && (
                <Typography>
                  <FormattedMessage {...ecommerceMessages.closed} />
                </Typography>
              )}
              {openingTime.closed === 0 && (
                <Typography
                  data-testid={`day-${openingTime.day_of_the_week}-times`}
                  fontWeight={isToday ? 'bold' : 'light'}
                >
                  {openingTime.opening_time?.replace(':00', '') + ' - ' + openingTime.closing_time?.replace(':00', '')}
                </Typography>
              )}
            </div>
          );
        })}
      </Styles.OpeningHoursColumn>
    </Styles.OpeningHoursWrapper>
  );
}
