import { setDay } from 'date-fns';

export default function getWeekDayFromIndex(
  /** Day of the week, starts at 0 */
  dayIndex: number,
  /** ISO locale (ex. en-NL) */
  locale: string,
  options: Intl.DateTimeFormatOptions = { weekday: 'short' }
) {
  return setDay(Date.now(), dayIndex).toLocaleDateString(locale, options);
}
