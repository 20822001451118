import { defineMessages } from 'react-intl';

export default defineMessages({
  carbonImpact: {
    defaultMessage: '{carbonImpact} kg Co2-eq, according to our 2023 LCA',
    id: 'carbonFootprint.carbonImpact'
  },
  carbonImpactNoData: {
    defaultMessage: 'The CO2 footprint for this frame has not been calculated yet',
    id: 'carbonFootprint.carbonImpactNoData'
  },
  noDataAvailable: {
    defaultMessage: 'no data available',
    id: 'carbonFootprint.noDataAvailable'
  }
});
