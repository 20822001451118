import React from 'react';
import { Typography } from '@aceandtate/ds';
import { FormattedMessage } from 'react-intl';
import * as Styles from '../../../styles';
import messages from '../../../messages';

export default function HardCaseMaterialsModal() {
  return (
    <>
      <Typography variant='h5' gutterBottom>
        <FormattedMessage {...messages.hardCaseMaterialInformationTitle} />
      </Typography>
      <Typography gutterBottom={4}>
        <FormattedMessage {...messages.hardCaseMaterialsDemoLensesDescription} />
      </Typography>
      <Typography as='span'>
        <FormattedMessage
          {...messages.hardCaseMaterialsList}
          values={{
            li: chunks => <li>{chunks}</li>,
            ul: chunks => <Styles.ProductModalList>{chunks}</Styles.ProductModalList>
          }}
        />
      </Typography>
    </>
  );
}

HardCaseMaterialsModal.buttonText = messages.hardCaseMaterialsLink;
