import { useMemo } from 'react';
import { Product, ProductVariant } from 'types/torii';

type ProductForLookup = Pick<Product, 'currentVariant'> & Partial<Pick<Product, 'variantSkus' | 'variants'>>;

function generateVariantMap(products: ProductForLookup[]) {
  return products.reduce((lookup, product) => {
    lookup[product.currentVariant.sku] = product.currentVariant;
    return lookup;
  }, {});
}
export default function useVariantsLookup(products: ProductForLookup[]) {
  const variantMap = useMemo(() => {
    return generateVariantMap(products);
  }, [products]);

  return (skus: string[]): ProductVariant[] => {
    return skus.map(x => variantMap[x]).filter(Boolean);
  };
}

/** non-hook version of `useVariantLookup`, should be kept in sync to ensure behaviour works the same everywhere */
export function variantLookup(skus: string[], products: Product[]): ProductVariant[] {
  const variantMap = generateVariantMap(products);

  return skus.map(x => variantMap[x]).filter(Boolean);
}
