import React from 'react';
import { brandColors, Select, Typography } from '@aceandtate/ds';
import productsSortMessages from 'messages/productsSort';
import { FormattedMessage } from 'react-intl';
import { SelectItem } from '@aceandtate/ds/dist/components/Select/Select';
import { productCollectionSortMap as productsSortMap, type SortOptions } from 'utils/product/sortProducts';
import styled from 'styled-components';

export const StyledSelect = styled(Select)`
  #product-sorting-toggle-button {
    border: transparent;
  }
`;

function getSortMessage(option: keyof typeof productsSortMessages) {
  return productsSortMessages[option] ? <FormattedMessage {...productsSortMessages[option]} /> : option;
}

function getLabel(option: SortOptions) {
  return (
    <Typography variant='h6' fontWeight='light' as='span'>
      {getSortMessage(option)}
    </Typography>
  );
}

type Props = {
  option: SortOptions;
  setOption: React.Dispatch<React.SetStateAction<SortOptions>>;
};

export default function SortSelector(props: Props) {
  const { option, setOption } = props;

  function getSelectItemsProps(options: SortOptions[]): SelectItem<any>[] {
    return options.map(option => {
      return {
        id: option,
        label: getLabel(option) as any,
        value: option
      };
    });
  }

  function handleSetOption(option: SortOptions) {
    setOption(option);
  }

  return (
    <StyledSelect
      fullWidth
      placeholder={
        <span>
          <Typography color={brandColors.dark} as='span'>
            {getSortMessage('sortBy')}:{' '}
          </Typography>
          <Typography color={brandColors.dark} fontWeight='bold' as='span'>
            {getSortMessage(option)}
          </Typography>
        </span>
      }
      id='product-sorting'
      items={getSelectItemsProps(Array.from(productsSortMap.keys()) as SortOptions[])}
      setSelectedItems={(selectedItem: SelectItem<any>) => handleSetOption(selectedItem.id as SortOptions)}
    />
  );
}
