export const illustrationTypes = {
  acetate_oval: 'acetate_oval',
  acetate_rectangular: 'acetate_rectangle',
  acetate_round: 'acetate_round',
  acetate_square: 'acetate_square',
  combi_round: 'combi_round',
  combi_square: 'combi_square',
  metal_rectangular: 'metal_square',
  metal_round: 'metal_round',
  metal_square: 'metal_square',
  titanium_oval: 'titanium_oval',
  titanium_round: 'titanium_round',
  titanium_square: 'titanium_square'
};

export const illustrations = [
  {
    frontSize: 94,
    imgName: 1,
    type: illustrationTypes.acetate_rectangular
  },
  {
    frontSize: 82,
    imgName: 2,
    type: illustrationTypes.acetate_round
  },
  {
    frontSize: 97,
    imgName: 3,
    type: illustrationTypes.acetate_square
  },
  {
    frontSize: 84,
    imgName: 4,
    type: illustrationTypes.combi_round
  },
  {
    frontSize: 87,
    imgName: 5,
    type: illustrationTypes.combi_square
  },
  {
    frontSize: 85,
    imgName: 6,
    type: illustrationTypes.metal_round
  },
  {
    frontSize: 81,
    imgName: 7,
    type: illustrationTypes.metal_square
  },
  {
    frontSize: 85,
    imgName: 8,
    type: illustrationTypes.titanium_round
  },
  {
    frontSize: 90,
    imgName: 9,
    type: illustrationTypes.titanium_square
  },
  {
    frontSize: 81,
    imgName: 10,
    type: illustrationTypes.acetate_oval
  },
  {
    frontSize: 84,
    imgName: 11,
    type: illustrationTypes.titanium_oval
  }
];

export function getMeasurements(size) {
  const sizes = size.split('-');
  return {
    bridgeWidth: sizes[1] ? `${sizes[1]}mm` : null,
    lensWidth: sizes[0] ? `${sizes[0]}mm` : null,
    templeLength: sizes[2] ? `${sizes[2]}mm` : null
  };
}

function getMaterial(materials: string[]) {
  // Material is an array, combi takes prio otherwise first material in array
  if (!Array.isArray(materials)) return null;
  if (materials.includes('combi')) return 'combi';
  return materials[0] || null;
}

export function getIllustrationType(materials: string[], shape: string[]) {
  const material = getMaterial(materials);
  // Get type from material/shape or return default shape
  return illustrationTypes[`${material}_${shape}`] || illustrationTypes.acetate_rectangular;
}

function getImgSrc(name) {
  return `https://images.aceandtate.com/image/upload/c_scale/v1521191111/measurements/${name}`;
}

export function getIllustration(illustrationType: string) {
  const illustation = illustrations.find(i => i.type === illustrationType) || illustrations[0];
  const { type, frontSize, imgName } = illustation;

  return {
    frontSize,
    frontSrc: getImgSrc(`${imgName}_front`),
    profileSrc: getImgSrc(`${imgName}_profile`),
    type
  };
}
