import { Product } from 'types/torii';

// sorting helper functions
export function sortAZ(products: Product[]) {
  return [...products].sort((prev, curr) => {
    const nameA = prev.currentVariant.name.toUpperCase(); // ignore upper and lowercase
    const nameB = curr.currentVariant.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
}
export function sortZA(products: Product[]) {
  return [...products].sort((prev, curr) => {
    const nameA = prev.currentVariant.name.toUpperCase(); // ignore upper and lowercase
    const nameB = curr.currentVariant.name.toUpperCase(); // ignore upper and lowercase
    if (nameA > nameB) {
      return -1;
    }
    if (nameA < nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
}

export function sortNew(products: Product[]) {
  return [...products].sort(
    (prev, curr) =>
      Date.parse(curr.currentVariant.availability.availableFromDate) -
      Date.parse(prev.currentVariant.availability.availableFromDate)
  );
}

export function sortLowToHigh(products: Product[]) {
  return [...products].sort((prev, curr) => {
    const prevPrice = prev.currentVariant.salePrice?.value ?? prev.currentVariant.price.value;
    const currPrice = curr.currentVariant.salePrice?.value ?? curr.currentVariant.price.value;

    return prevPrice - currPrice;
  });
}

export function sortHighToLow(products: Product[]) {
  return [...products].sort((prev, curr) => {
    const prevPrice = prev.currentVariant.salePrice?.value ?? prev.currentVariant.price.value;
    const currPrice = curr.currentVariant.salePrice?.value ?? curr.currentVariant.price.value;

    return currPrice - prevPrice;
  });
}

// sorting constructor
const sortProductsConstructor = {
  aZ: { action: sortAZ, key: 'aZ', label: 'A-Z' },
  default: {
    action: (products: Product[]) => {
      return products;
    },
    key: 'default',
    label: 'Default'
  },
  new: { action: sortNew, key: 'new', label: 'New' },
  priceHighLow: { action: sortHighToLow, key: 'priceHighLow', label: 'Price High-Low' },
  priceLowHigh: { action: sortLowToHigh, key: 'priceLowHigh', label: 'Price Low-High' },
  zA: { action: sortZA, key: 'zA', label: 'Z-A' }
} as const;

export type SortOptions = keyof typeof sortProductsConstructor;
export type SortValues = (typeof sortProductsConstructor)[SortOptions];

// sorting instances
export const productCollectionSortMap: Map<SortOptions, SortValues> = new Map();

productCollectionSortMap.set('default', sortProductsConstructor.default);
productCollectionSortMap.set('priceLowHigh', sortProductsConstructor.priceLowHigh);
productCollectionSortMap.set('priceHighLow', sortProductsConstructor.priceHighLow);
productCollectionSortMap.set('new', sortProductsConstructor.new);
productCollectionSortMap.set('aZ', sortProductsConstructor.aZ);
productCollectionSortMap.set('zA', sortProductsConstructor.zA);
