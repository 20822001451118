import React, { ReactNode } from 'react';
import { Chip } from '@aceandtate/ds';
import * as Styles from '../../styles';

type Props = {
  options: { value: any; label: ReactNode; disabled?: boolean }[];
  selectedOption: any;
  setSelectedOption: (option: any) => void;
};

export default function ProductOptions(props: Props) {
  const { options, selectedOption, setSelectedOption } = props;

  return (
    <Styles.ProductOptionWrapper>
      {options.map(option => (
        <Chip
          fullWidth
          data-testid={`product-option-${option.value}`}
          key={option.value}
          disabled={option.disabled}
          active={option.value === selectedOption}
          onClick={() => setSelectedOption(option.value)}
          style={{ justifyContent: 'center' }}
        >
          {option.label}
        </Chip>
      ))}
    </Styles.ProductOptionWrapper>
  );
}
