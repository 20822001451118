import React from 'react';
import { ProductDetailProps } from 'types/path';
import styled, { css } from 'styled-components';
import { fadeUpAnimation } from 'styles';
import { ESCAPE_MARGIN_INLINE } from 'views/ProductDetail/styles';
import BlockFlexible from './partials/BlockFlexible';
import MultiplierBlock from './partials/MultiplierBlock';

const StyledBlockWrapper = styled.div<{
  animate?: boolean;
}>`
  width: auto;
  position: relative;

  ${ESCAPE_MARGIN_INLINE}

  ${({ animate }) =>
    animate &&
    css`
      animation: 0.6s ease-out 0.3s 1 ${fadeUpAnimation};
      animation-fill-mode: both;
    `}
`;

/**
 * BlockRenderers keys correspond to Contentful Entry types
 * Values correspond to components that get passed the entries
 */
const BlockRenderers = {
  blockFlexible: BlockFlexible,
  multiplierBlock: MultiplierBlock
};

type Props = {
  content: ProductDetailProps['contentful']['variants'][number] | [];
};

export default function PdpBlockRenderer(props: Props) {
  const { content = [] } = props;

  return (
    <div>
      {content.map((container, index) => {
        const blockType = container.block.sys.contentType?.sys.id as keyof typeof BlockRenderers;
        const BlockComponent = BlockRenderers[blockType];
        return (
          BlockComponent && (
            <StyledBlockWrapper animate key={index + '-' + container.block.sys.id}>
              <BlockComponent {...(container.block.fields as any)} key={index + '-' + container.block.sys.id} />
            </StyledBlockWrapper>
          )
        );
      })}
    </div>
  );
}
