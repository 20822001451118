import { IntlShape } from 'react-intl';
import getToriiProductType from './getToriiProductType';
import { materialMessages, shapeMessages } from 'messages/pcpFilters';
import pdpMetaMessages from 'messages/metaContent';
import { ContactLensVariant, Product, ProductVariant } from 'types/torii';

const VALID_PRODUCT_TYPES = ['clip_on', 'giftcard', 'accessory', 'frame'];

export default function getProductMetaContent(
  product: Product,
  currentVariant: ProductVariant | ContactLensVariant,
  intl: IntlShape
) {
  const capitalizeString = (str: string) => str.replace(/\w+/gi, word => word[0].toUpperCase() + word.substring(1));

  const {
    productType,
    name,
    fromPrice: { display }
  } = product;

  const {
    displayAttributes: { color },
    filterOptions: { materials, shapes }
  } = currentVariant;

  const productCategory =
    productType && currentVariant ? getToriiProductType({ intl, productType, variant: currentVariant }) : '';

  let material: string;
  if (materials[0] && materialMessages[materials[0]]) {
    material = intl.formatMessage(materialMessages[materials[0]]);
  } else {
    material = materials[0] || '';
  }

  let shape: string;
  if (shapes[0] && shapeMessages[shapeMessages[0]]) {
    shape = intl.formatMessage(shapeMessages[shapeMessages[0]]);
  } else {
    shape = shapes[0] || '';
  }

  const metaGeneralProps = {
    color,
    material,
    name,
    price: display || '',
    productCategory,
    shape
  };

  const metaTitleProps = {
    ...metaGeneralProps,
    material: capitalizeString(material),
    shape: capitalizeString(shape)
  };

  const metaDescriptionProps = {
    ...metaGeneralProps,
    productCategory: productCategory.toLowerCase() || ''
  };

  const isValidProductType = VALID_PRODUCT_TYPES.some(type => type === productType);

  const metaTitle = isValidProductType
    ? `${intl.formatMessage(pdpMetaMessages.title[productType], { ...metaTitleProps })}`
    : `${name} ${color}`;
  const metaDescription = isValidProductType
    ? `${intl.formatMessage(pdpMetaMessages.description[productType], { ...metaDescriptionProps })}`
    : null;

  return { metaDescription, metaTitle };
}
