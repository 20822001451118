import React from 'react';
import { Typography } from '@aceandtate/ds';
import { FormattedMessage } from 'react-intl';
import { createBenefitsConfigurations } from './benefitsConfig';
import messages from '../../messages';
import * as Styles from '../../styles';

type Props = {
  isSkiGoggles?: boolean;
  isSportsGlasses?: boolean;
  benefitsBlockRef?: React.MutableRefObject<any> | null;
};

export default function BenefitsBlockDesktop({ isSkiGoggles, isSportsGlasses, benefitsBlockRef }: Props) {
  const benefitsConfigurations = createBenefitsConfigurations(isSportsGlasses, isSkiGoggles);

  return (
    <Styles.BenefitsContainer ref={benefitsBlockRef}>
      <Typography variant='h4' gutterBottom={24}>
        <FormattedMessage {...messages.productBenefits} />
      </Typography>
      <Styles.BenefitsWrapper>
        {benefitsConfigurations.map(card => (
          <Styles.BenefitsCardContent key={card.key}>
            <Styles.IconWrapper>{card.icon}</Styles.IconWrapper>
            <Styles.BenefitsCardColumn>
              <Typography variant='h5' fontWeight='regular'>
                {card.title}
              </Typography>
              <Typography variant='bodyM' gutterBottom>
                {card.caption}
              </Typography>
            </Styles.BenefitsCardColumn>
          </Styles.BenefitsCardContent>
        ))}
      </Styles.BenefitsWrapper>
    </Styles.BenefitsContainer>
  );
}
