import React from 'react';
import * as Styles from './styles';

type Props = {
  seoDescription: React.ReactNode;
};

export default function SeoDescription(props: Props) {
  return (
    props.seoDescription
      ? (
        <Styles.Container>{props.seoDescription}</Styles.Container>
      )
      : null
  );
}
