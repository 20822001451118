import { defineMessages } from 'react-intl';

const productsSortTypeMessages = defineMessages({
  aZ: {
    defaultMessage: 'A-Z',
    id: 'products.sort.aZ'
  },
  default: {
    defaultMessage: 'Default',
    id: 'products.sort.default'
  },
  new: {
    defaultMessage: 'New',
    id: 'products.sort.new'
  },
  priceHighLow: {
    defaultMessage: 'Price High-Low',
    id: 'products.sort.price_high_low'
  },
  priceLowHigh: {
    defaultMessage: 'Price Low-High',
    id: 'products.sort.price_low_high'
  },
  zA: {
    defaultMessage: 'Z-A',
    id: 'products.sort.zA'
  }
});

const productsSortGenericMessages = defineMessages({
  sortBy: {
    defaultMessage: 'Sort by',
    id: 'products.sortBy'
  }
});

export default { ...productsSortGenericMessages, ...productsSortTypeMessages };
