import React, { useState, useEffect, useRef, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { ServicesContext } from 'services/context';
import ProductCard from 'components/ProductCard';
import { pcpEvents } from 'tracking';
import * as Styles from '../styles';
import * as Types from '../types';
import messages from '../messages';
import LoadMoreSpacer from './LoadMoreSpacer';
import useVariantLookup from 'utils/hooks/useVariantLookup';
import { Product } from 'types/torii';
import { Typography } from '@aceandtate/ds';

import { ShopstoryGrid } from '@shopstory/react';
import { IrisShopstoryProvider } from '../../../shopstory/IrisShopstoryProvider';

const PRODUCTS_PER_PAGE = 48;

function calculatePages(pages, products: Product[]) {
  const prodTemp = [] as Product[][];
  for (let i = 0; i < products.length; i += PRODUCTS_PER_PAGE) {
    prodTemp.push(products.slice(i, PRODUCTS_PER_PAGE + i));
  }
  return [...prodTemp].slice(0, pages);
}

function isNaiveAboveFold(page: number, index: number) {
  return page === 0 && index < 4;
}

export default function ProductGrid(props: Types.ProductGridProps) {
  const { products, productFilters, page, handleLoadMore } = props;
  const isFirstRun = useRef(true);
  const context = useContext(ServicesContext);
  const [remainingProducts, setRemainingProducts] = useState(products.length - PRODUCTS_PER_PAGE);
  const canLoadMore = remainingProducts > 0;

  const pages = calculatePages(page, products);

  useEffect(() => {
    pcpEvents.productImpression({
      currency: context.webStore.config.currency,
      listType: 'pcp',
      products: pages.flat()
    });
  }, []);

  useEffect(() => {
    setRemainingProducts(products.length - pages.flat().length);
  }, [pages]);

  const findVariants = useVariantLookup(products);

  const gender =
    productFilters && productFilters.gender && productFilters.gender.length === 1
      ? productFilters.gender[0]
      : undefined;

  useEffect(() => {
    // preventing first run from rerendering products
    if (isFirstRun.current) {
      isFirstRun.current = false;
    }
  }, [products]);

  return (
    <div data-testid='pcp.products'>
      {pages.map((pageProducts, pageIndex) => {
        const productCards = pageProducts.map((product, productIndex) => {
          return (
            <ProductCard
              key={product.currentVariant.sku}
              product={product.currentVariant}
              variants={findVariants(product.variantSkus)}
              productType={product.productType}
              gender={gender}
              hasColorPicker
              hasWishlist
              loading={isNaiveAboveFold(pageIndex, productIndex) ? 'eager' : 'lazy'}
              onClick={() =>
                pcpEvents.productClick({
                  currency: context.webStore.config.currency,
                  listType: 'pcp',
                  productType: product.productType,
                  sku: product.sku,
                  variants: product.variants || []
                })
              }
            />
          );
        });

        return (
          <React.Fragment key={pageIndex}>
            {pageIndex > 0 && (
              <Styles.PageGrid columns={{ mobile: 1 }}>
                <Styles.PageLabel key={`pcp-page-label-${pageIndex}`} data-testid={`page-label-${pageIndex + 1}`}>
                  <Typography variant='bodyS'>
                    <FormattedMessage {...messages.pageDivider} values={{ pageCount: pageIndex + 1 }} />
                  </Typography>
                </Styles.PageLabel>
              </Styles.PageGrid>
            )}

            <section key={`pcp-grid-label-${pageIndex}`}>
              {props.shopstoryData && pageIndex === 0 && (
                <IrisShopstoryProvider>
                  <ShopstoryGrid
                    content={props.shopstoryData.content}
                    cards={productCards}
                    meta={props.shopstoryData.meta}
                  />
                </IrisShopstoryProvider>
              )}

              {(!props.shopstoryData || pageIndex > 0) && (
                <Styles.PageGrid columns={{ mobile: 1 }}>
                  <Styles.CardGrid
                    borderless
                    horizontalAlign='left'
                    verticalAlign='top'
                    columns={{ laptop: 4, mobile: 2, tablet: 3 }}
                  >
                    {pageProducts.map((product, productIndex) => (
                      <Styles.CardContainer key={product.currentVariant.sku}>
                        {productCards[productIndex]}
                      </Styles.CardContainer>
                    ))}
                  </Styles.CardGrid>{' '}
                </Styles.PageGrid>
              )}
            </section>
          </React.Fragment>
        );
      })}
      <Styles.PageGrid columns={{ mobile: 1 }}>
        {canLoadMore && <LoadMoreSpacer onLoadMore={handleLoadMore} remainingCount={remainingProducts} />}
        {/* do not remove, spacers needed for responsive views */}
      </Styles.PageGrid>
    </div>
  );
}
