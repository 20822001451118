import styled, { css } from 'styled-components';
import { navHeightDesktop, navHeightMobile, webColors, zIndexMasterList } from 'styles';
import { breakpointRules, grid, brandColors, Typography } from '@aceandtate/ds';
import Visibility from 'components/Visibility';

export const FilterRow = styled(Visibility).attrs({
  displayAs: 'flex',
  layout: 'auto'
})`
  width: 100%;
  flex-wrap: wrap;
  gap: ${grid[12]};
  align-items: center;
  justify-content: flex-start;
  transition: unset;
  position: relative;
  margin-block: ${grid[16]};

  @media ${breakpointRules.laptop} {
    margin-block: ${grid[24]};
  }
`;

export const FilterColumn = styled(Visibility).attrs({ displayAs: 'flex', layout: 'auto' })`
  align-items: center;
  color: ${webColors.medium};
  position: relative;
  gap: ${grid[12]};
  flex-wrap: wrap;

  @media ${breakpointRules.laptop} {
    position: relative;
  }
`;

export const ProductCount = styled(Typography)``;

export const Container = styled.div`
  margin-block: ${grid[24]};
`;

export const VerticalDivider = styled(Visibility).attrs({ displayAs: 'flex', layout: 'auto' })`
  border-left: 1px solid ${brandColors.dark10};
  align-self: stretch;
`;

export const Hr = styled(Visibility).attrs({ as: 'hr' })`
  border: 0;
  border-bottom: 1px solid ${brandColors.dark10};
  margin: 0;
`;

export const Filterbar = styled.div`
  transition: top 0.3s ease;
  width: -webkit-fill-available;
`;

// This function manages the visibility of the filterbar regarding the scroll
function filterbarScrollAnimation(props: GlobalFilterContainerProps, navbarHeight: number) {
  if (props.navVisible) {
    return 0;
  }
  return `${navbarHeight}px`;
}

type GlobalFilterContainerProps = {
  navVisible: boolean;
  filterBarHeight?: number;
  filterBarPosition: 'relative' | 'fixed';
};

export const GlobalFilterContainer = styled.div<GlobalFilterContainerProps>`
  #sort-selector-desktop {
    --max-width: 300px;
    --padding-right: 48px;

    button {
      max-width: var(--max-width);

      &:after {
        display: none;
      }
    }
  }

  #sort-selector-mobile {
    --max-width: 220px;
    --padding-right: 32px;

    button {
      max-width: var(--max-width);
      padding-block: 0;
      padding-left: 0;
      padding-right: var(--padding-right);

      &:after {
        display: none;
      }
    }

    svg {
      right: ${grid[4]};
    }
  }

  ${(props: GlobalFilterContainerProps) => {
    if (props.filterBarPosition === 'fixed') {
      return css`
        /* using pseudo element as border to not impact rendering height */
        transition: all 0.3s ease;
        display: flex;
        width: -webkit-fill-available;

        :after {
          content: '';
          position: relative;
          bottom: 0;
          left: 0;
          width: 100%;
          height: ${props.filterBarHeight}px;
          background: white;
        }

        ${Filterbar} {
          ${(props: GlobalFilterContainerProps) => {
            return css`
              background: white;
              position: ${props.filterBarPosition};
              padding-left: ${grid.padding};
              padding-right: ${grid.padding};
              left: 0;
              right: 0;
              z-index: ${zIndexMasterList.navbar - 1};
              top: ${(props: GlobalFilterContainerProps) => filterbarScrollAnimation(props, navHeightMobile * 2)};

              @media ${breakpointRules.laptop} {
                top: ${(props: GlobalFilterContainerProps) => filterbarScrollAnimation(props, navHeightDesktop)};
              }

              /* condensing Filterbar elems */
              ${FilterRow} {
                margin-block: ${grid[16]};
              }

              button {
                padding-block: ${grid[8]};
              }

              [data-testid='toggle-filters-drawer'] {
                padding-inline: ${grid[24]};
              }

              [data-testid='service-filters-wrapper'] button {
                padding-inline: ${grid[12]};
              }
            `;
          }}
        }
      `;
    }
  }}
`;
