import styled, { css } from 'styled-components';
import GenericLink from 'components/Link';
import * as Types from './types';
import { fadeUpAnimation } from 'styles';
import { RichTextTypographyBody, RichTextTypographyHeading } from 'utils/contentful/styles';
import {
  RichTextTypographyV2Body,
  RichTextTypographyV2Caption,
  RichTextTypographyV2Heading
} from 'utils/contentful/stylesRichTextV2';
import { breakpointRules, ResponsiveInput, Typography } from '@aceandtate/ds';

const paddingMap = {
  l: 80,
  m: 60,
  s: 40,
  xl: 100,
  xs: 20,
  xxl: 120,
  xxs: 0
} as const;

export const Hideable = styled.div<{
  display?: 'flex' | 'block';
  hide?: ResponsiveInput<boolean>;
}>`
  ${({ hide, display = 'block' }) =>
    hide?.mobile !== undefined &&
    css`
      display: ${hide.mobile ? 'none' : display};
    `}

  ${({ hide, display = 'block' }) =>
    hide?.tablet !== undefined &&
    css`
      @media ${breakpointRules.tablet} {
        display: ${hide.tablet ? 'none' : display};
      }
    `}

  ${({ hide, display = 'block' }) =>
    hide?.laptop !== undefined &&
    css`
      @media ${breakpointRules.laptop} {
        display: ${hide.laptop ? 'none' : display};
      }
    `}

  ${({ hide, display = 'block' }) =>
    hide?.desktop !== undefined &&
    css`
      @media ${breakpointRules.desktop} {
        display: ${hide.desktop ? 'none' : display};
      }
    `}
`;

export const BlockContainer = styled.section<Types.LandingPageContainerProps>`
  position: relative;
  background-color: ${props => props.backgroundColor};
  ${({ paddingVertical }) => paddingVertical && `padding: ${paddingMap[paddingVertical]}px 0`};
  ${({ topMargin }) => topMargin && `margin-top: ${paddingMap[topMargin]}px`};
  ${({ bottomMargin }) => bottomMargin && `margin-bottom: ${paddingMap[bottomMargin]}px`};

  ${({ animateTypographies }) =>
    animateTypographies &&
    css`
      ${RichTextTypographyBody},
      ${RichTextTypographyHeading},
      ${RichTextTypographyV2Body},
      ${RichTextTypographyV2Heading},
      ${RichTextTypographyV2Caption} {
        animation: 0.6s ease-out 0.3s 1 ${fadeUpAnimation};
        animation-fill-mode: both;
      }
    `}
`;

export const Link = styled(GenericLink)`
  text-decoration: underline;
`;

export const DebugLabel = styled(Typography)`
  font-size: 12px;
  background: #fff;
  border-radius: 4px;
  position: absolute;
  top: 8px;
  left: 8px;
  line-height: 12px;
  display: block;
  padding: 4px 8px;
  opacity: 0.5;
`;
