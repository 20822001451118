import * as Types from './types';

type ActionType = {
  type: 'newState' | 'filterToggle';
  value: string | Types.AppliedFiltersType;
  group?: string;
};

export function reducer(state: Types.AppliedFiltersType, action: ActionType) {
  const { group, value, type } = action;

  if (type === 'newState') {
    return value as Types.AppliedFiltersType;
  }

  if (type === 'filterToggle') {
    const target = typeof state[group] === 'string' ? [state[group]] : state[group];
    const result = target.includes(value) ? target.filter(item => item !== value) : target.concat(value);

    return {
      ...state,
      [group]: result
    };
  }

  return state;
}
