import { defineMessages } from 'react-intl';

export default defineMessages({
  available: {
    defaultMessage: 'Available',
    id: 'storeStockAvailability.available'
  },
  bookAFreeEyeTest: {
    defaultMessage: 'Book a free eye test',
    id: 'storeStockAvailability.bookAFreeEyeTest'
  },
  checkOtherStores: {
    defaultMessage: 'Check other stores',
    id: 'storeStockAvailability.checkOtherStores'
  },
  closesAt: {
    defaultMessage: 'Closes at {time}',
    id: 'storeStockAvailability.closesAt'
  },
  noStockInfo: {
    defaultMessage:
      "Sorry, we're out of this product right now. You can check if this product is available at another store or explore other styles",
    id: 'storeStockAvailability.noStockInfo'
  },
  onlineExclusive: {
    defaultMessage: 'Not available in stores - <b>Online Exclusive</b>',
    id: 'storeStockAvailability.onlineExclusiveFlow'
  },
  onlyAvailableInStores: {
    defaultMessage: 'Check in-store availability',
    id: 'storeStockAvailability.availableInAStore'
  },
  onlyShowStoresWithStock: {
    defaultMessage: 'Only show stores with stock',
    id: 'storeStockAvailability.onlyShowStoresWithStock'
  },
  opensAt: {
    defaultMessage: 'Opens at {time}',
    id: 'storeStockAvailability.opensAt'
  },
  preferredStoreCta: {
    defaultMessage: 'Select as preferred store',
    id: 'storeStockAvailability.preferredStoreCta'
  },
  sameDayServiceFootnote: {
    defaultMessage:
      '*Availability of this service depends on the frame and lens stock at the store, your unique prescription and lens choice.',
    id: 'storeStockAvailability.sameDayServiceFootnote'
  },
  sameDayServiceInfo: {
    defaultMessage: 'Need prescription (sun)glasses ASAP? We got you - we offer <u>{link}</u>* at this store.',
    id: 'storeStockAvailability.sameDayServiceInfo'
  },
  searchErrorMessage: {
    defaultMessage: "Sorry, we couldn't find a store near you. Check store suggestions below or try another address.",
    id: 'storeStockAvailability.searchErrorMessage'
  },
  searchStoresPlaceholder: {
    defaultMessage: 'Search by zip code or city',
    id: 'storeStockAvailability.searchStoresPlaceholder'
  },
  seeStoreDetails: {
    defaultMessage: 'See store details',
    id: 'storeStockAvailability.seeStoreDetails'
  },
  selectStoreCta: {
    defaultMessage: 'Select this store',
    id: 'storeStockAvailability.selectStoreCta'
  },
  subHeader: {
    defaultMessage:
      'Want to try out this frame before making up your mind? Check its availability at your favorite store.',
    id: 'storeStockAvailability.subHeader'
  },
  storeAvailability: {
    defaultMessage: 'Store availability',
    id: 'storeStockAvailability.storeAvailability'
  },
  checkInStoreAvailability: {
    defaultMessage: 'Check in-store availability',
    id: 'storeStockAvailability.checkInStoreAvailability'
  },
  checkOtherStoresAvailability: {
    defaultMessage: 'Check other stores availability',
    id: 'storeStockAvailability.checkOtherStoresAvailability'
  },
  unavailable: {
    defaultMessage: 'Unavailable',
    id: 'storeStockAvailability.unavailable'
  },
  availableAt: {
    defaultMessage: 'Available at <u>{storeStreet}</u>',
    id: 'storeStockAvailability.availableAtStreetWithIndicator'
  },
  unavailableAt: {
    defaultMessage: 'Unavailable at <u>{storeStreet}</u>',
    id: 'storeStockAvailability.unavailableAtStreetWithIndicator'
  },
  onlyAvailableOnline: {
    defaultMessage: 'Currently only available online',
    id: 'storeStockAvailability.onlyAvailableOnline'
  },
  outOfStock: {
    defaultMessage: 'Currently unavailable',
    id: 'storeStockAvailability.outOfStock'
  }
});
