import styled from 'styled-components';
import { webColors } from 'styles';
import { Typography, Cell, Grid, grid, breakpointRules, brandColors, Button } from '@aceandtate/ds';
import * as Types from './types';

export const PageGrid = styled(Grid)`
  row-gap: 0;
`;

export const CardGrid = styled(Grid)`
  margin-left: calc(-1 * ${grid.padding});
  margin-right: calc(-1 * ${grid.padding});
  margin-bottom: ${grid[80]};

  @media ${breakpointRules.tablet} {
    margin: 0 0 ${grid[80]};
  }
`;

export const FilterRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${grid[24]};
  width: 100%;
  justify-content: space-between;
`;

export const Container = styled.div`
  margin-block: ${grid[16]};

  @media ${breakpointRules.laptop} {
    margin-block: ${grid[24]};
  }
`;

export const CardContainer = styled(Cell)`
  margin-bottom: ${grid[40]};
`;

export const DrawerCategory = styled.div`
  margin-top: ${grid[40]};
  margin-bottom: ${grid[40]};
`;

export const DrawerHeading = styled(Typography)`
  margin-bottom: ${grid[40]} !important;
`;

export const BannerContent = styled.div<Types.BannerContentProps>`
  color: ${props => props.color};
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

export const LoadMoreContainer = styled.div`
  display: flex;
  margin-left: calc(${grid.padding} * -1);
  width: 100vw;
  text-align: center;
  margin-bottom: 2rem;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-top: 2rem;
  margin-bottom: 4rem;
  position: relative;

  :before {
    content: '';
    width: 100px;
    flex-grow: 1;
    margin-right: ${grid[40]};
    background: ${webColors.highlightPrimaryLight};
    height: 1px;
  }

  :after {
    content: '';
    width: 100px;
    flex-grow: 1;
    margin-left: ${grid[40]};
    background: ${webColors.highlightPrimaryLight};
    height: 1px;
  }

  @media ${breakpointRules.tablet} {
    width: 100%;
    margin-left: auto;
  }
`;

export const LoadMoreIndicator = styled.div`
  position: absolute;
  color: ${webColors.medium};
  white-space: pre-line;
  bottom: ${grid[60]};
  text-align: center;
  width: 100%;

  @media ${breakpointRules.laptop} {
    right: 0;
    max-width: 250px;
    bottom: ${grid[24]};
    text-align: right;
    margin-bottom: ${grid[8]};
  }
`;

export const PageLabel = styled.div`
  margin-top: ${grid[24]};
  margin-bottom: ${grid[12]};
  text-align: right;
`;

export const NoResultsPlaceholder = styled.div`
  margin-top: ${grid[40]} 0;
  padding-bottom: ${grid[40]};
  color: ${webColors.light};

  @media ${breakpointRules.laptop} {
    margin: ${grid[80]} 0 ${grid[120]};
    padding-bottom: ${grid[120]};
  }
`;

export const LoadingOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: ${grid[40]};
`;

export const LabelWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: ${grid[8]};
`;

export const IconWrapper = styled.div`
  padding: 1px;
  border-radius: 50%;
  display: flex;
  background: ${brandColors.dark};
`;

export const TooltipContentWrapper = styled.div`
  display: inherit;
  padding: ${grid[8]};
`;

export const FilterCtaButton = styled(Button)`
  padding-inline: 32px;

  * {
    line-height: inherit;
  }
`;
