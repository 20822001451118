import { defineMessages } from 'react-intl';

export default defineMessages({
  aboutLensDolomite: {
    defaultMessage:
      'Eddie in Dolomite has a silver mirror lens, which cuts glare and offers great optics for bright, sunny days. Switch to the extra amber lens in overcast, cloudy and low-light conditions.',
    id: 'pdp.usps.aboutLens.dolomite'
  },
  aboutLensPisteBlack: {
    defaultMessage:
      'Eddie in Piste Black has a black lens, which is best for the brightest sunny days. Avoid using this lens in flat light conditions and during night skiing. Switch to the extra amber lens in overcast, cloudy and low-light conditions.',
    id: 'pdp.usps.aboutLens.pisteBlack'
  },
  aboutLensSwissBlue: {
    defaultMessage:
      'Eddie in Swiss Blue has a blue mirror lens, which cuts glare and offers great optics for bright days as well as partly clouded skies. Switch to the extra amber lens in overcast, cloudy and low-light conditions.',
    id: 'pdp.usps.aboutLens.swissBlue'
  },
  aboutTheLens: {
    defaultMessage: 'About the lens',
    id: 'pdp.usps.aboutTheLens'
  },
  alsoAvailable: {
    defaultMessage: 'Also available: {availableSizes}',
    id: 'pdp.usps.details.alsoAvailable'
  },
  antiScratch: {
    defaultMessage: 'Anti-scratch lens coating',
    id: 'pdp.usps.included.antiScratch'
  },
  availableSizes: {
    defaultMessage: 'This frame is also available in {availableSizes} fit',
    id: 'pdp.usps.details.availableSizes'
  },
  caseAndCloth: {
    defaultMessage: 'Responsible {caseAndClothLink}',
    id: 'pdp.usps.included.caseAndCloth'
  },
  caseAndClothLink: {
    defaultMessage: 'case & cloth',
    id: 'pdp.usps.included.caseAndClothLink'
  },
  caseKids: {
    defaultMessage: 'A felt pouch made of Rpet with the logo made of nylon.',
    id: 'pdp.usps.included.kids.case'
  },
  cliponsAvailableAcetate: {
    defaultMessage: 'Clip-ons available for this frame. {cliponsLink}',
    id: 'pdp.usps.details.cliponsAvailableAcetate'
  },
  cliponsAvailableMetal: {
    defaultMessage: 'Clip-ons available for most colours. {cliponsLink}',
    id: 'pdp.usps.details.cliponsAvailableMetal'
  },
  cliponsLink: {
    defaultMessage: 'What are clip-ons?',
    id: 'pdp.usps.details.cliponsLink'
  },
  details: {
    defaultMessage: 'details',
    id: 'pdp.usps.details'
  },
  digitalShipping: {
    defaultMessage:
      'This is a digital product, it will be delivered straight to your email inbox after the order has been placed.',
    id: 'pdp.digitalShipping'
  },
  dimensions: {
    defaultMessage: 'Frame dimension are {dimensions}',
    id: 'usps.pdp.detailText.dimensions'
  },
  drivingWarning: {
    defaultMessage: 'Not suitable for driving',
    id: 'pdp.usps.details.drivingWarning'
  },
  extraFeatBobby: {
    defaultMessage: `<li>Sophisticated, sporty style</li>`,
    id: 'pdp.usps.featuresList.extraFeatBobby'
  },
  extraFeatQuinn: {
    defaultMessage: `<li>Wrapped, retro fit</li>`,
    id: 'pdp.usps.featuresList.extraFeatQuinn'
  },
  extraFeatTom: {
    defaultMessage: `<li>Wider, oversized fit</li>`,
    id: 'pdp.usps.featuresList.extraFeatTom'
  },
  featuresListSkiGoggles: {
    defaultMessage: `
    <li>Comes with an extra amber lens for cloudy days</li>
    <li>Easy click-in function to swap lenses</li>
    <li>Lens offers 100% UV protection</li>
    <li>Double-layered to reduce fogging</li>
    <li>Adjustable strap with logo</li>
    <li>Comes with a case & lens cleaning cloth</li>
    `,
    id: 'pdp.usps.featuresList.skiGoggles'
  },
  featuresListSportsGlasses: {
    defaultMessage: `
    <li>3D printed in Europe</li>
    <li>Ultra-lightweight fit and feel</li>
    <li>Nylon Polarised Lenses for better vision</li>
    <li>Function grips on the temple tips for grip</li>
    <li>Removable, sweat-wicking nose pads</li>
    <li>Comes with a GRS certified rPet pouch, to keep them scratch-free</li>
    `,
    id: 'pdp.usps.featuresList.sportsGlasses'
  },
  fit: {
    defaultMessage: 'fit',
    id: 'pdp.usps.fittingGuide.fit'
  },
  fittingGuide: {
    defaultMessage: 'Description & fitting guide',
    id: 'pdp.usps.fittingGuide'
  },
  flexHingesKids: {
    defaultMessage: 'All the frames are also provided with flex hinges to make them easy to wear for children.',
    id: 'pdp.usps.details.kids.frame.flexHinges'
  },
  frameDimensions: {
    defaultMessage: 'Frame dimensions',
    id: 'usps.pdp.fittingGuide.frameDimensions'
  },
  notReturnable: {
    defaultMessage: 'Return not available',
    id: 'pdp.usps.included.notReturnable'
  },
  freeReturnsTag: {
    defaultMessage: '30 days no questions asked free returns',
    id: 'pdp.usps.included.freeReturnsTag'
  },
  freeShipping: {
    defaultMessage: 'Free shipping',
    id: 'pdp.usps.freeShippingToLink'
  },
  freeShippingFrom: {
    defaultMessage: 'Free shipping from {price}',
    id: 'pdp.usps.freeShippingFrom'
  },
  front: {
    defaultMessage: 'front',
    id: 'usps.pdp.detailText.dimensions.front'
  },
  guaranteeNewLink: {
    defaultMessage: 'What does the guarantee cover?',
    id: 'pdp.usps.included.guaranteeNewLink'
  },
  guaranteeText: {
    defaultMessage: 'Two-year guarantee on frame & lenses.{br}{guaranteeNewLink}',
    id: 'pdp.usps.included.guaranteeText'
  },
  included: {
    defaultMessage: 'Included',
    id: 'pdp.usps.included'
  },
  kidsAgeBracketTag: {
    defaultMessage: 'For kids between four and eight',
    id: 'pdp.usps.details.kids.frames.ageBracketTag'
  },
  largeFitTag: {
    defaultMessage: 'Perfect for wider faces',
    id: 'pdp.usps.fittingGuide.largeFitTag'
  },
  materialListSkiGoggles: {
    defaultMessage: `
    <li>Lens: Polycarbonate outer lens with Cellulose Propionate inner lens</li>
    <li>Rim: TPU (Thermoplastic Urethane). TPU is lightweight, flexible and strong, so it helps with frame comfort, fit and impact resistance, strength and durability</li>
    <li>Foam: 3 layers/triple density foam</li>
    `,
    id: 'pdp.usps.materialsList.skiGoggles'
  },
  materialListBioSkiGoggles: {
    defaultMessage: `
    <li>Lens: Polycarbonate outer lens with Cellulose Propionate inner lens</li>
    <li>21% bio-based TPU and 79% TPU (Thermoplastic Urethane). TPU is lightweight, flexible and strong, so it helps with frame comfort, fit and impact resistance, strength and durability</li>
    <li>Foam: 3 layers/triple density foam</li>
    `,
    id: 'pdp.usps.materialsList.bioSkiGoggles'
  },
  materialListSportsGlasses: {
    defaultMessage: `
    <li>Frame: lightweight, flexible and durable PA11 — also known as Nylon, a biobased polymer. The raw material is extracted from Caster beans, from Castor Plants.</li>
    <li>Lens: Polarised, non-prescription lenses. Category 2.</li>
    `,
    id: 'pdp.usps.materialsList.sportsGlasses'
  },
  materialText: {
    defaultMessage: 'The frame is made from high quality {materials}',
    id: 'usps.pdp.detailText.material'
  },
  mediumFitTag: {
    defaultMessage: 'Perfect for regular-sized faces',
    id: 'pdp.usps.fittingGuide.mediumFitTag'
  },
  profile: {
    defaultMessage: 'profile',
    id: 'usps.pdp.detailText.dimensions.profile'
  },
  responsibilityTitle: {
    defaultMessage: 'Responsibility',
    id: 'pdp.usps.responsibilityTitle'
  },
  returnPolicy: {
    defaultMessage: '30 days {returnPolicyLink}',
    id: 'pdp.usps.returnPolicy'
  },
  returnPolicyLink: {
    defaultMessage: 'return policy',
    id: 'pdp.usps.returnPolicyLink'
  },
  returnPolicyWithFreeReturns: {
    defaultMessage: '30 days return policy & free returns',
    id: 'usps.pdp.returnPolicyWithFreeReturns.title'
  },
  skiGogglesFeatures: {
    defaultMessage: 'Product features',
    id: 'pdp.usps.productFeatures.skiGoggles'
  },
  skiGogglesAboutTheLens: {
    defaultMessage:
      'The lenses cut glare and offer great optics for bright days as well as partly clouded skies. Switch to the extra amber lens in overcast, cloudy and low-light conditions.',
    id: 'pdp.usps.aboutTheLens.skiGoggles'
  },
  skiGogglesMaterials: {
    defaultMessage: 'Materials',
    id: 'pdp.usps.productMaterials.skiGoggles'
  },
  warningsTitle: {
    defaultMessage: 'Warnings:',
    id: 'pdp.warnings.title'
  },
  skiGogglesWarningDrivingRoadUse: {
    defaultMessage: 'Not for driving or road use.',
    id: 'pdp.warnings.skiGoggles.drivingRoadUse'
  },
  skiGogglesWarningDirectSun: {
    defaultMessage: 'Not for direct observations of the sun.',
    id: 'pdp.warnings.skiGoggles.directSun'
  },
  skiGogglesWarningArtificialLight: {
    defaultMessage: 'Not for protection against artificial light sources eg. Solaria.',
    id: 'pdp.warnings.skiGoggles.artificialLight'
  },
  skiGogglesWarningImpactHazards: {
    defaultMessage: 'Not for protection against mechanical hazards such as impact.',
    id: 'pdp.warnings.skiGoggles.impactHazards'
  },
  smallFitTag: {
    defaultMessage: 'Perfect for narrow faces',
    id: 'pdp.usps.fittingGuide.smallFitTag'
  },
  superThinLenses: {
    defaultMessage: 'Super thin prescription lenses',
    id: 'pdp.usps.included.superThinLenses'
  },
  sustainability: {
    defaultMessage: 'Carbon impact: {carbonFootprint}',
    id: 'usps.pdp.details.carbonFootprint'
  },
  width: {
    defaultMessage: 'This is a {width} frame',
    id: 'pdp.usps.details.size'
  }
});
