import React from 'react';
import FullscreenLoader from 'components/Loaders/FullscreenLoader';
import V2LandingPageView from 'views/V2LandingPage';
import LandingPageView from 'views/LandingPage';
import ProductCollectionView from 'views/ProductCollection';
import LegalPageView from 'views/LegalPage';
import ProductDetailView from 'views/ProductDetail';
import { CatchAllProps } from 'types/path';
import { useRouter } from 'next/router';

export function PageRenderer(props: CatchAllProps) {
  const router = useRouter();

  if (router.isFallback) {
    return <FullscreenLoader />;
  }
  if (props.type === 'v2landing') {
    return <V2LandingPageView {...props} />;
  }
  if (props.type === 'landing') {
    return <LandingPageView {...props} />;
  }
  if (props.type === 'collection') {
    return <ProductCollectionView {...props} />;
  }
  if (props.type === 'legal') {
    return <LegalPageView {...props} />;
  }
  if (props.type === 'product') {
    return <ProductDetailView {...props} />;
  }

  return null;
}
