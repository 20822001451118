import widthIcon from './icons/width.svg';
import roundIcon from './icons/round.svg';
import squareIcon from './icons/square.svg';
import ovalIcon from './icons/oval.svg';
import hexagonalIcon from './icons/hexagonal.svg';
import rectangleIcon from './icons/rectangle.svg';
import { IconGlasses, IconPalette, IconShapes } from '@aceandtate/ds';

export const titleIconMap = {
  widths: widthIcon
};

export const filterIconMap = {
  colors: IconPalette,
  shapes: IconShapes,
  widths: IconGlasses
};

export const filterIconSrcMap = {
  hexagonal: hexagonalIcon,
  oval: ovalIcon,
  rectangular: rectangleIcon,
  round: roundIcon,
  square: squareIcon
};

export const filterColorMap = {
  beige: '#EBDDC9',
  black: '#1e1e1e',
  blue: '#0082E9',
  brown: '#9F6C46',
  clear: '#f4f7f6',
  gold: '#EFD07D',
  green: '#A5B749',
  grey: '#D4D4D6',
  orange: '#ED8051',
  pink: '#F380B5',
  purple: '#C4A0E0',
  red: '#E73131',
  silver: '#B4B5B8',
  white: '#ffffff',
  yellow: '#f9df57'
} as const;

export type FilterColorOptions = keyof typeof filterColorMap;

export type FilterColorValues = (typeof filterColorMap)[FilterColorOptions];
