import React from 'react';
import { brandColors, Flex, grid, Tooltip, Typography, IconInfo } from '@aceandtate/ds';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import messages from '../../messages';
import ProductOptions from '.';
import { Product } from 'types/torii';
import { useRouter } from 'next/router';

type Props = {
  product: Product;
  tooltip?: MessageDescriptor;
};

function getProductSize(sku: string) {
  if (sku.includes('-small-')) {
    return 'Small';
  } else {
    return 'Large';
  }
}

export default function ProductSizeToggle(props: Props) {
  const { product, tooltip } = props;
  const router = useRouter();

  const currentSize = getProductSize(product.currentVariant.sku);

  const availableOptions = product.familyVariants.map(variant => variant.size);
  const productOptions = [
    {
      label: (
        <Typography variant='bodyM' as='span' fontWeight='regular'>
          <FormattedMessage {...messages.productSizeSmall} />
        </Typography>
      ),
      value: 'Small'
    },
    {
      label: (
        <Typography variant='bodyM' as='span' fontWeight='regular'>
          <FormattedMessage {...messages.productSizeLarge} />
        </Typography>
      ),
      value: 'Large'
    }
  ];
  const filteredOptions = productOptions.filter(option => availableOptions.includes(option.value));

  function setSize(size: 'Small' | 'Large') {
    const sizeVariant = product.familyVariants.find(variant => variant.size === size);
    sizeVariant && router.replace(sizeVariant.sku);
  }

  if (!filteredOptions || filteredOptions.length <= 0) {
    return null;
  }

  return (
    <>
      {tooltip ? (
        <Flex gap={grid[4]} alignItems='center'>
          <Typography variant='bodyM' gutterBottom>
            <FormattedMessage {...messages.productSizeTitle} />
          </Typography>
          <Tooltip content={<FormattedMessage {...tooltip} />}>
            <Flex>
              <Typography variant='bodyM' gutterBottom>
                <IconInfo color={brandColors.dark30} style={{ height: '100%' }} />
              </Typography>
            </Flex>
          </Tooltip>
        </Flex>
      ) : (
        <Typography variant='bodyM' gutterBottom>
          <FormattedMessage {...messages.productSizeTitle} />
        </Typography>
      )}

      <ProductOptions selectedOption={currentSize} setSelectedOption={setSize} options={filteredOptions} />
    </>
  );
}
