import styled from 'styled-components';
import { grid, brandColors, GridSize } from '@aceandtate/ds';

type ColorSwatchProps = {
  color: string;
};

export const ColorSwatch = styled.span<ColorSwatchProps>`
  border-color: ${props => (props.color === '#ffffff' ? brandColors.dark30 : 'transparent')};
  background: ${props => props.color};
  border-width: 1px;
  border-style: solid;
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;

export const CategoryIcon = styled.img`
  margin-left: ${grid[12]};
  width: 50px;
`;

export const ChipIcon = styled.img`
  max-width: 35px;
  max-height: 10px;
`;

export const DrawerContainer = styled.div`
  padding: ${grid[60]} ${grid[24]};
`;

export const FilterBlocksWrapper = styled.div`
  display: grid;
  gap: ${grid[60]};
`;

export const FilterBlockContainer = styled.div`
  display: grid;
  gap: ${grid[24]};
`;
export const FilterGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${grid[12]};

  div {
    display: flex;
    gap: ${grid[4]};
  }
`;

export const ActionBarContainer = styled.div`
  display: flex;
  gap: ${grid[12]};
  padding: ${grid[12]};
  width: -webkit-fill-available;
`;

type PositionSets = 'topRight' | 'topLeft' | 'bottomRight' | 'bottomLeft';

type IconWrapperProps = {
  gutterBottom?: GridSize;
  onClose?: boolean;
} & {
  [key in PositionSets]?: GridSize;
};

// FIXME: Move Styles to Global Component Files?
export const IconWrapper = styled.div<IconWrapperProps>`
  padding: ${grid[8]};
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  position: absolute;
  top: ${({ topRight, topLeft }) => grid[topRight || topLeft]};
  right: ${({ topRight, bottomRight }) => grid[topRight || bottomRight]};
  bottom: ${({ bottomRight, bottomLeft }) => grid[bottomRight || bottomLeft]};
  left: ${({ topLeft, bottomLeft }) => grid[topLeft || bottomLeft]};

  :hover {
    background: ${brandColors.dark10};
  }
`;
