import { createContext } from 'react';

type RenderContext = {
  isContentfulPreview: boolean;
  isInspectorActive: boolean;
};

const RenderContext = createContext<RenderContext>({
  isContentfulPreview: false,
  isInspectorActive: false
});

export default RenderContext;
