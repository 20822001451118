import { Typography } from '@aceandtate/ds';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../../messages';

export default function HardCaseEOLModal() {
  return (
    <Typography>
      <FormattedMessage {...messages.hardCaseEOLDescription} />
    </Typography>
  );
}

HardCaseEOLModal.buttonText = messages.hardCaseEOLLink;
