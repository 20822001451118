import { captureException } from '@sentry/nextjs';
import { Entry, Asset } from 'contentful';
import { CatalogOptions, getAllVariants } from 'services/productsService';
import { configureCollection } from '@aceandtate/toolkit-lib';

import {
  BlockFlexibleExtended,
  ContentType,
  LandingPage,
  LandingPageCollectionBlock,
  MultiplierBlockExtended,
  Page,
  ShopstoryBlock,
  UrlRoute
} from 'types/contentful';
import { Locale } from 'types/locale';
import { Product } from 'types/torii';
import { ShopstoryClient, RenderableContent } from '@shopstory/core';
import { filterProductVisibility } from 'utils/helpers/filterProductVisiblity';
import { AsyncReturnType } from 'type-fest';
import { getProductDetailBlocks } from 'services/contentful';
import productRulesMap from 'utils/product/productRules';
import { AlternatePath } from 'types/path';
import { AssetFragment, UrlRouteCollectionQuery } from 'services/generated/graphql/graphql';

const contentfulToToriiMap = {
  base: '98',
  glasses: 'optical',
  premium: '148',
  sunglasses: 'sunny'
};

const applyFilterOptions = (products: Product[], types: string[], key: string) => {
  return products.filter(product =>
    types.some(type => {
      const mappedType = contentfulToToriiMap[type] || type;
      return product.currentVariant.filterOptions[key]?.includes(mappedType);
    })
  );
};

const filterActions = {
  color(products: Product[], types: string[]) {
    return applyFilterOptions(products, types, 'colorTypes');
  },
  color_pattern(products: Product[], types: string[]) {
    return applyFilterOptions(products, types, 'colorPatterns');
  },
  contains_colors(products: Product[], types: string[]) {
    return applyFilterOptions(products, types, 'colors');
  },
  hto(products: Product[], types: string[]) {
    const showHto = types.includes('yes');
    return products.filter(
      product =>
        product.currentVariant.availability.isAvailableHTO === showHto &&
        product.currentVariant.availability.isAvailableOnline
    );
  },
  material(products: Product[], types: string[]) {
    return applyFilterOptions(products, types, 'materials');
  },
  price(products: Product[], types: string[]) {
    return applyFilterOptions(products, types, 'prices');
  },
  product_type(products: Product[], types: string[]) {
    return products.filter(product =>
      types.some(type => {
        const mappedType = contentfulToToriiMap[type] || type;
        return product.productType === mappedType;
      })
    );
  },
  shapes(products: Product[], types: string[]) {
    return applyFilterOptions(products, types, 'shapes');
  },
  style(products: Product[], types: string[]) {
    return applyFilterOptions(products, types, 'styles');
  },
  type(products: Product[], types: string[]) {
    return applyFilterOptions(products, types, 'types');
  },
  width(products: Product[], types: string[]) {
    return applyFilterOptions(products, types, 'widths');
  },
  include_taxons(products: Product[], types: string[]) {
    return products.filter(product => product.currentVariant.filterOptions.taxons?.some(x => types.includes(x)));
  },
  exclude_taxons(products: Product[], types: string[]) {
    return products.filter(product => product.currentVariant.filterOptions.taxons?.every(x => !types.includes(x)));
  }
};

export function applyFilters(products: Product[], contentfulFilters: any) {
  let filteredProducts = products;
  for (const key in contentfulFilters) {
    if (filterActions[key]) {
      filteredProducts = filterActions[key](filteredProducts, contentfulFilters[key]);
    }
  }

  return filteredProducts;
}

type CollectionValues = {
  legacyCollection: readonly string[] | null;
  legacyFilters: any;
  productSorting?: Parameters<typeof configureCollection.execute>[1];
};

export async function getFilteredCollection(
  locale: Locale,
  { legacyCollection = [], legacyFilters, productSorting }: CollectionValues,
  options: CatalogOptions = {}
) {
  let products: Product[] = [];
  const catalog = await getAllVariants(locale, {
    draftMode: options.draftMode,
    source: options.source
  });

  if (!productSorting && legacyFilters && Object.keys(legacyFilters).length > 0) {
    products = applyFilters(catalog, legacyFilters);
  } else {
    products = configureCollection.execute(catalog as any, {
      pinnedSkus: legacyCollection.reduce((acc, prod, i) => {
        return { ...acc, [i]: prod };
      }, {}),
      ...productSorting
    }) as any;
  }

  if (legacyCollection) {
    // sku based collection pages may also show pdp only products
    products = filterProductVisibility(products, ['pcp', 'pdp']);
  } else {
    products = filterProductVisibility(products, ['pcp']);
  }

  return products;
}

export function getMetaImageUrl(asset: Asset | AssetFragment) {
  if ('url' in asset) {
    return `${asset.url}?q=90&w=1200`;
  }
  if ('fields' in asset) {
    return `${asset.fields.file.url}?q=90&w=1200`;
  }
}

export type LandingPageBlockFlat = {
  contentType: ContentType;
  id: string;
  [key: string]: any;
  blocks: Array<{
    contentType: ContentType;
    id: string;
    [key: string]: any;
    blocks: Array<{
      contentType: ContentType;
      id: string;
      [key: string]: any;
    }>;
  }>;
};

export type RootLevelBlock = {
  id: string;
  data: LandingPageBlockFlat | RenderableContent;
};

export type BlockType = Entry<LandingPageCollectionBlock>;

export function flattenBlock(block: BlockType): LandingPageBlockFlat | null {
  try {
    return {
      contentType: block.sys.contentType.sys.id as ContentType,
      id: block.sys.id,
      ...block.fields,
      blocks: [...(block.fields.blocks || [])].filter(Boolean).map(block => {
        return {
          contentType: block.sys.contentType.sys.id as ContentType,
          id: block.sys.id,
          ...block.fields,
          blocks: [...((block as BlockType).fields.blocks || [])].filter(Boolean).map(block => {
            return {
              contentType: block.sys.contentType.sys.id as ContentType,
              id: block.sys.id,
              ...block.fields
            };
          })
        };
      })
    };
  } catch (e) {
    !isUnlinkedEntry(block) && captureException(e);
    return null;
  }
}

const isUnlinkedEntry = (block: BlockType) => {
  const { type, linkType } = block.sys as any;
  return !block.fields && type === 'link' && linkType === 'Entry';
};

const isShopstoryBlock = (item: BlockType | Entry<ShopstoryBlock>): item is Entry<ShopstoryBlock> => {
  return item.sys.contentType?.sys.id === 'shopstoryBlock';
};

export function processBlock(
  entry: BlockType | Entry<ShopstoryBlock>,
  shopstoryClient: ShopstoryClient
): RootLevelBlock | null {
  const { id } = entry.sys;
  let block: LandingPageBlockFlat | RenderableContent | null;

  if (isShopstoryBlock(entry)) {
    block = shopstoryClient.add(entry.fields.config);
  } else {
    block = flattenBlock(entry);
  }

  if (block === null) {
    return null;
  }

  return {
    data: block,
    id
  };
}

export function processProductEnrichedBlocks(
  productDetailBlocks: AsyncReturnType<typeof getProductDetailBlocks>,
  product: Product
) {
  return productDetailBlocks
    .map(block => {
      // get first enrichedBlock reference where product rule matches product
      const enrichedBlock = block.fields.references.find(
        reference =>
          productRulesMap[reference.fields.productRule] && productRulesMap[reference.fields.productRule](product)
      );

      const displayBlock = enrichedBlock &&
        enrichedBlock.fields.block.fields && {
          rule: enrichedBlock.fields.productRule,
          name: enrichedBlock.fields.name,
          block: enrichedBlock.fields.block as Entry<BlockFlexibleExtended | MultiplierBlockExtended>
        };
      return displayBlock;
    })
    .filter(Boolean);
}

/**
 * flattenEntry does what it name says,
 * and it's required in order to maintain valid typescript types.
 * Ugly but necessary
 */

export function flattenEntry(entry: Entry<LandingPage | Page>, shopstoryClient: ShopstoryClient) {
  if (!entry) {
    throw new Error('Missing entry');
  }

  const blocks: Array<RootLevelBlock> = (entry.fields.blocks || [])
    .map((block: BlockType | Entry<ShopstoryBlock>) => processBlock(block, shopstoryClient))
    .filter(Boolean);

  return {
    blocks,
    menuTextColor: 'menuTextColor' in entry.fields ? entry.fields.menuTextColor : undefined,
    metaDescription: entry.fields.metaDescription,
    metaImage: entry.fields.metaImage ? getMetaImageUrl(entry.fields.metaImage) : null,
    metaTitle: entry.fields.metaTitle
  };
}

// This one was a pain to write, but in essence it allows any entry containing blocks to
// have it's correct flattened form extracted. This is necessary to reuse the
// Contentful generated types
export type FlattenedBlocksEntry<T> = T extends Entry<any>
  ? Pick<T['fields'], Exclude<keyof T['fields'], 'blocks'>> & {
      contentType: T['sys']['contentType']['sys']['id'];
      id: T['sys']['id'];
      blocks: T['fields']['blocks'] extends ReadonlyArray<infer B> ? ReadonlyArray<FlattenedBlocksEntry<B>> : unknown;
    }
  : never;

export type Flattened<Fields> = FlattenedBlocksEntry<Entry<Fields>>;

// order of array is configured in our contentful extension as per below:
// 0: en
// 1: nl
// 2: de
// 3: fr
// 4: es
export function makeAlternateHrefs(
  urlRoute: UrlRoute | UrlRouteCollectionQuery['urlRouteCollection']['items'][0]
): AlternatePath {
  function getValueFromIndex(index: number) {
    return urlRoute.intlPaths?.[index] || urlRoute.path;
  }

  return {
    en: getValueFromIndex(0),
    nl: getValueFromIndex(1),
    de: getValueFromIndex(2),
    fr: getValueFromIndex(3),
    es: getValueFromIndex(4)
  };
}
