import React, { JSXElementConstructor, ReactElement } from 'react';
import { Tooltip, Typography } from '@aceandtate/ds';
import * as Styles from '../../styles';

export type TooltipConfig = {
  id: string;
  label: JSX.Element;
  text: JSX.Element;
};

type FilterTooltipProps = {
  config: TooltipConfig;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
};

export default function TooltipWrapper({ config, children }: FilterTooltipProps) {
  return (
    <Tooltip
      content={
        <Styles.TooltipContentWrapper>
          <Typography variant='h5' gutterBottom={8} textAlign='left'>
            {config.label}
          </Typography>
          <Typography variant='bodyM' textAlign='left'>
            {config.text}
          </Typography>
        </Styles.TooltipContentWrapper>
      }
      width='350px'
      placement='bottom-start'
    >
      {children}
    </Tooltip>
  );
}
