import React from 'react';
import Contentful from 'blocks/Contentful';
import CustomHead from 'blocks/CustomHead';
import { IrisShopstoryProvider } from '../../shopstory/IrisShopstoryProvider';
import { ShopstoryMetadataProvider } from '@shopstory/react';
import { useUncompressShopstoryMetadata } from 'shopstory/helpers/compressShopstoryMetadata';
import { CommonPageProps, LandingPageExtraProps } from 'types/path';

export default function LandingPage(props: LandingPageExtraProps & CommonPageProps) {
  const { alternatePaths, urlRoute, landingPage } = props;
  const { metaImage, metaDescription, metaTitle, shopstoryMetadata, blocks } = landingPage;
  if (!shopstoryMetadata) {
    throw new Error("If you're using Shopstory blocks, you must pass meta");
  }

  const uncompressedShopstoryMetadata = useUncompressShopstoryMetadata(shopstoryMetadata);

  return (
    <>
      <CustomHead
        image={metaImage}
        description={metaDescription}
        title={metaTitle}
        noIndex={urlRoute.noIndex}
        alternatePaths={alternatePaths}
      />
      <IrisShopstoryProvider>
        <ShopstoryMetadataProvider meta={uncompressedShopstoryMetadata}>
          <Contentful blocks={blocks} draftMode={props.draftMode} />
        </ShopstoryMetadataProvider>
      </IrisShopstoryProvider>
    </>
  );
}
