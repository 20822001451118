import * as Sentry from '@sentry/nextjs';
import { getFromStorage, saveToStorage, removeFromStorage } from './storage';

export const PREFERRED_STORE = 'PREFERRED_STORE';
export const PREFERRED_LOCAL_STORE = 'PREFERRED_LOCAL_STORE';

export function getPreferredLocalStoreCode(country: string) {
  try {
    if (getFromStorage(PREFERRED_STORE)) {
      removeFromStorage(PREFERRED_STORE); // cleaning up old storage key
    }
    return JSON.parse(getFromStorage(PREFERRED_LOCAL_STORE))?.[`${country}`];
  } catch (err) {
    Sentry.captureException(`store stock availability: failed to get PREFERRED_LOCAL_STORE': ${err}`);
    return null;
  }
}

export function savePreferredLocalStore(storeCode: string, country: string) {
  try {
    return saveToStorage(
      PREFERRED_LOCAL_STORE,
      JSON.stringify({
        ...JSON.parse(getFromStorage(PREFERRED_LOCAL_STORE)),
        [`${country}`]: storeCode
      })
    );
  } catch (err) {
    Sentry.captureException(`store stock availability: failed to save PREFERRED_LOCAL_STORE': ${err}`);
    return null;
  }
}
