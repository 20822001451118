import { trackGaEvent } from '../helpers';
import { mapProductTorii } from '../utils';
import { Product, ProductType, Variant } from 'types/torii';
import { SortOptions } from 'utils/product/sortProducts';

type ProductClick = {
  listType: string;
  sku?: string;
  currency?: string;
  productType: ProductType;
  productsOffset?: number;
  variants: Variant[];
};

type ProductImpression = {
  listType: string;
  currency?: string;
  productsOffset?: number;
  products: Product[];
};

export function productClick(productClickEvent: ProductClick) {
  const { variants, sku, currency, listType, productType } = productClickEvent;

  const mappedVariants = variants
    .map((product, index) => {
      if (product.sku === sku) {
        return {
          ...mapProductTorii(product, productType),
          position: index + 1 // send position of the product in the list,
        };
      }
      return null;
    })
    .filter(product => product);

  trackGaEvent({
    ecommerce: {
      // Local currency
      click: {
        actionField: {
          list: listType
        },
        products: mappedVariants
      },
      currencyCode: currency
    },
    event: 'productClick'
  });
}

export function productImpression(productImpressionEvent: ProductImpression) {
  const { listType, products, currency, productsOffset = 0 } = productImpressionEvent;

  const impressions = products.map((product, index) => ({
    ...mapProductTorii(product.currentVariant, product.productType),
    list: listType, // 'Search Results', 'Recommendations' etc.
    position: index + 1 // position in the list
  }));

  trackGaEvent({
    ecommerce: {
      currencyCode: currency, // Local currency
      impressions: impressions.slice(productsOffset)
    },
    event: 'productImpression'
  });
}

export function filtering({ filters, filteredProducts }) {
  const { colorPatterns: pattern, colors, materials, prices, shapes, styles, widths, services } = filters;

  function getValue(value: string[] | string) {
    return Array.isArray(value) ? value.join('-') : value;
  }

  trackGaEvent({
    event: 'filtering',
    filteredColor: getValue(colors),
    filteredMaterial: getValue(materials),
    filteredPattern: getValue(pattern),
    filteredPrice: getValue(prices),
    filteredProducts, // indicates the products shown after the filter applied
    filteredService: getValue(services),
    filteredShape: getValue(shapes),
    filteredStyle: getValue(styles),
    filteredWidth: getValue(widths)
  });
}

export function sorting({ type }: { type: SortOptions }) {
  trackGaEvent({
    event: 'sorting',
    type
  });
}

export function loadMoreFrames() {
  trackGaEvent({
    event: 'loadMoreFrames'
  });
}

export function collectionInteraction({ label }: { label: string }) {
  trackGaEvent({
    clickedItem: label,
    event: 'collectionMenu'
  });
}
