import React, { ReactElement, ReactNode } from 'react';
import renderRichTextV2 from 'utils/contentful/renderRichTextV2';
import { BlockFlexibleExtended, ComponentCopyExtended, ComponentImage, ComponentVideoExtended } from 'types/contentful';
import * as Styles from './styles';
import { onPageBannerClick } from 'tracking/utils';
import { Hideable } from 'blocks/Contentful/styles';
import ConditionalLink from 'components/ConditionalLink';
import { Entry } from 'contentful';
import Image from 'next/image';
import { transformCfAsset, transformCfVideo } from 'utils/images';
import VideoPlayer from 'components/VideoPlayer';

/** Type Guards */
function isComponentVideo(entry: Entry<any>): entry is Entry<ComponentVideoExtended> {
  return entry && entry.sys.contentType.sys.id === 'componentVideo';
}

function isComponentImage(entry: Entry<any>): entry is Entry<ComponentImage> {
  return entry && entry.sys.contentType.sys.id === 'componentImage';
}

/** Helper Maps */
const aspectRatioMap = {
  default: {
    mobile: '3:4',
    laptop: '3:2'
  },
  landscape: {
    mobile: '3:2',
    laptop: '3:2'
  },
  portrait: {
    mobile: '3:4',
    laptop: '3:4'
  },
  square: {
    mobile: '1:1',
    laptop: '1:1'
  },
  video: {
    mobile: '3:4',
    laptop: '16:9'
  }
} as const;

export const alignItemsMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
} as const;

export const textAlignMap = {
  left: 'left',
  center: 'center',
  right: 'left' // we don't want to support right aligned text
} as const;

export const justifyContentMap = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
  'space-between': 'space-between'
} as const;

export const positionContentMap = {
  hug: 'relative',
  fixed: 'absolute'
} as const;

/** Helper Functions */
function getAspect(ratio: string) {
  if (ratio === null) return 0;
  const [width, height] = ratio.split(':').map(Number);
  return height / width;
}

function getContentWidth(widthFraction: string) {
  if (['1', null].includes(widthFraction)) return 'auto';
  const [numerator, denominator] = widthFraction.split('/').map(Number);
  return `${(numerator / denominator) * 100}%`;
}

/** Supporting Components */

function BackgroundComponent({
  backgroundEntry,
  backgroundColor,
  blockAspectRatio,
  blockFit,
  breakpointSize,
  children
}: {
  backgroundEntry?: BlockFlexibleExtended['background'];
  backgroundColor?: BlockFlexibleExtended['backgroundColor'];
  /** needed if blockFit is fixed */
  blockAspectRatio?: BlockFlexibleExtended['blockAspectRatio'];
  blockFit: BlockFlexibleExtended['blockFit'];
  breakpointSize: 'mobile' | 'laptop';
  children?: ReactNode | ReactNode[];
}) {
  const isVideo = isComponentVideo(backgroundEntry);
  const isImage = isComponentImage(backgroundEntry);

  return (
    <Styles.AspectRatioBox
      paddingBottom={blockFit === 'hug' ? '0' : `${getAspect(aspectRatioMap[blockAspectRatio][breakpointSize]) * 100}%`}
      style={{ backgroundColor: backgroundColor?.value }}
    >
      {isVideo && (
        <Styles.BackgroundVideo>
          <VideoPlayer
            videoPlayOptions={backgroundEntry.fields.autoPlay ? 'autoPlay' : undefined}
            videoFit='cover'
            src={transformCfVideo(backgroundEntry.fields[breakpointSize][0].secure_url)}
            contentType={`${backgroundEntry?.fields[breakpointSize][0].resource_type}/${backgroundEntry?.fields[breakpointSize][0].format}`}
            aspect={getAspect(aspectRatioMap[blockAspectRatio][breakpointSize])}
          />
        </Styles.BackgroundVideo>
      )}
      {isImage && (
        <Styles.BackgroundImage>
          <Image
            objectFit='cover'
            src={backgroundEntry?.fields[breakpointSize].fields?.file.url}
            fill
            sizes='66vw' // TODO: this is laptop and pdp specific => abstract
            alt={backgroundEntry?.fields[breakpointSize].fields?.description} // TODO: discuss alt text with Susanna and Tjis
            quality={75}
            loader={({ width, quality, src }) =>
              transformCfAsset(src, {
                fit: 'cover',
                quality,
                width
              })
            }
          />
        </Styles.BackgroundImage>
      )}
      {children && (
        <div
          style={{
            position: positionContentMap[blockFit],
            height: '100%'
          }}
        >
          {children}
        </div>
      )}
    </Styles.AspectRatioBox>
  );
}

function ComponentCopy(fields: ComponentCopyExtended) {
  return (
    <div onClickCapture={onPageBannerClick('componentCopy')}>
      {/* TODO: support tag overwritting of BLOCKS.HEADING_# in renderRichTextV2 */}
      {renderRichTextV2(fields.body)}
    </div>
  );
}

/** Main Component */

const BlockRenderers = {
  componentCopy: ComponentCopy
};

export default function BlockFlexible({
  blockFit = 'hug',
  blockAspectRatio = 'default',
  background,
  backgroundColor,
  content,
  contentColor,
  contentAlignY = 'top',
  contentAlignX = 'left',
  contentWidth = '1',
  isALinkedBlock = false,
  urlRoute,
  link
}: BlockFlexibleExtended): ReactElement {
  const blockLink = urlRoute?.fields?.path || link;
  const supportsBlockLink = isALinkedBlock && blockLink && !isComponentVideo(background);

  return (
    <div style={{ pointerEvents: 'none', position: 'relative' }}>
      <ConditionalLink isLink={supportsBlockLink} href={blockLink} onClickCapture={onPageBannerClick('flexible-block')}>
        <span
          style={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            opacity: '0',
            cursor: 'pointer',
            display: 'block',
            pointerEvents: 'auto'
          }}
        />
      </ConditionalLink>
      <Hideable hide={{ laptop: false, mobile: true }}>
        <BackgroundComponent
          backgroundEntry={background}
          backgroundColor={backgroundColor}
          blockFit={blockFit}
          blockAspectRatio={blockAspectRatio}
          breakpointSize='laptop'
        >
          {content && (
            <Styles.Content
              position='relative'
              justifyContent={justifyContentMap[contentAlignY]}
              alignItems={alignItemsMap[contentAlignX]}
              textAlign={textAlignMap[contentAlignX]}
              width={getContentWidth(contentWidth)}
              style={{ color: contentColor?.value }}
            >
              {content?.map((entry, index) => {
                const blockType = entry.sys.contentType?.sys.id as keyof typeof BlockRenderers;
                const BlockComponent = BlockRenderers[blockType];
                return BlockComponent && <BlockComponent {...(entry.fields as any)} key={index + '-' + entry.sys.id} />;
              })}
            </Styles.Content>
          )}
        </BackgroundComponent>
      </Hideable>
      <Hideable hide={{ laptop: true, mobile: false }}>
        <BackgroundComponent
          backgroundEntry={background}
          backgroundColor={backgroundColor}
          blockFit={blockFit}
          blockAspectRatio={blockAspectRatio}
          breakpointSize='mobile'
        >
          {content && (
            <Styles.Content
              position='relative'
              justifyContent={justifyContentMap[contentAlignY]}
              alignItems={alignItemsMap[contentAlignX]}
              textAlign={textAlignMap[contentAlignX]}
              width={getContentWidth(contentWidth)}
              style={{ color: contentColor?.value }}
            >
              {content?.map((entry, index) => {
                const blockType = entry.sys.contentType?.sys.id as keyof typeof BlockRenderers;
                const BlockComponent = BlockRenderers[blockType];
                return BlockComponent && <BlockComponent {...(entry.fields as any)} key={index + '-' + entry.sys.id} />;
              })}
            </Styles.Content>
          )}
        </BackgroundComponent>
      </Hideable>
    </div>
  );
}
