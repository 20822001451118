import React, { useContext } from 'react';
import { ContentBlockLevel2Fragment } from 'services/generated/graphql/graphql';
import BlockRenderer from '.';
import { ActionLink, ActionLinkProps, ActionProps } from '../Fields/Action';
import { LayoutContainer, LayoutElement } from '../Fields/Layout';
import { DebugLabel } from '../styles';
import RenderContext from '../RenderContext';

type ContentBlockLevel2Props = Omit<ContentBlockLevel2Fragment, 'action'> & { action?: ActionProps };

export default function ContentBlockLevel2Component({ block }: { block: ContentBlockLevel2Props }) {
  const { isInspectorActive } = useContext(RenderContext);
  const ConditionalActionLink = block.action?.link ? ActionLink : React.Fragment;

  const conditionalActionLinkProps = ConditionalActionLink.name === 'ActionLink' && {
    action: block.action as ActionLinkProps['action'],
    assets: block.assetsCollection?.items,
    references: block.referencesCollection?.items
  };

  return (
    <ConditionalActionLink {...conditionalActionLinkProps}>
      <LayoutElement
        layout={block.layout}
        action={block.action}
        depth={2}
        references={block.referencesCollection?.items}
        data-contentful-field-id='internalName'
        data-contentful-entry-id={block.sys.id}
      >
        {isInspectorActive && <DebugLabel>{block.internalName}</DebugLabel>}
        {block.blocksCollection && (
          <LayoutContainer layout={block.layout} depth={2}>
            <BlockRenderer blocks={block.blocksCollection.items} animateTypographies={false} />
          </LayoutContainer>
        )}
      </LayoutElement>
    </ConditionalActionLink>
  );
}
