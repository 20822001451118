import React from 'react';
import { FormattedMessage } from 'react-intl';
import Image from 'next/image';
import { Select, Typography, brandColors } from '@aceandtate/ds';
import { SelectItem } from '@aceandtate/ds/dist/components/Select/Select';
import { filterIconSrcMap, filterIconMap, filterColorMap } from 'components/FiltersDrawer/config';
import type { AppliedFiltersType } from 'components/FiltersDrawer/types';
import { ColorSwatch } from 'components/FiltersDrawer/styles';
import * as Styles from '../../styles';
import { expandedFilters } from '../../config';
import frameFilterMessages from 'messages/filters';

function getLabel(option) {
  const IconSvg = filterIconMap[option];
  const src = filterIconSrcMap[option];
  const color = filterColorMap[option];
  const message = frameFilterMessages[option] ? <FormattedMessage {...frameFilterMessages[option]} /> : option;
  let Icon;

  if (IconSvg) {
    Icon = <IconSvg fontSize={20} />;
  } else if (src) {
    Icon = (
      <Image
        src={src}
        alt={option}
        height={10}
        width={30}
        style={{
          maxWidth: '100%',
          height: 'auto'
        }}
      />
    );
  } else if (color) {
    Icon = <ColorSwatch color={color} />;
  }

  return (
    <Styles.LabelWrapper style={{ color: brandColors.dark }}>
      {Icon}
      <Typography variant='h6' fontWeight='light' as='span'>
        {message}
      </Typography>
    </Styles.LabelWrapper>
  );
}

type SelectValue = {
  group: string;
  option: string;
};

type Props = {
  updateFrameAttributeFilters: (updatedFilters: AppliedFiltersType) => void;
  frameAttributeFilters: AppliedFiltersType;
};

export default function ExpandedFrameFilters(props: Props) {
  const { updateFrameAttributeFilters, frameAttributeFilters } = props;

  function getFrameAttributeProps(
    currentFilters: AppliedFiltersType,
    filterGroup: string,
    selectedItems: SelectItem<SelectValue>[]
  ) {
    const selectedOptions = selectedItems.map(item => item.value.option);

    return {
      ...currentFilters,
      [filterGroup]: selectedOptions
    } as AppliedFiltersType;
  }

  function getSelectItemsProps(filterGroupKey, filterOptions: string[]): SelectItem<SelectValue>[] {
    return filterOptions.map(option => {
      return {
        id: option,
        label: getLabel(option) as any,
        value: {
          group: filterGroupKey,
          option: option
        }
      };
    });
  }

  return (
    <>
      {expandedFilters.map(filter => {
        const items: SelectItem<SelectValue>[] = getSelectItemsProps(filter.groupKey, filter.options);
        const seletedItems: SelectItem<SelectValue>[] = getSelectItemsProps(
          filter.groupKey,
          frameAttributeFilters[filter.groupKey]
        );

        return (
          <Select
            data-cs-capture
            id={filter.groupKey}
            key={filter.groupKey}
            multiple
            placeholder={getLabel(filter.groupKey)}
            items={items}
            setSelectedItems={selectedItems =>
              updateFrameAttributeFilters(
                getFrameAttributeProps(frameAttributeFilters, filter.groupKey, selectedItems as SelectItem<any>[])
              )
            }
            selectedItems={seletedItems}
            hasItemCounter={false}
          />
        );
      })}
    </>
  );
}
