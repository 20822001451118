import React from 'react';
import { Typography } from '@aceandtate/ds';
import messages from '../../../messages';
import { FormattedMessage } from 'react-intl';

export default function CleaningKitIngredientsModal() {
  return (
    <>
      <Typography variant='h5' gutterBottom>
        <FormattedMessage {...messages.ingredientListTitle} />
      </Typography>
      <Typography variant='bodyM' gutterBottom>
        <FormattedMessage {...messages.cleaningKitIngredientList} />
      </Typography>
      <Typography variant='bodyM' gutterBottom>
        <FormattedMessage {...messages.cleaningKitECIngredients} />
      </Typography>
      <Typography variant='bodyM'>
        <FormattedMessage {...messages.cleaningKitAllergyDisclaimer} />
      </Typography>
    </>
  );
}

CleaningKitIngredientsModal.buttonText = messages.ingredientListLink;
