import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IconFilters, Typography } from '@aceandtate/ds';
import frameFilterMessages from 'messages/filters';
import { FilterCtaButton } from 'views/ProductCollection/styles';

type FilterButtonProps = {
  count: number;
  onClick: () => void;
  counterVisible?: boolean;
  'data-testid'?: string;
  color: 'accent' | 'primary';
  fontWeight: 'light' | 'regular' | 'bold';
};

const FilterButton = (props: FilterButtonProps) => {
  const { count = 0, onClick = () => {}, counterVisible = false, color, fontWeight } = props;

  return (
    <FilterCtaButton onClick={onClick} data-testid={props['data-testid']} color={color}>
      <IconFilters fontSize={18} />
      <Typography fontWeight={fontWeight}>
        <FormattedMessage {...frameFilterMessages.allFilters} />
        {counterVisible && count > 0 && ` (${count})`}
      </Typography>
    </FilterCtaButton>
  );
};

export default FilterButton;
