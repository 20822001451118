import { isThisWeek } from 'date-fns';
import { HolidayOpeningHours, OpeningHours } from 'types/solidus';

export function getHoursWithExceptions(
  openingHours: OpeningHours[],
  holidayHours: HolidayOpeningHours[]
): OpeningHours[] {
  if (!holidayHours || !Array.isArray(holidayHours)) {
    return openingHours;
  }

  const holidayOverrides = holidayHours.reduce((acc, exception) => {
    const overrideDate = new Date(exception.date);

    if (isThisWeek(overrideDate)) {
      const day_of_the_week = overrideDate.getDay();

      const isClosed = !exception.hours || exception.hours === '';

      if (isClosed) {
        return {
          ...acc,
          [day_of_the_week]: {
            closed: 1,
            day_of_the_week
          }
        };
      }

      const [openingHour, openingMinute, closingHour, closingMinute] = exception.hours.split(':');
      const opening_time = `${openingHour}:${openingMinute}:00`;
      const closing_time = `${closingHour}:${closingMinute}:00`;

      return {
        ...acc,
        [day_of_the_week]: {
          closed: 0,
          closing_time,
          day_of_the_week,
          opening_time
        }
      };
    }

    return acc;
  }, {});

  const hoursWithHoliday = openingHours.map(regularHours => {
    const exception = holidayOverrides[regularHours.day_of_the_week];
    return exception || regularHours;
  });

  return hoursWithHoliday;
}
