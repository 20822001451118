import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, Typography, breakpointRules, useMediaQuery } from '@aceandtate/ds';
import Image from 'next/image';
import messages from './messages';

export default function CaseModal({ open, onClose }) {
  const isLaptop = useMediaQuery(breakpointRules.laptop);

  return (
    <Modal
      open={open}
      onClose={onClose}
      image={
        <Image
          src='/static/images/freecase_vertical.jpg'
          fill
          sizes='100vw'
          style={{
            objectFit: 'cover'
          }}
          quality={isLaptop ? 60 : 100}
          alt='Ace & Tate | Case and Cleaning Cloth'
        />
      }
      content={
        <>
          <Typography variant='h5' gutterBottom>
            <FormattedMessage {...messages.caseAndClothModalTitle} />
          </Typography>
          <Typography variant='bodyS'>
            <FormattedMessage {...messages.caseAndClothModalText} />
          </Typography>
        </>
      }
    />
  );
}
