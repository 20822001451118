import { captureException } from '@sentry/nextjs';
import { Metadata, Resource } from '@shopstory/core';
import { produce } from 'immer';
import { useMemo } from 'react';
import { ProductMapperResult } from 'shopstory/shopstoryConfig';
import useVariantLookup from 'utils/hooks/useVariantLookup';

function getProductResources(resources: Metadata['resources']) {
  try {
    return resources
      .filter(resource => resource.type === 'product' && resource.status === 'success')
      .map(resource => resource.values.default as ProductMapperResult);
  } catch (e) {
    captureException(e);
    return [];
  }
}

/** Enrich Product data with variants that exist on Shopstory metadata resources */
export function useUncompressShopstoryMetadata(meta: Metadata) {
  const products = useMemo(() => getProductResources(meta.resources), [meta.resources]);
  const findVariants = useVariantLookup(products);

  const uncompressed = useMemo(() => {
    try {
      return produce(meta, metaClone => {
        metaClone.resources = meta.resources.map(resource => {
          if (resource.type !== 'product' || resource.status !== 'success') {
            return resource;
          }

          return produce(resource, (state: Resource<ProductMapperResult>) => {
            state.values.default.variants = findVariants(state.values?.default?.variantSkus || []);
          });
        });
      });
    } catch (e) {
      captureException(e);
      return meta;
    }
  }, [meta]);

  return uncompressed;
}
