import React, { useMemo, useState } from 'react';
import { uspsFeatures, getProductUspsIds } from 'components/ProductUsps/helpers';
import { isSkiGoggles, isSportsGlasses } from 'utils/product/productTaxonomies';
import { CliponProduct, FamilyVariant, FrameProduct, GenericProduct, ProductVariant } from 'types/torii';
import { FormattedMessage } from 'react-intl';
import { WebStore } from 'types/webStore';
import FormattedPrice from 'components/DisplayLabels/FormattedPrice';
import messages from 'components/ProductUsps/messages';

import {
  Button,
  IconCheck,
  Typography,
  Flex,
  brandColors,
  grid,
  IconClose,
  useMediaQuery,
  breakpointRules
} from '@aceandtate/ds';
import { paths } from 'paths';
import Link from 'next/link';
import styled from 'styled-components';
import { AcetateFrameModal, MetalFrameModal } from 'components/ProductUsps/partials/CliponsModal';
import { getAvailableSizes } from 'utils/product/productHelpers';

export type UspsProps = {
  currentVariant: ProductVariant;
  product: FrameProduct | GenericProduct | CliponProduct;
  webStore: WebStore;
};

export enum ModalType {
  CLIPONS_MODAL_METAL = 'clipons_modal_metal',
  CLIPONS_MODAL_ACETATE = 'clipons_modal_acetate'
}

export const List = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
`;

export default function ProductHighlights({ product, currentVariant, webStore }: UspsProps) {
  const minimumShipping = (
    <FormattedPrice currency={webStore.config.currency} price={webStore.shipping.free_shipping_threshold} />
  );
  const availableSizes = useMemo(() => getAvailableSizes(product, currentVariant), [product, currentVariant]);

  const isLaptop = useMediaQuery(breakpointRules.laptop);

  const [modalToOpen, setModalToOpen] = useState<ModalType | null>(null);

  const uspsIds = getProductUspsIds({
    webStore,
    product,
    currentVariant
  });

  const uspsBaseConfig = uspsIds.reduce((acc, uspId) => {
    acc = { ...acc, [uspId]: { messageData: messages[uspId] ? <FormattedMessage {...messages[uspId]} /> : uspId } };
    return acc;
  }, {});

  const uspsExtraConfig = {
    freeShippingFrom: {
      messageData: <FormattedMessage {...messages.freeShippingFrom} values={{ price: minimumShipping }} />
    },
    guaranteeText: {
      messageData: (
        <FormattedMessage
          {...messages.guaranteeText}
          values={{
            br: '',
            guaranteeNewLink: ''
          }}
        />
      )
    },
    returnPolicy: {
      messageData: (
        <FormattedMessage
          {...messages.returnPolicy}
          values={{
            returnPolicyLink: (
              <Link href={paths.returnPolicy} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                <FormattedMessage {...messages.returnPolicyLink} />
              </Link>
            )
          }}
        />
      )
    },
    cliponsAvailableMetal: {
      messageData: (
        <Button
          onClick={() => setModalToOpen(ModalType.CLIPONS_MODAL_METAL)}
          variant='link'
          style={{ textAlign: 'left' }}
        >
          <Typography variant='bodyM' as='span'>
            <FormattedMessage
              {...messages.cliponsAvailableMetal}
              values={{
                cliponsLink: ''
              }}
            />
          </Typography>
        </Button>
      ),
      modal: (
        <MetalFrameModal
          open={modalToOpen === ModalType.CLIPONS_MODAL_METAL}
          onClose={() => setModalToOpen(null)}
          width={isLaptop && '426px'}
        />
      )
    },
    cliponsAvailableAcetate: {
      messageData: (
        <Button
          onClick={() => setModalToOpen(ModalType.CLIPONS_MODAL_ACETATE)}
          variant='link'
          style={{ textAlign: 'left' }}
        >
          <Typography variant='bodyM' as='span'>
            <FormattedMessage
              {...messages.cliponsAvailableAcetate}
              values={{
                cliponsLink: ''
              }}
            />
          </Typography>
        </Button>
      ),
      modal: (
        <AcetateFrameModal
          open={modalToOpen === ModalType.CLIPONS_MODAL_ACETATE}
          onClose={() => setModalToOpen(null)}
          width={isLaptop && '426px'}
        />
      )
    },
    alsoAvailable: {
      messageData: (
        <FormattedMessage
          {...messages.alsoAvailable}
          values={{
            availableSizes: availableSizes.map((sizeMap: FamilyVariant) => (
              <>
                <Link href={sizeMap.sku} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                  {sizeMap.size} <FormattedMessage {...messages.fit} />
                </Link>
                {/* eslint-disable-next-line no-nested-ternary */}
                {availableSizes.indexOf(sizeMap) === availableSizes.length - 2
                  ? ' & '
                  : availableSizes.indexOf(sizeMap) === availableSizes.length - 1
                  ? ' '
                  : ', '}
              </>
            ))
          }}
        />
      )
    },
    drivingWarning: {
      messageData: <FormattedMessage {...messages.drivingWarning} />,
      sentiment: 'negative'
    },
    notReturnable: {
      messageData: <FormattedMessage {...messages.notReturnable} />,
      sentiment: 'negative'
    }
  } as const;

  const uspsConfig = { ...uspsBaseConfig, ...uspsExtraConfig };

  return (
    <List key='product-highlights-list' id='product-highlights-list' style={{ margin: 0 }}>
      {isSkiGoggles(currentVariant) || isSportsGlasses(currentVariant) ? (
        <>
          {uspsFeatures(currentVariant).map(usp => (
            <FormattedMessage
              key={usp.key}
              values={{
                li: chunks => (
                  <li key={usp.key} id={usp.key} style={{ display: 'inline' }}>
                    <Flex alignItems='self-start' gap={grid[4]}>
                      <Typography variant='bodyM' as='span'>
                        <IconCheck
                          key={usp.key}
                          color={brandColors.accent}
                          fontSize={18}
                          style={{ verticalAlign: 'sub' }}
                        />
                      </Typography>
                      <Typography variant='bodyM' as='span'>
                        {chunks}
                      </Typography>
                    </Flex>
                  </li>
                )
              }}
              {...messages[usp.key]}
            />
          ))}
        </>
      ) : (
        <>
          {uspsIds.map(uspId => (
            <li key={uspId} id={uspId} style={{ display: 'inline' }}>
              {uspsConfig[uspId]?.modal}
              <Flex alignItems='self-start' gap={grid[4]}>
                <Typography variant='bodyM' as='span'>
                  {uspsConfig[uspId]?.sentiment === 'negative' ? (
                    <IconClose key={uspId} color={brandColors.accent} fontSize={18} style={{ verticalAlign: 'sub' }} />
                  ) : (
                    <IconCheck key={uspId} color={brandColors.accent} fontSize={18} style={{ verticalAlign: 'sub' }} />
                  )}
                </Typography>
                <Typography variant='bodyM'>{uspsConfig[uspId].messageData || uspId}</Typography>
              </Flex>
            </li>
          ))}
        </>
      )}
    </List>
  );
}
