import React, { useState } from 'react';
import { grid, Typography, IconPlus, IconMinus } from '@aceandtate/ds';
import { FormattedMessage } from 'react-intl';
import { createBenefitsConfigurations } from './benefitsConfig';
import messages from '../../messages';
import * as Styles from '../../styles';

type Props = {
  isSkiGoggles?: boolean;
  isSportsGlasses?: boolean;
  benefitsBlockRef?: React.MutableRefObject<any> | null;
};

export default function BenefitsBlockMobile({ isSkiGoggles, isSportsGlasses, benefitsBlockRef }: Props) {
  const [openKey, setOpenKey] = useState();

  const benefitsConfigurations = createBenefitsConfigurations(isSportsGlasses, isSkiGoggles);

  function handleCollapsibleToggle(key) {
    setOpenKey(openKey !== key ? key : null);
  }

  return (
    <Styles.BenefitsContainer ref={benefitsBlockRef}>
      <Typography variant='h4' gutterBottom={12}>
        <FormattedMessage {...messages.productBenefits} />
      </Typography>
      {benefitsConfigurations.map(tab => (
        <Styles.CollapsibleRoot
          key={tab.key}
          open={openKey === tab.key}
          onClick={() => handleCollapsibleToggle(tab.key)}
        >
          <Styles.CollapsibleTrigger>
            {tab.icon}
            <Typography variant='bodyM' fontWeight='regular' style={{ marginLeft: grid[4] }}>
              {tab.title}
            </Typography>
            <Styles.CollapsibleIconWrapper>
              {openKey === tab.key ? <IconMinus fontSize={20} /> : <IconPlus fontSize={20} />}
            </Styles.CollapsibleIconWrapper>
          </Styles.CollapsibleTrigger>
          <Styles.CollapsibleContent isAnimated>
            <Typography variant='bodyM'>{tab.caption}</Typography>
          </Styles.CollapsibleContent>
        </Styles.CollapsibleRoot>
      ))}
    </Styles.BenefitsContainer>
  );
}
