import React from 'react';
import { brandColors, Tooltip, Typography, IconInfo, Flex, grid } from '@aceandtate/ds';
import { FormattedMessage } from 'react-intl';
import { FrameProduct } from 'types/torii';
import messages from '../../messages';
import ProductOptions from '.';

type Props = {
  product: FrameProduct;
  isNosepadVariant: boolean;
  setSelectedNosepadVariant: (hasNodepads: boolean) => void;
  isDisabled?: boolean;
};

export default function NosepadsToggle(props: Props) {
  const { product, isNosepadVariant, setSelectedNosepadVariant, isDisabled } = props;
  return (
    <>
      <Flex gap={grid[4]} alignItems='center'>
        <Typography variant='bodyM' gutterBottom>
          <FormattedMessage {...messages.nosepads} />
        </Typography>
        <Tooltip
          content={<FormattedMessage {...messages.nosepadsInfo} values={{ framename: product.currentVariant.name }} />}
        >
          <Flex>
            <Typography variant='bodyM' gutterBottom>
              <IconInfo color={brandColors.dark30} style={{ height: '100%' }} />
            </Typography>
          </Flex>
        </Tooltip>
      </Flex>

      <ProductOptions
        selectedOption={isNosepadVariant}
        setSelectedOption={setSelectedNosepadVariant}
        options={[
          {
            label: (
              <Typography variant='bodyM' as='span' fontWeight='regular'>
                <FormattedMessage {...messages.withNosepads} />
              </Typography>
            ),
            disabled: !isNosepadVariant && isDisabled,
            value: true
          },
          {
            label: (
              <Typography variant='bodyM' as='span' fontWeight='regular'>
                <FormattedMessage {...messages.withoutNosepads} />
              </Typography>
            ),
            disabled: isNosepadVariant && isDisabled,
            value: false
          }
        ]}
      />
    </>
  );
}
