import React from 'react';
import styled from 'styled-components';
import { Grid, Cell } from '@aceandtate/ds';
import BlockFlexible from '../BlockFlexible';
import type { MultiplierBlockExtended } from 'types/contentful';

const MultiplierBlockRenderers = {
  blockFlexible: BlockFlexible
};

const StyledGrid = styled(Grid)`
  --ds-grid-gutter: 0px;
`;

export default function MultiplierBlock(props: MultiplierBlockExtended) {
  const { references = [] } = props;
  return (
    <StyledGrid borderless horizontalAlign='center' verticalAlign='top'>
      {references.map((entry, index) => {
        const blockType = entry.sys.contentType.sys.id as keyof typeof MultiplierBlockRenderers;
        const BlockComponent = MultiplierBlockRenderers[blockType];
        return (
          <Cell key={index + '-' + entry.sys.id} columns={{ laptop: references.length / 1, mobile: 1 }}>
            {BlockComponent && <BlockComponent {...(entry.fields as any)} key={index + '-' + entry.sys.id} />}
          </Cell>
        );
      })}
    </StyledGrid>
  );
}
