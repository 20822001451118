import { Variant, Product } from 'types/torii';
import { trackGaEvent } from '../helpers';
import { mapProductTorii } from '../utils';
import posthog from 'posthog-js';

export function productDetail(currentVariant: Variant, product: Product) {
  const mappedProduct = mapProductTorii(currentVariant, product.productType);
  const isOptical = product.productType === 'frame' && !currentVariant.filterOptions.types.includes('sunny');
  const htoUnavailable = !currentVariant.availability.isAvailableHTO || !currentVariant.availability.isAvailableOnline;

  trackGaEvent({
    ecommerce: {
      currencyCode: currentVariant.price.currency,
      detail: {
        products: [
          {
            ...mappedProduct,
            price: currentVariant.price.value,
            ...(isOptical ? { dimension5: htoUnavailable ? 'No' : 'Yes' } : {})
          }
        ]
      }
    },
    event: 'productDetail'
  });

  posthog.capture('visited_pdp', {
    sku: currentVariant.sku,
    productName: product.name
  });
}

export function productSuggestion({
  interactionType,
  from,
  to
}: {
  interactionType: string;
  from: string;
  to: string;
}) {
  trackGaEvent({
    event: 'productSuggestion',
    from,
    interactionType,
    to
  });
}

export function clipOnSuggestion({ interactionType, from, to }: { interactionType: string; from: string; to: string }) {
  trackGaEvent({
    event: 'clipOnSuggestion',
    from,
    interactionType,
    to
  });
}
