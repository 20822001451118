import { Breakpoint, Button } from '@aceandtate/ds';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import FilterButton from './FilterButton';
import * as Styles from './styles';
import frameFilterMessages from 'messages/filters';
import throttle from 'lodash/throttle';

const smallDevices: Breakpoint[] = ['mobile', 'tablet'];
const largeDevices: Breakpoint[] = ['laptop', 'desktop'];
const allDevices: Breakpoint[] = [...smallDevices, ...largeDevices];

type Props = {
  handleFiltersDrawerToggle: () => void;
  resetFilters: () => void;
  filterCount?: number;
  productCount?: number;
  serviceFilters?: JSX.Element;
  expandedFilters?: JSX.Element;
  filterTags?: JSX.Element;
  sortSelector?: JSX.Element;
};

const FilterBar = (props: Props) => {
  const {
    handleFiltersDrawerToggle,
    resetFilters,
    productCount,
    filterCount,
    serviceFilters,
    expandedFilters,
    filterTags,
    sortSelector
  } = props;

  const [navbarVisible, setNavbarVisible] = useState(true);
  const previousScrollY = useRef<number>(0);

  const filterbarRef = useRef<HTMLDivElement>(null);
  const [filterBarPosition, setFilterBarPosition] = useState<'relative' | 'fixed'>('relative');
  const previousFilterBarPosition = useRef<'relative' | 'fixed'>('relative');
  const initialFilterbarOffset = useRef<number>(null);

  // conditionally showing / hiding navigation on scroll
  const handleFilterbarVisibility = throttle(
    () => {
      const { scrollY } = window;
      const distance = initialFilterbarOffset.current - scrollY;

      if (previousFilterBarPosition.current === 'relative' && distance < 0) {
        setFilterBarPosition('fixed');
        previousFilterBarPosition.current = 'fixed';
      }
      if (previousFilterBarPosition.current === 'fixed' && distance > 0) {
        setFilterBarPosition('relative');
        previousFilterBarPosition.current = 'relative';
      }

      setNavbarVisible(scrollY > previousScrollY.current);
      previousScrollY.current = scrollY;
    },
    100,
    { leading: true }
  );

  useEffect(() => {
    previousScrollY.current = window.scrollY;
    initialFilterbarOffset.current = filterbarRef.current.offsetTop;

    window.addEventListener('scroll', handleFilterbarVisibility, {
      passive: true
    });

    return () => {
      window.removeEventListener('scroll', handleFilterbarVisibility);
    };
  }, []);

  return (
    <>
      <Styles.GlobalFilterContainer
        data-cs-capture
        navVisible={navbarVisible}
        filterBarHeight={filterbarRef.current ? filterbarRef.current.clientHeight : 0}
        filterBarPosition={filterBarPosition}
      >
        <Styles.Filterbar ref={filterbarRef}>
          <Styles.FilterRow visibleOn={allDevices} style={{ justifyContent: 'space-between' }}>
            <Styles.FilterColumn data-testid='filters-bar-column' visibleOn={allDevices}>
              <Styles.FilterColumn data-testid='all-filters-cta-wrapper-mobile' visibleOn={smallDevices}>
                <FilterButton
                  data-testid='toggle-filters-drawer-mobile'
                  onClick={handleFiltersDrawerToggle}
                  count={filterCount || 0}
                  counterVisible
                  color='primary'
                  fontWeight='bold'
                />
              </Styles.FilterColumn>
              <Styles.FilterColumn data-testid='all-filters-cta-wrapper-laptop' visibleOn={largeDevices}>
                <FilterButton
                  data-testid='toggle-filters-drawer'
                  onClick={handleFiltersDrawerToggle}
                  count={filterCount || 0}
                  counterVisible
                  color='primary'
                  fontWeight='light'
                />
              </Styles.FilterColumn>
              <Styles.VerticalDivider visibleOn={serviceFilters ? allDevices : largeDevices} />
              <Styles.FilterColumn data-testid='expanded-filters-wrapper' visibleOn={largeDevices}>
                {expandedFilters}
              </Styles.FilterColumn>
              <Styles.FilterColumn data-testid='service-filters-wrapper' visibleOn={serviceFilters ? allDevices : []}>
                {serviceFilters}
              </Styles.FilterColumn>
            </Styles.FilterColumn>
            <Styles.FilterColumn id='sort-selector-desktop' visibleOn={largeDevices}>
              {sortSelector}
            </Styles.FilterColumn>
          </Styles.FilterRow>
          <Styles.Hr visibleOn={allDevices} />
          <Styles.FilterRow visibleOn={filterCount === 0 ? [] : largeDevices} data-testid='filters-tags-row'>
            <Styles.FilterColumn visibleOn={allDevices} data-testid='filters-tags-wrapper'>
              {filterTags}
              <Button onClick={resetFilters} variant='link'>
                <FormattedMessage {...frameFilterMessages.clearFilters} />
              </Button>
            </Styles.FilterColumn>
          </Styles.FilterRow>
          <Styles.Hr visibleOn={filterCount === 0 ? [] : largeDevices} />
          <Styles.FilterRow visibleOn={allDevices} style={{ justifyContent: 'space-between' }}>
            <Styles.ProductCount variant='h5' forwardedAs='p' data-testid='product-count'>
              <FormattedMessage {...messages.count} values={{ productCount }} />
            </Styles.ProductCount>
            <Styles.FilterColumn id='sort-selector-mobile' visibleOn={smallDevices}>
              {sortSelector}
            </Styles.FilterColumn>
          </Styles.FilterRow>
        </Styles.Filterbar>
      </Styles.GlobalFilterContainer>
    </>
  );
};

export default FilterBar;
