import { IconDrag, IconZoom, Typography, useBodyScrollLock } from '@aceandtate/ds';
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Zoom } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/zoom';
import ZoomFooter from './ZoomFooter';
import messages from '../../messages';
import { Background, MoveAroundPill, ZoomButton } from './styles';
import { FormattedMessage } from 'react-intl';

export default function MobileZoom({ images, activeIndex = 0, setActiveIndex }) {
  const [isInZoomView, setIsInZoomView] = useState(false);
  useBodyScrollLock(isInZoomView);

  return (
    <>
      <ZoomButton
        onClick={() => {
          setIsInZoomView(true);
        }}
        variant='outlined'
      >
        <IconZoom fontSize={24} />
      </ZoomButton>
      {isInZoomView && (
        <Background>
          <div style={{ height: '100%' }}>
            <Swiper
              spaceBetween={24}
              modules={[Zoom]}
              centeredSlides={true}
              style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
              keyboard
              initialSlide={activeIndex}
              onSlideChange={e => {
                setActiveIndex(e.realIndex);
              }}
              loop
              zoom
            >
              {images.map((img, i) => (
                <SwiperSlide data-hash={`slide${i}`} key={i} zoom>
                  <img src={img.src} style={{ position: 'absolute' }} />
                </SwiperSlide>
              ))}
              <ZoomFooter
                images={images}
                onClose={() => {
                  setIsInZoomView(false);
                }}
                initialIndex={activeIndex}
              />
            </Swiper>
          </div>
          <MoveAroundPill>
            <Typography variant='bodyL' fontWeight='bold'>
              <FormattedMessage {...messages.moveAroundPill} />
            </Typography>
            <IconDrag fontSize={24} />
          </MoveAroundPill>
        </Background>
      )}
    </>
  );
}
