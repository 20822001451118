import { CliponProduct, FamilyVariant, FrameProduct, GenericProduct, ProductVariant } from 'types/torii';
import { WebStore } from 'types/webStore';
import { getAvailableSizes } from 'utils/product/productHelpers';
import { isKidsGlasses, isNotReturnable, isSkiGoggles, isSportsGlasses } from 'utils/product/productTaxonomies';

type UspConfiguration = {
  hasFreeReturns: boolean;
  hasFreeDelivery: boolean;
  hideReturnsUsp?: boolean;
  canBeReturned: boolean;
  hasLenses: boolean;
  includeThinLenses: boolean;
  isDigitalShipping: boolean;
  isRawCollection: boolean;
  isKidsGlasses: boolean;
  minimumShipping: React.ReactNode;
};

type Usp = {
  key: string;
  values?: Record<string, any>;
};

export const uspsDescriptions = (
  product: FrameProduct | GenericProduct | CliponProduct,
  currentVariant: ProductVariant,
  { availableSizes }: { availableSizes: FamilyVariant[] }
) => {
  const usps = [];

  // tags
  if (currentVariant.filterOptions.widths[0] === 'narrow') {
    usps.push({ key: 'smallFitTag' });
  } else if (currentVariant.filterOptions.widths[0] === 'medium') {
    usps.push({ key: 'mediumFitTag' });
  } else if (currentVariant.filterOptions.widths[0] === 'wide') {
    usps.push({ key: 'largeFitTag' });
  } else if (isKidsGlasses(currentVariant)) {
    usps.push({ key: 'kidsAgeBracketTag' });
  }

  // bullet points
  if (product.productType === 'frame') {
    usps.push({ key: 'materialText' });
    !isKidsGlasses(currentVariant) && usps.push({ key: 'width' });
  }

  if (isKidsGlasses(currentVariant)) {
    usps.push({ key: 'flexHingesKids' });
  }

  if (availableSizes?.length > 0) {
    usps.push({ key: 'alsoAvailable' });
  }

  if (['peggy-tangerine-sun', 'noel-ganni-liquorice-w-cloth-case-sun'].includes(currentVariant.sku)) {
    usps.push({ key: 'drivingWarning' });
  }

  if (currentVariant.relatedProducts.clipOns && currentVariant.properties.frameMaterials.includes('metal')) {
    usps.push({ key: 'cliponsAvailableMetal' });
  }

  if (currentVariant.relatedProducts.clipOns && currentVariant.properties.frameMaterials.includes('acetate')) {
    usps.push({ key: 'cliponsAvailableAcetate' });
  }

  return usps;
};

export const uspsIncluded = ({
  hasFreeReturns,
  hideReturnsUsp = false,
  hasFreeDelivery,
  includeThinLenses,
  isDigitalShipping,
  isRawCollection,
  isKidsGlasses,
  minimumShipping,
  canBeReturned,
  hasLenses
}: UspConfiguration) => {
  const usps = [] as Usp[];

  if (canBeReturned && !hideReturnsUsp) {
    usps.push({
      key: hasFreeReturns ? 'freeReturnsTag' : 'returnPolicy'
    });
  } else if (!hideReturnsUsp) {
    usps.push({
      key: 'notReturnable'
    });
  }

  if (!isDigitalShipping) {
    usps.push({
      key: hasFreeDelivery ? 'freeShipping' : 'freeShippingFrom',
      values: { price: minimumShipping }
    });
  }

  if (includeThinLenses) {
    usps.push({ key: 'superThinLenses' });
  }

  if (hasLenses && !isRawCollection) {
    usps.push({ key: 'antiScratch' });
  }

  if (hasLenses) {
    usps.push({ key: isKidsGlasses ? 'caseKids' : 'caseAndCloth' }, { key: 'guaranteeText' });
  }

  return usps;
};

export const uspsFeatures = (currentVariant: ProductVariant) => {
  const usps = [] as Usp[];

  if (isSkiGoggles(currentVariant)) {
    usps.push({ key: 'featuresListSkiGoggles' });
  }

  if (currentVariant.sku.includes('benchpress-bobby-')) {
    usps.push({ key: 'featuresListSportsGlasses' }, { key: 'extraFeatBobby' });
  }

  if (currentVariant.sku.includes('deadlift-tom-')) {
    usps.push({ key: 'featuresListSportsGlasses' }, { key: 'extraFeatTom' });
  }

  if (currentVariant.sku.includes('home-run-quinn-')) {
    usps.push({ key: 'featuresListSportsGlasses' }, { key: 'extraFeatQuinn' });
  }

  return usps;
};

export const uspsMaterials = (currentVariant: ProductVariant) => {
  const usps = [] as Usp[];

  if (isSkiGoggles(currentVariant)) {
    const bioSkus = ['eddie-kalamata-sun', 'eddie-lavender-sun', 'eddie-silver-sun'];
    if (bioSkus.includes(currentVariant.sku)) {
      usps.push({ key: 'materialListBioSkiGoggles' });
    } else {
      usps.push({ key: 'materialListSkiGoggles' });
    }
  }

  if (isSportsGlasses(currentVariant)) {
    usps.push({ key: 'materialListSportsGlasses' });
  }

  return usps;
};

export type UspsProps = {
  currentVariant: ProductVariant;
  product: FrameProduct | GenericProduct | CliponProduct;
  webStore: WebStore;
};

type UspsId =
  | 'freeReturnsTag'
  | 'returnPolicy'
  | 'notReturnable'
  | 'freeShipping'
  | 'freeShippingFrom'
  | 'guaranteeText'
  | 'kidsAgeBracketTag'
  | 'drivingWarning'
  | 'alsoAvailable'
  | 'cliponsAvailableMetal'
  | 'cliponsAvailableAcetate';

export const getProductUspsIds = ({ currentVariant, product, webStore }: UspsProps) => {
  const usps = [] as UspsId[];

  const canBeReturned = !isNotReturnable(currentVariant);
  const hasFreeDelivery = product.fromPrice.value >= webStore.shipping.free_shipping_threshold;
  const { hasFreeReturns } = webStore.config;
  const isFrame = product.productType === 'frame';
  const isMetalFrame = currentVariant.properties?.frameMaterials.includes('metal');
  const isAcetateFrame = currentVariant.properties?.frameMaterials.includes('acetate');

  const hasClipOns = currentVariant?.relatedProducts?.clipOns;
  const isDigitalShipping = product.productType === 'giftcard';

  const includeDrivingWarning = ['peggy-tangerine-sun', 'noel-ganni-liquorice-w-cloth-case-sun'].includes(
    currentVariant.sku
  );

  // Product Policies
  if (canBeReturned) {
    usps.push(hasFreeReturns ? 'freeReturnsTag' : 'returnPolicy');
  }

  if (!isDigitalShipping) {
    usps.push(hasFreeDelivery ? 'freeShipping' : 'freeShippingFrom');
  }

  if (isFrame) {
    usps.push('guaranteeText');
  }

  if (!canBeReturned) {
    usps.push('notReturnable');
  }

  // Product Advices/Warnings
  if (isKidsGlasses(currentVariant)) {
    usps.push('kidsAgeBracketTag');
  }

  // Product Warnings
  if (includeDrivingWarning) {
    usps.push('drivingWarning');
  }

  // Product Related Products
  if (!currentVariant.sku.startsWith('hardcase-rpmma-') && getAvailableSizes(product, currentVariant).length > 0) {
    usps.push('alsoAvailable');
  }

  if (hasClipOns && isMetalFrame) {
    usps.push('cliponsAvailableMetal');
  }

  if (hasClipOns && isAcetateFrame) {
    usps.push('cliponsAvailableAcetate');
  }

  return usps;
};
