import React from 'react';
import { useIntl } from 'react-intl';
import * as Styles from '../styles';
import { filterIconSrcMap, filterColorMap } from '../config';
import frameFilterMessages from 'messages/filters';

export function ChipIcon({ value }: { value: string }) {
  const intl = useIntl();

  const src = filterIconSrcMap[value];
  const color = filterColorMap[value];
  const message = frameFilterMessages[value];

  if (src) {
    return <Styles.ChipIcon src={src} alt={message ? intl.formatMessage(message) : value} />;
  }
  if (color) {
    return <Styles.ColorSwatch color={color} />;
  }
  return null;
}
