import React, { useState } from 'react';
import MobileZoom from '../ImageZoom/MobileZoom';
import Image from 'next/image';
import type { ProductImage } from 'types/images';

import Swiper from 'components/Swiper/Swiper';
import { breakpointRules } from '@aceandtate/ds';
import { getOptimizedProductUrl } from 'utils/cloudinary';

type ImageSliderProps = {
  images: Pick<ProductImage, 'alt' | 'isFrame' | 'src' | 'title' | 'type'>[];
};

export default function MobileCarousel(props: ImageSliderProps) {
  const { images } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div style={{ height: '100%' }}>
      <Swiper
        style={{ height: '100%' }}
        onSlideChange={swiper => {
          setActiveIndex(swiper.realIndex);
        }}
      >
        {images.length > 1 && (
          <div
            style={{
              bottom: '20px',
              left: 0,
              margin: 'auto',
              position: 'absolute',
              right: 0,
              width: 'fit-content',
              zIndex: 1
            }}
          >
            <Swiper.NavigationDots items={images} />
          </div>
        )}
        {images.map((image, i) => (
          <Swiper.SwiperSlide key={i}>
            <Image
              src={image.src}
              alt={image.alt}
              title={image.title}
              loading='lazy'
              quality={85}
              loader={({ src, width, quality }) =>
                getOptimizedProductUrl(src, { height: width, isFrame: image.isFrame, quality, type: image.type, width })
              }
              fill
              sizes={`${breakpointRules.laptop} 10px, 100vw`}
              style={{
                objectFit: 'cover',
                objectPosition: 'center'
              }}
            />
          </Swiper.SwiperSlide>
        ))}
      </Swiper>
      <MobileZoom images={images} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
    </div>
  );
}
