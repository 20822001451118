import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';
import { Toast } from '@aceandtate/ds';
import { useCartService, useCartState } from 'services/cartService';
import { ServicesContext } from 'services/context';
import NotificationsContext from 'features/Notifications/NotificationsContext';
import { paths } from 'paths';
import notificationMessages from 'messages/notifications';
import productConfiguratorMessages from 'messages/productConfigurator';
import { Product } from 'types/torii';

type Props = {
  currentVariant?: Product['currentVariant'];
};

export default function useAddToCart() {
  const cartService = useCartService();
  const router = useRouter();
  const ctx = useContext(ServicesContext);
  const { webStore } = ctx;
  const cartQuery = useCartState();
  const cart = cartQuery.data?.cart;
  const notifications = useContext(NotificationsContext.Context);

  return async (props: Props) => {
    const { currentVariant } = props;
    const { htoLimit } = webStore.config;
    const htoItemsRemaining = htoLimit - cart.hto_lines.length;

    if (cart.hto_lines.length >= htoLimit) {
      notifications.addNotification({
        action: closeFn => (
          <Toast.Action
            altText='view'
            onClick={() => {
              closeFn();
              router.push(paths.cart);
            }}
          >
            <FormattedMessage {...notificationMessages.view} />
          </Toast.Action>
        ),
        children: <FormattedMessage {...productConfiguratorMessages.htoLimitReachedSnackbar} values={{ htoLimit }} />,
        'data-testid': 'hto-limit-reached-notification',
        name: 'hto-limit-reached-notification'
      });
    } else if (htoItemsRemaining > 0) {
      await cartService.addToCart({
        is_hto: true,
        lens_color: null,
        lens_index: null,
        polarised: null,
        premium_lenses: null,
        prescription_type: null,
        variant_id: currentVariant.id
      });
      notifications.addNotification({
        action: closeFn => (
          <Toast.Action
            altText='view'
            onClick={() => {
              closeFn();
              router.push(paths.cart);
            }}
          >
            <FormattedMessage {...notificationMessages.view} />
          </Toast.Action>
        ),
        children: <FormattedMessage {...notificationMessages.addHtoItem} values={{ htoLeft: htoItemsRemaining - 1 }} />,
        'data-testid': 'hto-product-added-notification',
        name: 'hto-product-added-notification'
      });
    }
  };
}
