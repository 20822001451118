/** @defaults to "Europe/Amsterdam" if countryCode is not covered in function cases */
const getTimezoneForCountry = (countryCode: string) => {
  const centralEuropeTimezone = 'Europe/Amsterdam';
  const easternEuropeTimezone = 'Europe/Sofia';
  const westernEuropeTimezone = 'Europe/London';
  // If we open a store in a country with multiple timezone (opening a store in Spain, in Tenerife), this approach with have to be refined.

  switch (countryCode?.toLowerCase()) {
    case undefined:
      return;
    case 'gb':
    case 'ie':
    case 'pt':
      return westernEuropeTimezone;
    case 'bg':
    case 'ee':
    case 'fi':
    case 'gr':
    case 'lv':
    case 'lt':
    case 'ro':
      return easternEuropeTimezone;
    default:
      return centralEuropeTimezone;
  }
};

export default getTimezoneForCountry;
