import React from 'react';
import { FormattedMessage } from 'react-intl';
import { grid, uiColors } from '@aceandtate/ds';
import messages from '../messages';
import * as Styles from '../styles';

export type StockIndicatorProps = {
  isAvailable: boolean;
  withUnderline?: boolean;
  storeCode: string;
  streetName?: string;
  direction?: 'default' | 'reverse';
  size?: string;
  gap?: string;
};

export default function StockIndicator(props: StockIndicatorProps) {
  const { isAvailable, streetName, storeCode, withUnderline = true, direction, size = grid[8], gap = grid[8] } = props;

  return (
    <Styles.StockIndicatorGroup
      reverse={!streetName || direction === 'reverse'}
      data-testid={`storeStock.stockIndicator.${storeCode}`}
      gap={gap}
    >
      <span>
        {streetName &&
          (isAvailable ? (
            <FormattedMessage
              {...messages.availableAt}
              values={{
                storeStreet: streetName,
                u: chunks => (withUnderline ? <u>{chunks}</u> : <span>{chunks}</span>)
              }}
            />
          ) : (
            <FormattedMessage
              {...messages.unavailableAt}
              values={{
                storeStreet: streetName,
                u: chunks => (withUnderline ? <u>{chunks}</u> : <span>{chunks}</span>)
              }}
            />
          ))}
        {!streetName &&
          (isAvailable ? <FormattedMessage {...messages.available} /> : <FormattedMessage {...messages.unavailable} />)}
      </span>
      <Styles.StockIndicatorIcon style={{ background: isAvailable ? uiColors.success : uiColors.error }} size={size} />
    </Styles.StockIndicatorGroup>
  );
}
