import React from 'react';
import Modal from 'components/Modal';
import { FormattedMessage } from 'react-intl';
import { ProductVariant } from 'types/torii';
import { cliponCompatibilityMessages } from '../messages';
import ecommerceMessages from 'messages/ecommerce';
import { Button, Typography } from '@aceandtate/ds';

type Props = {
  variant: ProductVariant;
  onClose: () => void;
};

export default function CliponDisclaimer(props: Props) {
  const { variant, onClose } = props;

  return (
    <Modal open={true} onClose={onClose}>
      <Modal.Title>
        <FormattedMessage {...cliponCompatibilityMessages.disclaimerHint} />
      </Modal.Title>
      <Modal.Body>
        <Typography>
          <FormattedMessage
            {...cliponCompatibilityMessages.disclaimerNameCheck}
            values={{
              cliponName: variant.name,
              nameCheckHint: (
                <Typography as='span' fontWeight='bold'>
                  {<FormattedMessage {...cliponCompatibilityMessages.disclaimerNameCheckHint} />}
                </Typography>
              )
            }}
          />
        </Typography>
        <br />
        <Typography>
          <FormattedMessage
            {...cliponCompatibilityMessages.disclaimerColourwayCheck}
            values={{
              colourwayCheckHint: (
                <Typography as='span' fontWeight='bold'>
                  {<FormattedMessage {...cliponCompatibilityMessages.disclaimerColourwayCheckHint} />}
                </Typography>
              )
            }}
          />
        </Typography>
        <br />
        <Typography>
          <FormattedMessage {...cliponCompatibilityMessages.disclaimerReturnPolicy} />
        </Typography>
      </Modal.Body>
      <Modal.Buttons>
        <Button onClick={onClose}>
          <FormattedMessage {...ecommerceMessages.ok} />
        </Button>
      </Modal.Buttons>
    </Modal>
  );
}
