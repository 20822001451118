import { useContext } from 'react';
import { ServicesContext } from './context';
import useSWR from 'swr';
import * as Sentry from '@sentry/nextjs';

import { customFetch, useFetch, useToriiFetch } from 'utils/helpers/customFetch';
import { PickupLocation, Store } from 'types/solidus';
import type { StoreStock } from 'components/StoreStockAvailability';
import { ProductType } from 'types/torii';
import { Language } from 'types/language';

const SOLIDUS_ENDPOINT_BASE = process.env.NEXT_PUBLIC_SOLIDUS_ENDPOINT_BASE;

export function useGetPickupLocations() {
  const fetch = useFetch<PickupLocation[]>();
  const { webStore } = useContext(ServicesContext);

  return async (iso: string) => {
    const response = fetch(`/locations/${iso}`, {
      params: {
        // forces english language by overriding params and not including language param
        store_id: webStore.config.storeID // this is always added to params too, and should be restored with the override
      }
    });
    return response;
  };
}

export function useFetchStores() {
  const fetch = useFetch<Store[]>();

  return async () => {
    const response = fetch('/locations/full');
    return response;
  };
}

/**
 * Fetcher function and hook to get all stores.
 * The fetcher function by itself should only be used during SSG, otherwise use the useFetchStores hook.
 */

type StoreFetchOptions = {
  slug_filter?: string;
};

export async function fetchStores(lang?: Language, options?: StoreFetchOptions) {
  const { slug_filter } = options || {};
  const url = new URL(`${SOLIDUS_ENDPOINT_BASE}/locations/full`);
  lang && url.searchParams.set('language', lang);
  options?.slug_filter && url.searchParams.set('i18n_slug_filter', slug_filter);
  const { data: stores } = await customFetch<Store[]>(url.toString());
  return stores;
}

type StoreStockAvailabilityResponse = StoreStock[];

type StoreStockAvailabilityProps = {
  productType: ProductType;
  sku: string;
};

export interface StoreWithStockAvailabilityProps extends PickupLocation {
  stockCount: number;
}

/**
 * Hook to get stock availability for a chosen list of stores in a chosen country.
 */

export function useGetStoreStockAvailability({ sku, productType }: StoreStockAvailabilityProps) {
  const fetch = useToriiFetch<StoreStockAvailabilityResponse>();
  const getPickupLocations = useGetPickupLocations();
  const ctx = useContext(ServicesContext);
  const { webStore } = ctx;
  const currentCountry = webStore.country.iso;

  const { data } = useSWR(`/stock-availability/${sku}`, async () => {
    const storesWithLocations = await getPickupLocations(currentCountry);
    const storesIds = storesWithLocations.data.map((store: PickupLocation) => store.universal_store_id);
    const stockAvailability = await fetch(`/stock-availability/${sku}`, {
      params: {
        productType,
        stores: storesIds
      }
    });

    if (!stockAvailability.success) {
      const err = new Error(
        `An unexpected error occured when looking for available store stock. ${JSON.stringify(stockAvailability)}`
      );
      Sentry.captureException(err);
      return null;
    }

    const storeStockMap = stockAvailability.data.reduce((acc, a) => {
      acc.set(a.storeCode, a.stockCount);
      return acc;
    }, new Map());

    return storesWithLocations.data
      .filter((store: PickupLocation) => storeStockMap.has(store.universal_store_id))
      .map((store: PickupLocation) => {
        return {
          ...store,
          stockCount: storeStockMap.get(store.universal_store_id)
        };
      })
      .sort((a, b) => a.city.localeCompare(b.city));
  });

  return data;
}
