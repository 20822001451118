import React from 'react';
import { Typography } from '@aceandtate/ds';
import messages from '../../../messages';
import { FormattedMessage } from 'react-intl';

export default function EyeDropsIngredientsModal() {
  return (
    <>
      <Typography variant='h5' gutterBottom>
        <FormattedMessage {...messages.ingredientListTitle} />
      </Typography>
      <Typography variant='bodyM' gutterBottom>
        <FormattedMessage {...messages.eyedropsIngredientList} />
      </Typography>
      <Typography variant='bodyM'>
        <FormattedMessage {...messages.eyedropsIngredientNote} />
      </Typography>
    </>
  );
}

EyeDropsIngredientsModal.buttonText = messages.ingredientListLink;
