import React from 'react';
import * as Styles from './styles';

type Props = {
  title: string;
  body: React.ReactNode;
};

export default function SplitBanner(props: Props) {
  return (
    <Styles.BannerContainer>
      <Styles.Title variant='h2' forwardedAs='h1'>
        {props.title}
      </Styles.Title>
      <Styles.BannerParagraph>{props.body}</Styles.BannerParagraph>
    </Styles.BannerContainer>
  );
}
