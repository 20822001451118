import styled from 'styled-components';
import { grid, uiColors, breakpointRules } from '@aceandtate/ds';

export const Container = styled.div`
  margin: 0 0 ${grid[24]};
  color: ${uiColors.medium};
  max-width: 480px;
  @media ${breakpointRules.laptop} {
    width: 66%;
    margin: 0 0 ${grid[24]};
  }

  @media ${breakpointRules.desktop} {
    width: 50%;
  }

  h5 {
    margin-bottom: ${grid[12]} !important;
  }
`;
