import React from 'react';
import { LegalPage } from 'types/contentful';
import { useMediaQuery, breakpointRules, uiColors, Cell } from '@aceandtate/ds';
import OptimizedCFImage from 'components/Images/OptimizedCtfImage';
import { Asset } from 'contentful';
import * as Styles from './styles';
import LegacyContentfulRenderer from 'blocks/Contentful/LegacyContentfulRenderer';
import CustomHead from 'blocks/CustomHead';
import { LegalPageExtraProps } from 'types/path';

function LegalSidebar({ headerImage, background = uiColors.light }: { headerImage: Asset; background?: string }) {
  const isLaptop = useMediaQuery(breakpointRules.laptop);

  return (
    <Styles.SidebarCell columns={{ laptop: 12 / 5, mobile: 1 }}>
      <Styles.ImageSidebar background={background}>
        {headerImage && (
          <Styles.SidebarImageWrapper>
            <OptimizedCFImage
              image={headerImage}
              width={500}
              height={500}
              style={{ objectFit: 'contain', objectPosition: 'center', width: '100%', height: 'auto' }}
              sizes={isLaptop ? '40vw' : '100vw'}
            />
          </Styles.SidebarImageWrapper>
        )}
      </Styles.ImageSidebar>
    </Styles.SidebarCell>
  );
}

interface Props {
  content: LegalPage;
}

export default function LegalPageView({ content, alternatePaths }: Props & LegalPageExtraProps) {
  const { blocks, headerImage, imageBackground, title } = content;

  return (
    <>
      <CustomHead title={title} alternatePaths={alternatePaths} />

      <Styles.StyledGrid>
        <LegalSidebar background={imageBackground} headerImage={headerImage} />
        <Cell columns={{ desktop: 2, laptop: 12 / 7, mobile: 1 }}>
          <Styles.ContentContainer>
            <LegacyContentfulRenderer blocks={[{ blocks }]} />
          </Styles.ContentContainer>
        </Cell>
      </Styles.StyledGrid>
    </>
  );
}
