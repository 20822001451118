import { defineMessages } from 'react-intl';

export default defineMessages({
  blueLightFilterPdpDescription: {
    defaultMessage: `
    Protects your eyes from blue light of digital screens.
    <ul>
      <li>Relieves eye strain from screens</li>
      <li>Reduces glare</li>
      <li>Blocks 94% of blue light</li>
    </ul>
      `,
    id: 'pdp.blueLightFilterPdpDescription'
  },
  cleaningKitAllergyDisclaimer: {
    defaultMessage: 'May produce an allergic reaction.',
    id: 'pdp.cleaningKit.allergyDisclaimer'
  },
  cleaningKitECIngredients: {
    defaultMessage:
      'Ingredients according to (EC) No.648/2004: <5% amphoteric surfactants, SODIUM LAURETH-12 SULFATE, perfumes.',
    id: 'pdp.cleaningKit.ECIngredients'
  },
  cleaningKitIngredientList: {
    defaultMessage:
      'Contains 1-Propanaminium, 3-amino-N-(carboxymethyl)-N, N-dimethyl-, N-coco acyl derivatives, hydroxides, inner salts.',
    id: 'pdp.cleaningKit.ingredientList'
  },
  clearOptics: {
    defaultMessage: 'Crystal clear optics',
    id: 'pdp.skiGoggles.clearOptics'
  },
  clearOpticsCaption: {
    defaultMessage: 'All lenses offer 100% UV protection and are double-layered to reduce fogging.',
    id: 'pdp.skiGoggles.clearOpticsCaption'
  },
  cliponsHeading: {
    defaultMessage: 'Can I clip it? Yes you can!',
    id: 'usps.pdp.cliponsHeading'
  },
  comfortableFit: {
    defaultMessage: 'Comfortable fit',
    id: 'pdp.skiGoggles.comfortableFit'
  },
  comfortableFitCaption: {
    defaultMessage: 'Fits comfortably with an adjustable strap and soft padding on the inside.',
    id: 'pdp.skiGoggles.comfortableFitCaption'
  },
  eyedropsIngredientList: {
    defaultMessage:
      'Hyaluronic acid sodium salt (high molecular weight), Hyaluronic acid sodium salt (low molecular weight), L-Glycine, Vitamin E TPGS, Extract of Centella Asiatica (L.) Urban (titrated in asiaticoside ≥ 3%), Polyvinylpyrrolidone, D-Mannitol, Sodium Phosphate monobasic monohydrate, Sodium Phosphate dibasic dodecahydrate, Sodium Chloride, Water for injection up to 100 ml.',
    id: 'pdp.eyedropsIngredientList'
  },
  eyedropsIngredientNote: {
    defaultMessage: 'Note: the content must be used within 3 months after the first opening',
    id: 'pdp.eyedropsIngredientNote'
  },
  eyedropsInstructionLink: {
    defaultMessage: 'Instructions for use',
    id: 'pdp.eyedropsInstructionLink'
  },
  eyedropsInstructionList: {
    defaultMessage: `
    <ol>
    <li>Make sure your hands are squeaky clean</li>
    <li>Tear off the seal for first time usage — if this seal is already broken, don’t use the drops</li>
    <li>Open the multi-dose bottle by removing the cap</li>
    <li>Look up to the ceiling/sky/stars etc., hold the bottle above your eye upside down</li>
    <li>Squeeze 2 drops into each eye, unless you have a special prescription for more</li>
    <li>As the bottle empties, you may need to squeeze a little harder for the drops to dispense</li>
    <li>If you wear contact lenses, you can add a drop to your lenses before putting them in</li>
    <li>Don’t forget to close the bottle immediately after use!</li>
    </ol>`,
    id: 'pdp.eyedropsInstructionList'
  },
  eyedropsInstructionTitle: {
    defaultMessage: 'Instructions',
    id: 'pdp.eyedropsInstructionTitle'
  },
  hardCaseEOLDescription: {
    defaultMessage: `If you no longer wish to use your case, please return your used case to any Ace & Tate store, as we will recycle
    these valuable materials again. If you want to dispose of your case locally, please do so responsibly by exploring
    local recycling routes. Felt inlay needs to be separated and can be disposed of with general waste. Due to
    adhesive and multiple textile compositions, the material becomes non-recyclable.`,
    id: 'pdp.productModal.hardCaseEOLDescription'
  },
  hardCaseEOLLink: {
    defaultMessage: 'End of life',
    id: 'pdp.productModal.hardCaseEOLLink'
  },
  hardCaseMaterialInformationTitle: {
    defaultMessage: 'Materials',
    id: 'pdp.productModal.hardCaseMaterialInformationTitle'
  },
  hardCaseMaterialsDemoLensesDescription: {
    defaultMessage: '100% recycled demo lenses (acrylic plastic)',
    id: 'pdp.productModal.hardCaseMaterialsDemoFramesDescription'
  },
  hardCaseMaterialsLink: {
    defaultMessage: 'Material Information',
    id: 'pdp.productModal.hardCaseMaterialsLink'
  },
  hardCaseMaterialsList: {
    defaultMessage: `
    Inlay:

    <ul>
      <li>50% certified recycled wool felt (GRS certified)</li>
      <li>30% wool (R.W.S certified)</li>
      <li>20% polyester</li>
      <li>From Italy</li>
    </ul>
    `,
    id: 'pdp.productModal.hardCaseMaterialsList'
  },
  hardCaseMaterialsTitle: {
    defaultMessage: 'Materials',
    id: 'pdp.productModal.hardCaseMaterialsTitle'
  },
  skiGogglesWarningsLink: {
    defaultMessage: 'See product safety',
    id: 'pdp.skiGogglesWarningsLink'
  },
  skiGogglesWarningsTitle: {
    defaultMessage: 'Product Safety Information:',
    id: 'pdp.skiGogglesWarningsTitle'
  },
  hardCaseSizeTooltip: {
    defaultMessage:
      'The Hardcase comes in two sizes. Pick large for larger frames like Bella and Byron Large. Pick small for small to medium frames like Neil and Pierce.',
    id: 'pdp.hardCase.sizeTooltip'
  },
  ingredientListLink: {
    defaultMessage: 'See ingredient list',
    id: 'pdp.ingredientListLink'
  },
  ingredientListTitle: {
    defaultMessage: 'Ingredient List',
    id: 'pdp.ingredientListTitle'
  },
  interchangableLenses: {
    defaultMessage: 'Interchangeable lenses',
    id: 'pdp.skiGoggles.interchangableLenses'
  },
  interchangableLensesCaption: {
    defaultMessage: 'Each colourway comes with an extra amber lens. Simply click it in to switch up.',
    id: 'pdp.skiGoggles.interchangableLensesCaption'
  },
  lensGuideBlockSubtitle: {
    defaultMessage: 'All our lenses include anti-reflection and anti-scratch coatings.',
    id: 'pdp.lensGuideBlockSubtitle'
  },
  lensGuideBlockTitle: {
    defaultMessage: 'What type of upgrades do we sell?',
    id: 'pdp.lensGuideBlockTitle'
  },
  lensType: {
    defaultMessage: 'Lens type',
    id: 'pdp.clipon.lensType'
  },
  lightFit: {
    defaultMessage: 'Light fit and feel',
    id: 'pdp.sportGlasses.lightFit'
  },
  lightFitCaption: {
    defaultMessage:
      "Don't let your gear bring you down. We made each frame with 3D printing technology from bio-based nylon — lightweight but also strong and flexible.",
    id: 'pdp.sportGlasses.lightFitCaption'
  },
  materialDescriptionLink: {
    defaultMessage: 'See material description',
    id: 'pdp.materialDescriptionLink'
  },
  materialDescriptionTitle: {
    defaultMessage: 'Materials',
    id: 'pdp.materialDescriptionTitle'
  },
  moveAroundPill: {
    defaultMessage: 'You can move around freely',
    id: 'pdp.moveAroundPill'
  },
  normal: {
    defaultMessage: 'Normal',
    id: 'pdp.clipon.normal'
  },
  nosepads: {
    defaultMessage: 'Nosepads',
    id: 'pdp.nosepads'
  },
  nosepadsInfo: {
    defaultMessage:
      'Our {framename} is made of high quality acetate. You can now add adjustable nose pads - making the frame easier to adjust to your face. Especially great for lower or wider nose bridges.',
    id: 'pdp.nosepadsInfo'
  },
  onlyRoundNumbersCaption: {
    defaultMessage: 'You can only use rounded numbers',
    id: 'pdp.variablePrice.onlyRoundNumbersCaption'
  },
  origamiCaseMaterialDescription: {
    defaultMessage:
      'Eco Canvas (Banksy) — recycled-cotton 60% + conventional cotton 40% {br} Liner: recycled polyester 100%',
    id: 'pdp.origamiCase.materialDescription'
  },
  otherAmount: {
    defaultMessage: 'Other amount',
    id: 'pdp.variablePrice.otherAmount'
  },
  peakStyle: {
    defaultMessage: 'Peak style',
    id: 'pdp.skiGoggles.peakStyle'
  },
  peakStyleCaption: {
    defaultMessage: 'Look good on the slopes with a minimal, sleek design and frameless front.',
    id: 'pdp.skiGoggles.peakStyleCaption'
  },
  performance: {
    defaultMessage: 'Performance details',
    id: 'pdp.sportGlasses.performance'
  },
  performanceCaption: {
    defaultMessage:
      'Comfort is key. Our sports glasses are fitted with Polarised Lenses, adjustable nose pads and grips on the temples to keep them in place.',
    id: 'pdp.sportGlasses.performanceCaption'
  },
  polarised: {
    defaultMessage: 'Polarised',
    id: 'pdp.clipon.polarised'
  },
  productBenefits: {
    defaultMessage: 'Product benefits',
    id: 'pdp.skiGoggles.productBenefits'
  },
  productSizeLarge: {
    defaultMessage: 'Large',
    id: 'pdp.productSizeLarge'
  },
  productSizeSmall: {
    defaultMessage: 'Small',
    id: 'pdp.productSizeSmall'
  },
  productSizeTitle: {
    defaultMessage: 'Size',
    id: 'pdp.productSizeTitle'
  },
  productSpecifications: {
    defaultMessage: 'Specifications',
    id: 'pdp.productSpecifications'
  },
  recommendationsHeading: {
    defaultMessage: 'These would look good on you.',
    id: 'pdp.recommendationsHeading'
  },
  productRecommendations: {
    defaultMessage: 'Product recommendations',
    id: 'pdp.productRecommendations'
  },
  selectAnAmount: {
    defaultMessage: 'Select an amount',
    id: 'pdp.variablePrice.selectAnAmount'
  },
  shopError: {
    defaultMessage: 'Something is wrong with our shop. Ordering this product is not possible at this time. Sorry!',
    id: 'pdp.shopError'
  },
  sophistication: {
    defaultMessage: 'Sporty sophistication',
    id: 'pdp.sportGlasses.sophistication'
  },
  sophisticationCaption: {
    defaultMessage:
      "We wouldn't be us if we didn't make sure our frames look real good. Pick from a square classic, retro sporty style or an oversized yet subtle statement maker. ",
    id: 'pdp.sportGlasses.sophisticationCaption'
  },
  sustainability: {
    defaultMessage: 'Carbon impact: {carbonFootprint}',
    id: 'usps.pdp.details.carbonFootprint'
  },
  thinLenses: {
    defaultMessage: '{thinLensesLink} prescription lenses',
    id: 'pdp.usps.included.thinLenses'
  },
  thinLensesLink: {
    defaultMessage: 'Super thin',
    id: 'pdp.usps.included.thinLensesLink'
  },
  upToAmount: {
    defaultMessage: 'up to {amount}',
    id: 'pdp.variablePrice.upToAmount'
  },
  variablePriceCustomLabel: {
    defaultMessage: 'Custom',
    id: 'pdp.variablePrice.customLabel'
  },
  variablePriceMaxValueError: {
    defaultMessage: 'The maximum allowed value is {value}',
    id: 'pdp.variablePrice.maxValueError'
  },
  variablePriceMinValueError: {
    defaultMessage: 'The minimum allowed value is {value}',
    id: 'pdp.variablePrice.minValueError'
  },
  width: {
    defaultMessage: 'This is a {width} frame',
    id: 'pdp.usps.details.size'
  },
  withNosepads: {
    defaultMessage: 'With nosepads',
    id: 'pdp.withNosepads'
  },
  withoutNosepads: {
    defaultMessage: 'Regular',
    id: 'pdp.withoutNosepads'
  }
});

export const cliponCompatibilityMessages = defineMessages({
  disclaimerColourwayCheck: {
    defaultMessage: `{colourwayCheckHint} We don’t recommend combining a Clip-on with our metal frames that have an acetate rim (Tigerwood, Space, Windsor Rim). That’s because the acetate part is delicate and could become damaged through frequent clippin’.`,
    id: 'pdp.clipon.compatibilityDisclaimer.colourwayCheck'
  },
  disclaimerColourwayCheckHint: {
    defaultMessage: 'Check the colourway:',
    id: 'pdp.clipon.compatibilityDisclaimer.colourwayCheck.hint'
  },
  disclaimerHint: {
    defaultMessage: 'Can you clip it?',
    id: 'pdp.clipon.compatibilityDisclaimer.hint'
  },
  disclaimerNameCheck: {
    defaultMessage: `{nameCheckHint} Double-check that {cliponName} matches the name of your frame, and make sure you pick the right size.`,
    id: 'pdp.clipon.compatibilityDisclaimer.nameCheck'
  },
  disclaimerNameCheckHint: {
    defaultMessage: `Check the name:`,
    id: 'pdp.clipon.compatibilityDisclaimer.nameCheck.hint'
  },
  disclaimerReturnPolicy: {
    defaultMessage: `But, if things go wrong — hey, it happens — we have a 30 day return policy.`,
    id: 'pdp.clipon.compatibilityDisclaimer.returnPolicy'
  },

  prompt: {
    defaultMessage: 'Is your frame compatible with a clip-on? {link}',
    id: 'pdp.clipon.compatibilityPrompt'
  },
  promptLink: {
    defaultMessage: 'Find out',
    id: 'pdp.clipon.compatibilityPromptLink'
  }
});
