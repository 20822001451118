import { defineMessages } from 'react-intl';

export default defineMessages({
  closesAt: {
    defaultMessage: 'Closes at {time}',
    id: 'storeStockAvailability.closesAt'
  },
  opensAt: {
    defaultMessage: 'Opens at {time}',
    id: 'storeStockAvailability.opensAt'
  },
  opensAtOn: {
    defaultMessage: 'Opens at {time} on {day}',
    id: 'storeStockAvailability.opensAtOn'
  }
});
