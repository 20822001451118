import React from 'react';
import * as Styles from './styles';
import Legal from './partials/Legal';

type Props = {
  blocks: any[];
  animateTypographies?: boolean;
};
/**
 * BlockRenderers keys correspond to Contentful Entry types
 * Values correspond to components that get passed the entries
 */
const BlockRenderers = {
  // V2 Contentful Components
  LegalPageBlock: Legal
};

export default function LegacyContentfulRenderer(props: Props) {
  const { blocks = [], animateTypographies = false } = props;

  return (
    <main>
      {blocks.map(container => (
        <Styles.BlockContainer key={container.id} animateTypographies={animateTypographies} {...container}>
          {(container.blocks || []).map(item => {
            const blockType = item.contentType as keyof typeof BlockRenderers;
            const BlockComponent = BlockRenderers[blockType];
            if (typeof BlockComponent === 'function') {
              return <BlockComponent key={item.id} {...item} />;
            }
            return null;
          })}
        </Styles.BlockContainer>
      ))}
    </main>
  );
}
