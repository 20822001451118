import React from 'react';
import config from 'iris.config';

import type { Locale } from 'types/locale';
import type { LandingPageProps } from 'types/page';
import type { Product } from 'types/torii';
import type { AsyncReturnType } from 'type-fest';
import type { Collection, LandingPage, LegalPage } from 'types/contentful';
import type { Entry } from 'contentful';

import baseNames from 'plugins/prerenderLocales';

import {
  getRoutedEntry,
  retrieveLandingPageData,
  retrieveCollectionPageData,
  retrieveLegalPageData,
  getProductDetailBlocks
} from 'services/contentful';

import { getLocale } from 'services/locale';
import { getCatalogPath, fetchProduct } from 'services/productsService';
import { getRedirect } from 'services/ecommService';
import { StaticProps } from 'services/context';

import retrieveFromContentfulCache from 'utils/buildCache/retrieveFromContentfulCache';
import retrieveFromProductCache, { getSkuPaths } from 'utils/buildCache/retrieveFromProductCache';
import retrieveFromLocaleCache from 'utils/buildCache/retrieveFromLocaleCache';

import shouldHideProduct from 'utils/helpers/shouldHideProduct';

import { GetStaticPropsContext } from 'next';
import { getSunnyOrOpticalVariants } from 'utils/product/productHelpers';
import { processProductEnrichedBlocks } from 'services/contentful/helpers';
import { getUrlEntryByUrlRoute } from 'services/contentful/graphql';
import type {
  CatchAllProps,
  MakeResult,
  CommonPageProps,
  PageSpecificProps,
  GetContentfulProductBlocks,
  ModernContentfulPage
} from 'types/path';
import { PageRenderer } from 'blocks/PageRenderer';

export default function Page(props: CatchAllProps) {
  return <PageRenderer {...props} />;
}

export async function getStaticPaths() {
  const renderedLocales = Object.keys(baseNames);
  const skus = renderedLocales.flatMap(loc => {
    const locale = getLocale(loc);
    return getSkuPaths(locale);
  });

  return {
    fallback: true,
    paths: skus
  };
}

export async function getStaticProps(
  props: GetStaticPropsContext & LandingPageProps
): Promise<StaticProps<CatchAllProps>> {
  const { params, preview: draftMode = false } = props;

  const locale = getLocale(props.locale);

  if (!locale) {
    return {
      notFound: true
    };
  }

  const [pageLayoutData, webStore, messages] = await Promise.all([
    retrieveFromContentfulCache('pageLayoutData', locale, { draftMode }),
    retrieveFromContentfulCache('webStore', locale),
    retrieveFromLocaleCache(locale)
  ]);

  const path = `/${params.path.join('/')}`;
  const isValidUrlRoute = !path.includes('.');

  const commonProps = {
    draftMode,
    footer: pageLayoutData.footer,
    key: path,
    locale,
    menuNavigation: pageLayoutData.menu,
    messages,
    params,
    path,
    webStore
  };

  // 0. return from build cache
  if (config.productBuildCacheEnabled) {
    const result = await makeProductResultFromBuildCache({ path, commonProps });
    if (result) {
      return result;
    }
  }

  // Check type of entry
  const content = isValidUrlRoute ? await getUrlEntryByUrlRoute(path, locale, draftMode) : undefined;
  // 1.   no url route was found, look for SKU in catalog
  if (!content || content.urlRouteCollection.items.length === 0) {
    const result = await makeProductResultFromCatalog({ path, commonProps });
    if (result) {
      return result;
    }

    return await handleNotFound(path, locale);
  }

  // 2. Found a url route but browsing in wrong locale for that route. Need to redirect
  if (content.urlRouteCollection.items[0]?.path && content.urlRouteCollection.items[0].path !== path) {
    return {
      redirect: {
        destination: `/${locale.prefix}${content?.urlRouteCollection.items[0]?.path}`,
        permanent: false
      }
    };
  }

  // 3. found a country override
  if (content.urlRouteCollection.items[0]?.countryOverrideReferencesCollection.items.length > 0) {
    // 3a.  country override has new BasePage type, use provided to render page
    if (content.urlRouteCollection.items[0]?.countryOverrideReferencesCollection.items[0]?.__typename === 'BasePage') {
      console.debug('3a. RENDER COUNTRY OVERRIDE <BasePage>'); // eslint-disable-line
      const result = renderModernContentfulPage({ commonProps, urlRouteCollection: content });
      if (result) {
        return result;
      }
    } else {
      // 3b.  country override has other type, fetch from content delivery api instead
      console.debug('3b. RENDER COUNTRY OVERRIDE <Old Pages>'); // eslint-disable-line
      const result = await renderLegacyContentfulPage({ commonProps });
      if (result) {
        return result;
      }
    }
  }

  // 4.   found a valid url route without country override
  // 4a.  __typename is BasePage, use provided data to render page
  if (content.urlRouteCollection.items[0]?.reference?.__typename === 'BasePage') {
    console.debug('4a. RENDER <BasePage>'); // eslint-disable-line
    const result = renderModernContentfulPage({ commonProps, urlRouteCollection: content });

    if (result) {
      return result;
    }
  }
  // 4b.  __typename is another type, fetch from content delivery api instead
  if (content.urlRouteCollection.items[0]?.reference?.__typename) {
    console.debug('4b. RENDER <Old Pages>'); // eslint-disable-line
    const result = await renderLegacyContentfulPage({ commonProps });

    if (result) {
      return result;
    }
  }

  return await handleNotFound(path, locale);
}

function renderModernContentfulPage({ commonProps, urlRouteCollection }: ModernContentfulPage) {
  return makeResult('landing page v2', commonProps, {
    type: 'v2landing',
    urlRouteCollection
  });
}

async function renderLegacyContentfulPage({ commonProps }: Pick<MakeResult, 'commonProps'>) {
  const { locale, params, draftMode, webStore } = commonProps;

  const result = await getRoutedEntry<LandingPage | LegalPage | Collection>(locale, params.path, 5, {
    draftMode
  });

  if ('entry' in result) {
    const entryType = result.entry?.sys.contentType.sys.id;
    if (entryType === 'LandingPage') {
      const entry = result.entry as Entry<LandingPage>;
      const landingPage = await retrieveLandingPageData(entry, webStore, { draftMode, locale });

      return makeResult('landing page', commonProps, {
        alternatePaths: result.alternates,
        landingPage,
        type: 'landing',
        urlRoute: result.urlRoute
      });
    }

    // Collection, load collection data and apply filters, etc.
    else if (entryType === 'Collection') {
      const collectionPage = result.entry as Entry<Collection>;
      const collection = await retrieveCollectionPageData(collectionPage, locale, webStore, {
        draftMode,
        source: 'pcp'
      });

      return makeResult('collection', commonProps, {
        alternatePaths: result.alternates,
        collection,
        type: 'collection',
        urlRoute: result.urlRoute
      });
    }
    // LegalPage
    else if (entryType === 'LegalPage') {
      const content = retrieveLegalPageData(result.entry as Entry<LegalPage>, locale, webStore);
      return makeResult('legal', commonProps, {
        alternatePaths: result.alternates,
        content,
        type: 'legal'
      });
    }
  }
}

async function handleNotFound(path: string, locale: Locale) {
  const NOT_FOUND = {
    notFound: true
  } as const;
  const redirect = await getRedirect(path);

  if (redirect.success) {
    return {
      redirect: {
        destination: `/${locale.prefix}${redirect.data.destination}`,
        permanent: redirect.data.permanent
      }
    };
  } else {
    return NOT_FOUND;
  }
}

async function getContentfulProductBlocks({ locale, draftMode, product }: GetContentfulProductBlocks) {
  const productDetailBlocks = (await retrieveFromContentfulCache('productDetailBlocks', locale, {
    draftMode
  })) as AsyncReturnType<typeof getProductDetailBlocks>;

  const variantsProcessedBlocks = getSunnyOrOpticalVariants(product).reduce((acc, variant) => {
    const productWithVariant = {
      productType: product.productType,
      currentVariant: variant
    } as Product;
    acc[variant.sku] = processProductEnrichedBlocks(productDetailBlocks, productWithVariant);
    return acc;
  }, {} as Record<string, ReturnType<typeof processProductEnrichedBlocks>>);

  return variantsProcessedBlocks;
}

async function makeProductResultFromBuildCache({ path, commonProps }: MakeResult) {
  const { locale, draftMode } = commonProps;
  const sku = path.replace('/', '');
  const isValidSku = !sku.includes('/') && !sku.includes('.');

  if (!isValidSku) {
    return null;
  }

  const product = retrieveFromProductCache(sku, locale);

  if (product) {
    const variantsProcessedblocks = await getContentfulProductBlocks({ locale, draftMode, product });

    return makeResult('product', commonProps, {
      product,
      type: 'product',
      contentful: {
        variants: variantsProcessedblocks
      }
    });
  }
}

async function makeProductResultFromCatalog({ path, commonProps }: MakeResult) {
  const { locale, draftMode } = commonProps;
  const sku = path.replace('/', '');
  const isValidSku = !sku.includes('/') && !sku.includes('.');

  if (!isValidSku) {
    return null;
  }

  const catalogPath = getCatalogPath(`/products/filter?sku=${sku}`, locale, { draftMode, visibility: ['pdp'] });
  const product = await fetchProduct(catalogPath);

  // 4a. SKU found, render PDP
  if (product && !shouldHideProduct(product)) {
    const variantsProcessedblocks = await getContentfulProductBlocks({ locale, draftMode, product });

    return makeResult('product', commonProps, {
      product,
      contentful: {
        variants: variantsProcessedblocks
      },
      type: 'product'
    });
  }
}

function makeResult(pageName: string, common: CommonPageProps, specifics: PageSpecificProps) {
  return {
    props: {
      pageName,
      ...common,
      ...specifics
    }
  };
}
