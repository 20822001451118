import { defineMessages } from 'react-intl';

export default defineMessages({
  cliponAcetateModal: {
    defaultMessage:
      'Our Clip-ons transform your trusty specs into sunnies in the blink of an eye - with practical clips, that slide onto your frame easily and stay secure. {br} {br} To get Clip-ons, simply select them in the accessories step while adding the frame to your basket!',
    id: 'cliponsModal.cliponAcetateModal'
  },
  cliponMetalModal: {
    defaultMessage:
      'Our Clip-ons transform your trusty specs into sunnies in the blink of an eye - with practical clips, that slide onto your frame easily and stay secure. {br} {br} To get Clip-ons, simply select them in the accessories step while adding the frame to your basket! {br} {br} Good to know: Metal frames with acetate rims are not compatible with Clip-ons, because the frequent clipping can damage the rim part.',
    id: 'cliponsModal.cliponMetalModal'
  },
  cliponModalTitle: {
    defaultMessage: 'What are clip-ons?',
    id: 'cliponsModal.cliponModalTitle'
  }
});
