import React, { useEffect, useState } from 'react';
import { Swiper, useSwiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import { Flex, IconButton, IconChevronLeft, IconChevronRight, borderRadius, brandColors, grid } from '@aceandtate/ds';
import styled from 'styled-components';

const StyledDot = styled.button<{ isActive: boolean }>`
  border-radius: ${borderRadius.full};

  background-color: ${({ isActive }) => (isActive ? brandColors.dark : '#D9D9D9')};
  padding: ${grid[4]};
`;

export function NavigationDots({ items = [], style = {}, className = '', initialIndex = 0 }) {
  const swiper = useSwiper();
  const [activeIndex, setActiveIndex] = useState(initialIndex);

  // useSwiper is not reactive
  useEffect(() => {
    swiper &&
      swiper.on('slideChange', swipe => {
        setActiveIndex(swipe.realIndex);
      });
    return () => {
      swiper.off('slideChange');
    };
  }, [swiper]);

  const dots = items.length > 0 ? items : swiper.slides;

  if (!dots || dots.length === 0) {
    return null;
  }

  return (
    <Flex
      alignItems='center'
      gap={grid[8]}
      style={{ backgroundColor: 'white', borderRadius: borderRadius.full, padding: `${grid[4]} ${grid[8]}`, ...style }}
      className={className}
    >
      {dots.map((a, i) => (
        <StyledDot onClick={() => swiper.slideToLoop(i)} isActive={activeIndex === i} key={i} />
      ))}
    </Flex>
  );
}

export function NextButton() {
  const swiper = useSwiper();

  return (
    <IconButton variant='outlined' onClick={() => swiper.slideNext()} style={{ backgroundColor: 'white' }}>
      <IconChevronRight fontSize='24px' />
    </IconButton>
  );
}

export function PreviousButton() {
  const swiper = useSwiper();

  return (
    <IconButton variant='outlined' onClick={() => swiper.slidePrev()} style={{ backgroundColor: 'white' }}>
      <IconChevronLeft fontSize='24px' />
    </IconButton>
  );
}

export default Object.assign(Swiper, {
  NavigationDots,
  NextButton,
  PreviousButton,
  SwiperSlide
});
