import { brandColors, grid, borderRadius, IconButton } from '@aceandtate/ds';
import styled from 'styled-components';

export const Background = styled.div`
  background-color: ${brandColors.sandLight};
  position: fixed;
  z-index: 100;
  isolation: isolate;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  margin-top: auto;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${grid[24]};
  border-top: 1px solid grey;
`;

export const MoveAroundPill = styled.span`
  padding: ${grid[8]} ${grid[16]};
  border: 1px solid ${brandColors.dark20};
  border-radius: ${borderRadius.full};
  background-color: white;
  width: fit-content;
  position: absolute;
  margin: auto;
  display: flex;
  align-items: center;
  gap: 8px;
  top: 20px;
  left: 0px;
  right: 0px;
  z-index: 1;
`;

export const ZoomButton = styled(IconButton)`
  position: absolute;
  bottom: 24px;
  right: 24px;
  z-index: 1;
  background-color: white;
  :hover {
    background-color: white;
  }
`;
