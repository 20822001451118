import styled from 'styled-components';
import {
  grid,
  brandColors,
  IconButton,
  Button,
  uiColors,
  breakpointRules,
  Collapsible,
  RadioGroup
} from '@aceandtate/ds';

type StyleProps = {
  reverse: boolean;
  gap: string;
};

type HeaderProps = {
  hasError: boolean;
};

type SlideAnimationInnerScrollProps = {
  stepIndex: number;
};

export const Slide = styled.div`
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-flow: column;
  overflow: auto;
  position: relative;
`;

export const SlideAnimationWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

export const SlideAnimationInnerScroll = styled.div<SlideAnimationInnerScrollProps>`
  display: flex;
  height: 100%;
  transition: transform 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: ${props => `translateX(-${props.stepIndex * 100}%)`};
`;

export const ButtonRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ContentContainer = styled.div`
  padding: 0 ${grid[24]};
  max-height: 100%;

  @media ${breakpointRules.laptop} {
    padding: 0 ${grid[40]};
  }
`;

export const StoreListContainer = styled(RadioGroup.Root)`
  gap: unset;
`;

export const StoreItemContainer = styled.label`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: ${grid[24]} 0;
  cursor: pointer;
  border-bottom: 1px solid ${brandColors.dark10};

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

export const HeaderWrapper = styled.div<HeaderProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${grid[12]};
  padding-bottom: ${({ hasError }) => (hasError ? '0' : `${grid[24]}`)};
  border-bottom: ${({ hasError }) => (hasError ? 'none' : `1px solid ${brandColors.dark10}`)};
`;

type BodyProps = {
  withGap?: boolean;
};

export const Body = styled.div<BodyProps>`
  display: flex;
  flex-direction: column;
  gap: ${props => (props.withGap ? `${grid[24]}` : 0)};
  padding-bottom: ${grid[24]};
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100;
  background-color: ${brandColors.light};
  padding: ${grid[12]};

  @media ${breakpointRules.laptop} {
    padding: ${grid[12]} ${grid[24]};
  }
`;

export const ToggleGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${grid[12]};
  width: 100%;
`;

export const StyledIconButton = styled(IconButton)`
  background: transparent;
  color: ${brandColors.dark};

  :hover {
    background: ${brandColors.dark10};
    color: ${brandColors.dark};
  }
`;

export const Disclaimer = styled.div`
  display: flex;
  padding: ${grid[12]};
  margin-bottom: ${grid[24]};
  background: ${uiColors.warningLight};
  border-radius: ${grid[4]};
`;

export const SDSDisclaimer = styled.div`
  display: flex;
  padding: ${grid[12]};
  background: ${uiColors.infoLight};
  border-radius: ${grid[4]};
`;

export const InfoIcon = styled.div`
  padding-right: ${grid[12]};
`;

export const StockIndicatorGroup = styled.div<StyleProps>`
  align-items: center;
  display: flex;
  flex-flow: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  flex-wrap: wrap;
  justify-content: left;
  gap: ${({ gap }) => gap};
`;

export const StockIndicatorIcon = styled.span<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background: ${uiColors.error};
  border-radius: 50%;
  text-align: center;
  margin-left: ${grid[4]};
`;

export const StyledButton = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StoreAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${grid[8]};

  svg {
    color: ${brandColors.accent};
    cursor: pointer;
  }
`;

export const OpeningHours = styled(Collapsible.Root)`
  display: flex;
  flex-direction: column;
  margin: ${grid[8]} 0;
`;

export const OpeningHoursTitle = styled(Collapsible.Trigger)`
  display: flex;
  gap: ${grid[8]};
  justify-content: space-between;
`;

export const OpeningHoursContent = styled(Collapsible.Content)`
  padding-left: ${grid[24]};
  margin-bottom: ${grid[8]};
`;

export const OpeningHoursIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: ${grid[8]};
  margin-bottom: ${grid[8]};
`;

export const OpeningHoursIndicatorIcon = styled.span`
  width: ${grid[12]};
  height: ${grid[12]};
  background: ${uiColors.error};
  border-radius: 50%;
  text-align: center;
`;

export const StoreLink = styled(Button).attrs({ variant: 'link' })`
  text-decoration: none;
  font-weight: 200;
`;

export const searchErrorBox = styled.div`
  border-radius: ${grid[4]};
  padding: ${grid[12]};
  background: ${uiColors.infoLight};
`;

type StoresStockButtonProps = {
  withUnderline: boolean;
};

export const StoresStockButton = styled(Button)<StoresStockButtonProps>`
  color: ${brandColors.dark};
  font-weight: normal;
  text-decoration: ${({ withUnderline }) => (withUnderline ? 'underlined' : 'none')};
`;

export const FloatingButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${grid[24]};
  margin: 0 -${grid[24]};
  background: ${brandColors.light};
  border-top: 1px solid ${brandColors.dark10};

  @media ${breakpointRules.laptop} {
    margin: 0 -${grid[40]};
  }
`;

export const Footnote = styled.div`
  border-top: 1px solid ${brandColors.dark10};
  padding: ${grid[24]} 0;
`;
