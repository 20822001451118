import React, { useState, useMemo, useEffect } from 'react';
import { NextRouter, useRouter } from 'next/router';
import { FormattedMessage, useIntl } from 'react-intl';
import { breakpointRules, Cell, Typography, useMediaQuery } from '@aceandtate/ds';
import SplitBanner from 'components/SplitBanner';
import FiltersDrawer from 'components/FiltersDrawer';
import {
  FilterBar,
  FilterTags,
  ServiceFilters,
  ProductGrid,
  SeoDescription,
  ExpandedFrameFilters,
  SortSelector
} from './partials';
import renderRichTextV2 from 'utils/contentful/renderRichTextV2';
import { laptopFilters, mobileFilters } from './config';
import { productCollectionSortMap as productsSortMap, SortOptions } from 'utils/product/sortProducts';
import { pcpEvents } from 'tracking';
import { trackArtificialPageView, trackPageEvent } from 'tracking/helpers';
import type { Document } from '@contentful/rich-text-types';
import type { Product } from 'types/torii';
import type { AppliedFiltersType } from 'components/FiltersDrawer/types';
import type * as Types from './types';
import * as Styles from './styles';
import messages from './messages';
import { CollectionPageExtraProps, CommonPageProps } from 'types/path';
import CustomHead from 'blocks/CustomHead';
import getCollectionMetaContent from 'utils/helpers/getCollectionMetaContent';
import { transformCfAsset } from 'utils/images';
import pageTitles from 'messages/pageTitles';
import { getFilteredProducts } from 'views/Stores/helpers/getFilteredProducts';

const initialFrameAttributeFilters: AppliedFiltersType = {
  colorPatterns: [],
  colors: [],
  materials: [],
  prices: [],
  shapes: [],
  styles: [],
  widths: []
};

function isSortOption(input: any) {
  return Array.from(productsSortMap.keys()).includes(input);
}

function sortBy(option: SortOptions, products: Product[]) {
  return productsSortMap.get(option).action(products);
}

function handleRouteChange(_url, { shallow }) {
  shallow && trackArtificialPageView('');
}

function updateQuery(router: NextRouter) {
  const { query } = router;
  const newRouterQuery = JSON.parse(JSON.stringify({ ...query }));
  delete newRouterQuery.path;

  router.replace(
    {
      pathname: router.asPath.split('?')[0],
      query: newRouterQuery
    },
    undefined,
    { shallow: true }
  );
}

export default function ProductCollectionView(
  props: Types.ProductCollectionProps & CommonPageProps & CollectionPageExtraProps
) {
  const intl = useIntl();

  const { fallbackMetaTitle, fallbackMetaDescription } = getCollectionMetaContent({
    collection: props.collection.fields,
    intl,
    webStore: props.webStore
  });

  const pageData = props.collection.fields;

  const { collection, webStore } = props;
  const { hideFilterBar } = collection.fields;

  const router = useRouter();

  const isLargeDevice = useMediaQuery(breakpointRules.laptop);

  const [frameAttributeFilters, setFrameAttributeFilters] = useState<AppliedFiltersType>(initialFrameAttributeFilters);
  const [sortOption, setSortOption] = useState<SortOptions>('default');
  const [htoFilter, setHtoFilter] = useState(false);
  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.query]);

  function parseFilterQuery() {
    const { query } = router;
    if (query.homeTryOn === 'true') {
      setHtoFilter(true);
    } else if (htoFilter) {
      setHtoFilter(false);
    }

    if (query.page && typeof query.page === 'string') {
      setPage(parseInt(query.page, 10));
    }

    if (query.sort && isSortOption(query.sort)) {
      setSortOption(query.sort as SortOptions);
    }

    const parsedFrameAttributeFilters: AppliedFiltersType = Object.keys(initialFrameAttributeFilters).reduce(
      (activeFilters, availableFilterGroup) => {
        const queryMatch = query[availableFilterGroup];
        if (queryMatch) {
          activeFilters[availableFilterGroup] = typeof queryMatch === 'string' ? [queryMatch] : queryMatch;
        } else {
          activeFilters[availableFilterGroup] = [];
        }

        return activeFilters;
      },
      {} as AppliedFiltersType
    );

    setFrameAttributeFilters(parsedFrameAttributeFilters);
  }

  useEffect(() => {
    if (!router.isReady) return;
    parseFilterQuery();
  }, [router.isReady]);

  function updateFrameAttributeFilters(updatedFilters: AppliedFiltersType) {
    const { query } = router;

    for (const key in updatedFilters) {
      query[key] = updatedFilters[key];
    }

    pcpEvents.filtering({ filteredProducts: filteredProducts.length, filters: updatedFilters });
    updateQuery(router);

    setFrameAttributeFilters(updatedFilters);
  }

  function resetFrameAttributeFilters() {
    const { query } = router;
    for (const key in initialFrameAttributeFilters) {
      delete query[key];
    }
    updateQuery(router);

    setFrameAttributeFilters(initialFrameAttributeFilters);
  }

  function handleFiltersDrawerClose() {
    setIsFiltersDrawerOpen(false);
  }

  function handleFiltersDrawerToggle() {
    setIsFiltersDrawerOpen(prevValue => !prevValue);
  }

  const activeFrameAttributeFilterCount = useMemo(() => {
    let counter = 0;
    for (const group in frameAttributeFilters) {
      counter += Array.isArray(frameAttributeFilters[group]) ? frameAttributeFilters[group].length : 1;
    }
    return counter;
  }, [frameAttributeFilters]);

  const htosOnly = useMemo(
    () => collection.products.every(product => product.currentVariant.availability.isAvailableHTO),
    [collection]
  );

  const anyAvailableHtos = useMemo(
    () => collection.products.some(product => product.currentVariant.availability.isAvailableHTO),
    [collection]
  );

  function handleHtoFilter(isActive: boolean) {
    const { query } = router;

    if (isActive) {
      query.homeTryOn = isActive.toString();
    } else {
      delete query.homeTryOn;
    }

    setHtoFilter(isActive);
    isActive && trackPageEvent('htoToggleOn');

    updateQuery(router);
  }

  function handleLoadMore() {
    setPage(page => {
      const newPage = page + 1;
      const { query } = router;
      query.page = newPage.toString();
      updateQuery(router);
      return newPage;
    });

    pcpEvents.loadMoreFrames();
  }

  // 2. Collection Sorting Function
  function handleProductSorting(option: SortOptions) {
    const { query } = router;

    query.sort = option;
    updateQuery(router);

    pcpEvents.sorting({ type: option });

    setSortOption(option);
  }

  const sortedProducts = useMemo(() => {
    if (hideFilterBar || collection.products.length < 1) return collection.products;

    return sortBy(sortOption, collection.products);
  }, [collection.products, sortOption]);

  // 3. Products Filtering Function
  // comparing the filter options on a sku to our currently active filters and hiding those
  // that do not match at least partially for every group of filters.
  const filteredProducts = useMemo(() => {
    return getFilteredProducts(sortedProducts, frameAttributeFilters);
  }, [frameAttributeFilters, sortedProducts]);

  const productCount = filteredProducts.length;
  const showHtoToggle = webStore.config.hasHTO && anyAvailableHtos && !htosOnly;
  return (
    <>
      <CustomHead
        description={pageData.metaBody || fallbackMetaDescription || pageData.body}
        title={pageData.metaTitle || fallbackMetaTitle || pageData.title || intl.formatMessage(pageTitles.collection)}
        noIndex={props.urlRoute.noIndex}
        image={transformCfAsset(pageData.metaImage?.fields.file.url, { height: 627, width: 1200 })}
        alternatePaths={props.alternatePaths}
      >
        <>
          <meta property='og:type' content='product.group' />
          <meta property='twitter:card' content='summary' />
          <meta property='cache-test' content='1' />
        </>
      </CustomHead>
      <Styles.PageGrid columns={{ mobile: 1 }} data-testid='pcp.title' data-cs-signal-enabled>
        <Cell>
          <SplitBanner
            title={collection.fields.title}
            body={renderRichTextV2(collection.fields.bodyRichText as Document)}
          />
        </Cell>
        <Cell>
          <Styles.Container>
            <FiltersDrawer
              availableFilters={isLargeDevice ? laptopFilters : mobileFilters}
              appliedFilters={frameAttributeFilters}
              onApply={updateFrameAttributeFilters}
              open={isFiltersDrawerOpen}
              onClose={handleFiltersDrawerClose}
              resetFilters={resetFrameAttributeFilters}
              products={sortedProducts}
            />
            {!hideFilterBar && (
              <>
                <FilterBar
                  productCount={productCount}
                  filterCount={activeFrameAttributeFilterCount}
                  handleFiltersDrawerToggle={handleFiltersDrawerToggle}
                  resetFilters={resetFrameAttributeFilters}
                  serviceFilters={
                    showHtoToggle ? (
                      <ServiceFilters
                        htoFilterState={htoFilter}
                        handleHtoFilter={anyAvailableHtos && !htosOnly && handleHtoFilter}
                      />
                    ) : null
                  }
                  expandedFilters={
                    <ExpandedFrameFilters
                      updateFrameAttributeFilters={updateFrameAttributeFilters}
                      frameAttributeFilters={frameAttributeFilters}
                    />
                  }
                  filterTags={
                    <FilterTags
                      updateFrameAttributeFilters={updateFrameAttributeFilters}
                      frameAttributeFilters={frameAttributeFilters}
                    />
                  }
                  sortSelector={<SortSelector option={sortOption} setOption={handleProductSorting} />}
                />
              </>
            )}
          </Styles.Container>
        </Cell>
      </Styles.PageGrid>
      <ProductGrid
        products={filteredProducts}
        productFilters={collection.fields.productFilters}
        handleLoadMore={handleLoadMore}
        page={page}
        shopstoryData={collection.shopstoryData}
      />
      <Styles.PageGrid columns={{ mobile: 1 }}>
        {filteredProducts.length === 0 && (
          <Styles.NoResultsPlaceholder>
            <Typography variant='h1' color='inherit'>
              <FormattedMessage {...messages['pcp.search.noProductsFound']} />
            </Typography>
          </Styles.NoResultsPlaceholder>
        )}
        <Cell>
          <Styles.Container>
            <SeoDescription
              seoDescription={renderRichTextV2(collection.fields.seoDescription as Document, {
                bodySize: 'bodyXS',
                optimizeForSeo: true
              })}
            />
          </Styles.Container>
        </Cell>
      </Styles.PageGrid>
    </>
  );
}
