import React from 'react';
import { Typography } from '@aceandtate/ds';
import messages from '../../../messages';
import productUspsMessages from 'components/ProductUsps/messages';
import { FormattedMessage } from 'react-intl';
import * as Styles from '../../../styles';

export default function SkiGooglesWarningsModal() {
  return (
    <>
      <Typography variant='h5' gutterBottom>
        <FormattedMessage {...messages.skiGogglesWarningsTitle} />
      </Typography>
      <Styles.ProductModalList>
        <Typography variant='bodyM' as='li' key='warning-road-use'>
          <FormattedMessage {...productUspsMessages.skiGogglesWarningDrivingRoadUse} />
        </Typography>
        <Typography variant='bodyM' as='li' key='warning-direct-sun'>
          <FormattedMessage {...productUspsMessages.skiGogglesWarningDirectSun} />
        </Typography>
        <Typography variant='bodyM' as='li' key='warning-artificial-light'>
          <FormattedMessage {...productUspsMessages.skiGogglesWarningArtificialLight} />
        </Typography>
        <Typography variant='bodyM' as='li' key='warning-impact-hazards'>
          <FormattedMessage {...productUspsMessages.skiGogglesWarningImpactHazards} />
        </Typography>
      </Styles.ProductModalList>
    </>
  );
}

SkiGooglesWarningsModal.buttonText = messages.skiGogglesWarningsLink;
