import React from 'react';
import ContentBlockLevel1Component from './ContentBlockLevel1';
import ContentBlockLevel2Component from './ContentBlockLevel2';
import ContentBlockLevel3Component from './ContentBlockLevel3';
import TextBlockComponent from './TextBlockComponent';
import FAQBlock from './FAQ/FAQBlock';
import {
  ContentBlockLevel1Fragment,
  ContentBlockLevel2Fragment,
  ContentBlockLevel3Fragment,
  FaqBlockFragment,
  TextFragment
} from 'services/generated/graphql/graphql';

/**
 * BlockRenderers keys correspond to Contentful Entry types
 * Values correspond to components that get passed the entries
 */
const BlockRenderers = {
  ContentBlockLevel1: ContentBlockLevel1Component,
  ContentBlockLevel2: ContentBlockLevel2Component,
  ContentBlockLevel3: ContentBlockLevel3Component,
  LandingPageFaqBlockV2: FAQBlock,
  ComponentText: TextBlockComponent
};

type AllowedBlocks =
  | ContentBlockLevel1Fragment[]
  | ContentBlockLevel1Fragment['blocksCollection']['items']
  | ContentBlockLevel2Fragment[]
  | ContentBlockLevel2Fragment['blocksCollection']['items']
  | ContentBlockLevel3Fragment[]
  | ContentBlockLevel3Fragment['blocksCollection']['items']
  | FaqBlockFragment[]
  | TextFragment[];

export default function BlockRenderer(props: { blocks: AllowedBlocks; animateTypographies: boolean }) {
  const { blocks = [], animateTypographies = false } = props;

  return blocks.map((block, index) => {
    const BlockElm = BlockRenderers[block.__typename];

    return (
      BlockElm && <BlockElm block={block} animateTypographies={animateTypographies} key={`${index}-${block.sys.id}`} />
    );
  });
}
