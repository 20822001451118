import React, { ReactNode, useMemo } from 'react';
import Link from 'next/link';
import HoverLink from './HoverMedia';
import {
  AssetFragment,
  ContentBlockLevel1Fragment,
  ContentBlockLevel2Fragment,
  ContentBlockLevel3Fragment,
  UrlRouteFragment
} from 'services/generated/graphql/graphql';
import styled from 'styled-components';
import { ProductMapperResult } from 'shopstory/shopstoryConfig';
import { transform } from 'utils/images';

type ActionUrlRouteBase = {
  type: 'UrlRoute';
  label: '🔗 URL Route';
  link: string; // expected value is an entry id
};

type ActionUrlLinkBase = {
  type: 'QuickLink';
  label: '🔗 QuickLink';
  link: string;
};

type MakeOptional<T, K extends keyof T> = Partial<Pick<T, K>> & Omit<T, K>;

type SharedProps = {
  targetBlank: boolean;
  effects: Partial<EffectProps>;
};

type EffectProps = {
  hoverMedia: {
    enabled: boolean;
    id: AssetFragment['sys']['id'];
  };
};

type HelperProps = {
  shouldRenderAsOverlay: boolean;
};

export type ActionProps = MakeOptional<ActionUrlLinkBase | ActionUrlRouteBase, 'link'> &
  Partial<SharedProps> &
  Partial<HelperProps>;

export type ActionLinkProps = {
  children: ReactNode;
  action: (ActionUrlLinkBase | ActionUrlRouteBase) & Partial<SharedProps> & Partial<HelperProps>;
  references?:
    | (
        | ContentBlockLevel1Fragment
        | ContentBlockLevel2Fragment
        | ContentBlockLevel3Fragment
      )['referencesCollection']['items']
    | undefined;
  assets?:
    | (
        | ContentBlockLevel1Fragment
        | ContentBlockLevel2Fragment
        | ContentBlockLevel3Fragment
      )['assetsCollection']['items']
    | undefined;
};

const LinkOverlay = styled.a`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: none;
  z-index: 2;
`;

function modifyChildren(children: React.ReactNode, component: React.ReactNode) {
  return React.Children.map(children, (child, index) => {
    // Check if the current child is valid and has its own children
    if (React.isValidElement(child) && child.props.children) {
      // div that is layout
      if (index === 0) {
        // Add a new element to the first child that has children
        return React.cloneElement(
          child,
          child.props,
          <>
            {child.props.children}
            {component}
          </>
        );
      }
    }
    return child; // Return other children unchanged
  });
}

export function ActionLink({ children, action, references = [], assets = [] }: ActionLinkProps) {
  const { type } = action;

  const component = useMemo(() => {
    if (action.shouldRenderAsOverlay) {
      if (type === 'QuickLink') {
        return (
          <>
            {modifyChildren(
              children,
              <QuickLink action={action} assets={assets}>
                <LinkOverlay />
              </QuickLink>
            )}
          </>
        );
      }

      if (type === 'UrlRoute') {
        return (
          <>
            {modifyChildren(
              children,
              <URLRouteLink action={action} assets={assets} references={references}>
                <LinkOverlay />
              </URLRouteLink>
            )}
          </>
        );
      }
    }

    if (type === 'QuickLink') {
      return (
        <QuickLink action={action} assets={assets}>
          {children}
        </QuickLink>
      );
    }

    if (type === 'UrlRoute') {
      return (
        <URLRouteLink action={action} assets={assets} references={references}>
          {children}
        </URLRouteLink>
      );
    }

    return <>{children}</>;
  }, [children, action]);

  return component;
}

type QuickLinkProps = {
  children?: ReactNode;
  action: ActionUrlLinkBase & Partial<SharedProps>;
  assets?:
    | (
        | ContentBlockLevel1Fragment
        | ContentBlockLevel2Fragment
        | ContentBlockLevel3Fragment
      )['assetsCollection']['items']
    | undefined;
};

export function QuickLink({ children = null, action, assets = [] }: QuickLinkProps) {
  const { link, targetBlank, effects } = action;

  const asset = assets.find(asset => asset.sys.id === effects?.hoverMedia?.id && effects?.hoverMedia?.enabled);
  const mappedResult = useMemo(() => (asset ? effectMappers.Asset(asset) : null), [asset]);

  const hasHoverMedia = effects?.hoverMedia?.enabled;
  if (hasHoverMedia && mappedResult?.hoverMedia) {
    return (
      <HoverLink hoverMedia={mappedResult.hoverMedia}>
        <div>
          <Link href={link} target={targetBlank ? '_blank' : undefined} passHref legacyBehavior>
            {children}
          </Link>
        </div>
      </HoverLink>
    );
  }

  return (
    <Link href={link} target={targetBlank ? '_blank' : undefined} passHref legacyBehavior>
      {children}
    </Link>
  );
}

type URLRouteLinkProps = {
  children?: ReactNode;
  action: ActionUrlRouteBase & Partial<SharedProps>;
  references?:
    | (
        | ContentBlockLevel1Fragment
        | ContentBlockLevel2Fragment
        | ContentBlockLevel3Fragment
      )['referencesCollection']['items']
    | undefined;
  assets?:
    | (
        | ContentBlockLevel1Fragment
        | ContentBlockLevel2Fragment
        | ContentBlockLevel3Fragment
      )['assetsCollection']['items']
    | undefined;
};

export function URLRouteLink({ children = null, action, references = [], assets = [] }: URLRouteLinkProps) {
  const { link, targetBlank, effects } = action;

  const reference = references.find(item => item.sys.id === link) as UrlRouteFragment;
  const href = reference.path || '#';

  const asset = assets.find(asset => asset.sys.id === effects?.hoverMedia?.id && effects?.hoverMedia?.enabled);
  const mappedResult = useMemo(() => (asset ? effectMappers.Asset(asset) : null), [asset]);
  const hasHoverMedia = effects?.hoverMedia?.enabled;

  if (hasHoverMedia && mappedResult?.hoverMedia) {
    return (
      <HoverLink hoverMedia={mappedResult.hoverMedia}>
        <Link href={href} target={targetBlank ? '_blank' : undefined} passHref legacyBehavior>
          {children}
        </Link>
      </HoverLink>
    );
  }

  return (
    <Link href={href} target={targetBlank ? '_blank' : undefined} passHref legacyBehavior>
      {children}
    </Link>
  );
}

// NOTE: Not implemented copied from shopstory/components/Links/index.tsx
// export function ProductLink({ Component, componentProps, values }: any) {
//   const { product, targetBlank, hasHoverMedia } = values;
//   const url = product?.currentVariant.sku ? `/${product.currentVariant.sku}` : '#';
//   const mappedResult = useMemo(() => (product ? effectMappers.Product(product) : null), [values.product]);

//   if (hasHoverMedia && mappedResult?.hoverMedia) {
//     return (
//       <HoverLink hoverMedia={mappedResult.hoverMedia}>
//         <Link href={url} target={targetBlank ? '_blank' : undefined}>
//           <Component {...componentProps} as='span' />
//         </Link>
//       </HoverLink>
//     );
//   }

//   return (
//     <Link href={url} target={targetBlank ? '_blank' : undefined}>
//       <Component {...componentProps} as='span' />
//     </Link>
//   );
// }

const effectMappers = {
  Asset: (asset: AssetFragment) => {
    const contentType = asset.contentType.split('/')[0];

    const hoverMedia = asset.url && {
      blurData: transform(asset.url, { fit: 'scale-down', quality: 30, width: 128 }),
      contentType,
      src: asset.url,
      alt: asset.description || `Ace & Tate | ${contentType}`
    };

    return { hoverMedia };
  },
  Product: ({ currentVariant: product }: ProductMapperResult) => {
    const src = product.images.dynamic?.url || product.images.front?.url || product.images.flat?.url;
    const hoverMedia = src && {
      blurData: transform(src, { fit: 'scale-down', quality: 30, width: 128 }),
      contentType: 'image',
      src,
      alt: `Ace & Tate | ${product.name}`
    };

    return { hoverMedia };
  }
} as const;
