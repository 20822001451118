import React, { RefObject } from 'react';
import * as Styles from '../styles';
import { StoreWithStockAvailabilityProps } from 'services/storeService';
import { Typography, RadioGroup } from '@aceandtate/ds';
import StockIndicator from './StockIndicator';

type Props = {
  store: StoreWithStockAvailabilityProps;
  chosenStoreRef?: RefObject<HTMLDivElement>;
};

export default function StoreListItem({ store, chosenStoreRef }: Props) {
  return (
    <Styles.StoreItemContainer htmlFor={store.universal_store_id}>
      <div ref={chosenStoreRef}>
        <Typography variant='h6'>{store.city}</Typography>
        <Typography>{store.display_address_line1}</Typography>
        <Typography as='span'>
          <StockIndicator isAvailable={store.stockCount > 0} storeCode={store.universal_store_id} />
        </Typography>
      </div>
      <RadioGroup.Item id={store.universal_store_id} value={store.universal_store_id} />
    </Styles.StoreItemContainer>
  );
}
