import styled from 'styled-components';
import { Typography, breakpointRules, grid, brandColors } from '@aceandtate/ds';

export const BannerContainer = styled.div`
  margin: 0;
  padding: ${grid[8]} 0 ${grid[16]};
  text-align: left;
  border-bottom: 1px solid ${brandColors.dark10};

  @media ${breakpointRules.laptop} {
    padding: ${grid[60]} 0 ${grid[40]};
  }
`;

export const Title = styled(Typography)`
  ::first-letter {
    text-transform: uppercase;
  }
`;

export const BannerParagraph = styled.div`
  padding-top: ${grid[12]};
  width: 100%;
  max-width: 640px;
`;
