import React from 'react';
import { Typography } from '@aceandtate/ds';
import messages from '../../../messages';
import { FormattedMessage } from 'react-intl';

export default function EyeDropsInstructionsModal() {
  return (
    <>
      <Typography variant='h5' gutterBottom>
        <FormattedMessage {...messages.eyedropsInstructionTitle} />
      </Typography>
      <Typography variant='bodyM' as='span' gutterBottom>
        <FormattedMessage
          {...messages.eyedropsInstructionList}
          values={{
            li: chunks => <li>{chunks}</li>,
            ol: chunks => <ol>{chunks}</ol>
          }}
        />
      </Typography>
    </>
  );
}

EyeDropsInstructionsModal.buttonText = messages.eyedropsInstructionLink;
