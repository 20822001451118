import type { AppliedFiltersType } from 'components/FiltersDrawer/types';
import type { Product } from 'types/torii';

export const getFilteredProducts = (products: Product[], filters: AppliedFiltersType) => {
  return products.filter(product => {
    let matchesActiveFilters = true;

    for (const group in filters) {
      if (
        filters[group].length > 0 &&
        !product.currentVariant.filterOptions[group]?.some((val: string) => filters[group].includes(val))
      ) {
        matchesActiveFilters = false;
        break;
      }
      if (!matchesActiveFilters) break;
    }

    return matchesActiveFilters;
  });
};
