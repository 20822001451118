import React from 'react';
import {
  brandColors,
  Flex,
  grid,
  IconButton,
  IconChevronLeft,
  IconChevronRight,
  IconClose,
  IconZoom
} from '@aceandtate/ds';
import styled from 'styled-components';
import { useSwiper } from 'swiper/react';
import Swiper from 'components/Swiper/Swiper';

export const Footer = styled.div`
  margin-top: auto;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${grid[24]};
  border-top: 1px solid ${brandColors.dark20};
  z-index: 1;
`;

const ZoomFooter = ({ images, initialIndex, onClose = null }) => {
  const swiper = useSwiper();
  const hasCloseButton = !!onClose;
  return (
    <Footer>
      {images.length > 1 && (
        <Flex gap={grid[16]}>
          <IconButton
            variant='outlined'
            onClick={() => {
              swiper.slidePrev();
            }}
          >
            <IconChevronLeft fontSize={24} />
          </IconButton>
          <IconButton
            variant='outlined'
            onClick={() => {
              swiper.slideNext();
            }}
          >
            <IconChevronRight fontSize={24} />
          </IconButton>
          <Swiper.NavigationDots style={{ marginLeft: grid[8] }} items={images} initialIndex={initialIndex} />
        </Flex>
      )}
      <IconButton
        variant='outlined'
        onClick={() => {
          if (hasCloseButton) {
            onClose();
            return;
          }
          if (swiper.zoom.scale === 1) {
            swiper.zoom.in();
          } else {
            swiper.zoom.out();
          }
        }}
        style={{ marginLeft: 'auto' }}
      >
        {hasCloseButton ? <IconClose fontSize={24} /> : <IconZoom fontSize={24} />}
      </IconButton>
    </Footer>
  );
};

export default ZoomFooter;
