import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, uiColors } from '@aceandtate/ds';
import messages from '../messages';
import * as Types from '../types';

// This error boundary is created to catch the possibility where any price surcharges are not defined (e.g. if A&T adds a new currency to the site, without updating the costs for multifocal glasses for that currency). In that case, the order button should not be available.
class ShopErrorBoundary extends Component<Types.ShopErrorBoundaryProps, Types.ShopErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Typography variant='bodyM' color={uiColors.error} gutterBottom>
          <FormattedMessage {...messages.shopError} />
        </Typography>
      );
    }
    return this.props.children;
  }
}

export default ShopErrorBoundary;
