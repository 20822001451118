import { brandColors, Button, IconClose, Typography } from '@aceandtate/ds';
import FormattedPrice from 'components/DisplayLabels/FormattedPrice';
import { AppliedFiltersType } from 'components/FiltersDrawer/types';
import frameFilterMessages from 'messages/filters';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ServicesContext } from 'services/context';
import * as Styles from '../../styles';

type Props = {
  updateFrameAttributeFilters: (updatedFilters: AppliedFiltersType) => void;
  frameAttributeFilters: AppliedFiltersType;
};

export default function FilterTags(props: Props) {
  const { updateFrameAttributeFilters, frameAttributeFilters } = props;

  const ctx = useContext(ServicesContext);
  const { webStore } = ctx;
  const intl = useIntl();

  const priceTable = {
    basePrice: `${webStore.config.basePrice}`,
    limitedEditionPrice: `${webStore.config.limitedEditionPrice}`,
    premiumPrice: `${webStore.config.premiumPrice}`
  };

  function getKeyFromValue(value: string, object: Record<string, string>) {
    for (const [objectKey, objectValue] of Object.entries(object)) {
      if (objectValue === value) {
        value = objectKey;
      }
    }

    return value;
  }

  function removeFilter(currentFilters: AppliedFiltersType, filterGroup: string, value: string) {
    const newFilterGroupValue = currentFilters[filterGroup].filter(currentvalue => currentvalue !== value);

    return {
      ...currentFilters,
      [filterGroup]: newFilterGroupValue
    } as AppliedFiltersType;
  }

  function renderTags(frameAttributeFilters: AppliedFiltersType) {
    return Object.entries(frameAttributeFilters).map(([key, values]) => {
      const groupLabel = frameFilterMessages[key];
      return values.map(value => {
        const message = frameFilterMessages[getKeyFromValue(value, priceTable)];
        return (
          <Button
            key={`tag-${key}-${value}`}
            onClick={() => updateFrameAttributeFilters(removeFilter(frameAttributeFilters, key, value))}
            data-testid='pcp.remove-filter'
            style={{ background: brandColors.sandLight, paddingInline: '24px 14px' }}
            color='light'
          >
            <Typography fontWeight='bold' style={{ lineHeight: 'inherit' }}>
              {groupLabel ? <FormattedMessage {...groupLabel} /> : key}:
            </Typography>
            <Typography as='span' fontWeight='light' style={{ lineHeight: 'inherit', textTransform: 'capitalize' }}>
              {message
                ? intl.formatMessage(message, {
                    basePrice: <FormattedPrice price={webStore.config.basePrice} currency={webStore.config.currency} />,
                    limitedEditionPrice: (
                      <FormattedPrice price={webStore.config.limitedEditionPrice} currency={webStore.config.currency} />
                    ),
                    premiumPrice: (
                      <FormattedPrice price={webStore.config.premiumPrice} currency={webStore.config.currency} />
                    )
                  })
                : value}
            </Typography>
            <Styles.IconWrapper>
              <IconClose fontSize={18} color={brandColors.sandLight} />
            </Styles.IconWrapper>
          </Button>
        );
      });
    });
  }

  return <>{renderTags(frameAttributeFilters).map(tag => tag)}</>;
}
