import React, { useReducer, useEffect, useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Typography, Drawer, IconClose, Chip, brandColors } from '@aceandtate/ds';
import FormattedPrice from 'components/DisplayLabels/FormattedPrice';
import ecommerceMessages from 'messages/ecommerce';
import frameFilterMessages from 'messages/filters';
import { ServicesContext } from 'services/context';
import * as Styles from './styles';
import type { AppliedFiltersType, AvailableFilterType } from './types';
import { reducer } from './helpers';
import { titleIconMap } from './config';
import { trackArtificialPageView } from 'tracking/helpers';
import { ChipIcon } from './partials/ChipIcon';
import type { Product } from 'types/torii';
import { getFilteredProducts } from 'views/Stores/helpers/getFilteredProducts';
type FiltersDrawerProps = {
  appliedFilters: AppliedFiltersType;
  availableFilters: AvailableFilterType[];
  onApply: (updatedFilters: AppliedFiltersType) => void;
  resetFilters?: () => void;
  onClose: () => void;
  open: boolean;
  products: Product[];
};

export default function FiltersDrawer(props: FiltersDrawerProps) {
  const { open, onClose, onApply, appliedFilters, availableFilters, resetFilters, products } = props;

  const ctx = useContext(ServicesContext);
  const { webStore } = ctx;
  const intl = useIntl();
  const [localFilters, dispatch] = useReducer(reducer, appliedFilters);

  const hasActiveFilters = Object.values(appliedFilters).some(filters => filters.length > 0);

  useEffect(() => {
    open && trackArtificialPageView('filtersActive');
  }, [open]);

  const localFilteredProducts = useMemo(() => {
    return getFilteredProducts(products, localFilters);
  }, [localFilters, products]);

  const filteredProductsCount = localFilteredProducts.length;

  useEffect(() => {
    dispatch({
      type: 'newState',
      value: appliedFilters
    });
  }, [appliedFilters]);

  function transformValue(value: string) {
    if (value === 'basePrice') {
      value = `${webStore.config.basePrice}`;
    } else if (value === 'premiumPrice') {
      value = `${webStore.config.premiumPrice}`;
    } else if (value === 'limitedEditionPrice') {
      value = `${webStore.config.limitedEditionPrice}`;
    }

    return value;
  }

  function isSelected(group: string, value: string) {
    value = transformValue(value);
    return localFilters[group].includes(value);
  }

  function handleFilterSelect(group: string, value: string) {
    value = transformValue(value);
    dispatch({
      group,
      type: 'filterToggle',
      value
    });
  }

  function handleFiltersApply() {
    window.scrollTo(0, 0);
    onApply(localFilters as AppliedFiltersType);
    setTimeout(() => {
      onClose();
    }, 300);
  }

  function handleFiltersAbort() {
    dispatch({
      type: 'newState',
      value: appliedFilters
    });

    onClose();
  }

  return (
    <Drawer
      open={open}
      position='left'
      actionBarChildren={
        <Styles.ActionBarContainer data-cs-capture>
          <Styles.IconWrapper topRight='16' onClick={handleFiltersAbort} data-testid='pcp.close-filters-drawer'>
            <IconClose fontSize={30} />
          </Styles.IconWrapper>
          <Button
            variant='outlined'
            disabled={!hasActiveFilters}
            onClick={resetFilters}
            data-testid='pcp.clear-filters-cta'
          >
            <FormattedMessage {...frameFilterMessages.clearAll} />
          </Button>
          <Button
            color='primary'
            style={{ flexGrow: 1 }}
            onClick={handleFiltersApply}
            data-testid='filter-apply-button'
          >
            ({filteredProductsCount}) <FormattedMessage {...ecommerceMessages.apply} />
          </Button>
        </Styles.ActionBarContainer>
      }
      onClose={handleFiltersAbort}
    >
      <Styles.DrawerContainer data-cs-capture data-testid='pcp_filter-drawer'>
        <Typography variant='h3' gutterBottom={40}>
          <FormattedMessage {...frameFilterMessages.filters} />
        </Typography>
        <Styles.FilterBlocksWrapper>
          {availableFilters.map(group => {
            const groupLabel = frameFilterMessages[group.groupKey];
            const icon = titleIconMap[group.groupKey];
            return (
              <Styles.FilterBlockContainer key={groupLabel.id}>
                <Typography variant='h5' style={{ textTransform: 'capitalize' }}>
                  <FormattedMessage {...groupLabel} />
                  {icon && <Styles.CategoryIcon src={icon} />}
                </Typography>
                <Styles.FilterGroup>
                  {group.options.map(option => {
                    const selected = isSelected(group.groupKey, option);
                    const message = frameFilterMessages[option];

                    // not showing extra limited edition price chip in countries where both prices are equal
                    if (
                      option === 'limitedEditionPrice' &&
                      webStore.config.premiumPrice === webStore.config.limitedEditionPrice
                    ) {
                      return null;
                    }

                    return (
                      <Chip
                        active={selected}
                        key={option}
                        data-testid={`pcp-filter_${option}`}
                        onClick={() => handleFilterSelect(group.groupKey, option)}
                        style={{ paddingInline: '16px' }}
                      >
                        <>
                          <ChipIcon value={option} />
                          <Typography variant='h6' color={brandColors.dark75} style={{ textTransform: 'capitalize' }}>
                            {message
                              ? intl.formatMessage(message, {
                                  basePrice: (
                                    <FormattedPrice
                                      price={webStore.config.basePrice}
                                      currency={webStore.config.currency}
                                    />
                                  ),
                                  limitedEditionPrice: (
                                    <FormattedPrice
                                      price={webStore.config.limitedEditionPrice}
                                      currency={webStore.config.currency}
                                    />
                                  ),
                                  premiumPrice: (
                                    <FormattedPrice
                                      price={webStore.config.premiumPrice}
                                      currency={webStore.config.currency}
                                    />
                                  )
                                })
                              : option}
                          </Typography>
                        </>
                      </Chip>
                    );
                  })}
                </Styles.FilterGroup>
              </Styles.FilterBlockContainer>
            );
          })}
        </Styles.FilterBlocksWrapper>
      </Styles.DrawerContainer>
    </Drawer>
  );
}
