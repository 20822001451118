import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Typography, grid } from '@aceandtate/ds';
import { WishlistAction, WishlistButton } from 'features/Wishlist';
import ColorPicker from 'components/ColorPicker';
import FrameConfigurator from 'components/Configurators/FrameConfigurator';
import ProductPrice from 'components/DisplayLabels/ProductPrice';
import StoreStockAvailability from 'components/StoreStockAvailability';
import type { ProductVariant, CliponProduct, Product, ContactLensProduct } from 'types/torii';
import type { ColorProps } from 'types/colorSwatches';
import { isSunny, getProductColors } from 'utils/product/productHelpers';
import * as Types from '../types';
import * as Styles from '../styles';
import bundleDealMessages from 'messages/bundleDeal';
import giftcardMessages from 'messages/giftcard';
import productConfiguratorMessages from 'messages/productConfigurator';
import stockMessages from 'messages/stock';
import ShopErrorBoundary from './ShopErrorBoundary';
import irisConfig from 'iris.config';
import NosepadsToggle from './ProductOptions/NosepadToggle';
import NotifyMe from 'components/OnlineProductStock/partials/NotifyMe';
import { isArchiveSale, isSkiGoggles } from 'utils/product/productTaxonomies';
import { BUNDLE_DEAL_PERCENTAGE } from 'globalConstants';
import { useConfiguratorStore } from 'components/Configurators/FrameConfigurator/configuratorStore';
import ProductSizeToggle from './ProductOptions/ProductSizeToggle';
import messages, { cliponCompatibilityMessages } from '../messages';
import VariablePricePicker from './VariablePricePicker';
import ClipOnOptions, { ClipOnLensType } from './ProductOptions/ClipOnOptions';
import GiftcardConfigurator from 'components/Configurators/GiftcardConfigurator/GiftcardConfigurator';
import { paths } from 'paths';
import Link from 'next/link';
import CliponDisclaimer from './CliponDisclaimer';
import ProductInformationModal from './ProductInformationModals/ProductInformationModal';
import ProductHighlights from './ProductHighlights';
import useFrameLimitModal from 'utils/hooks/useFrameLimitModal';
import { useCartState } from 'services/cartService';
import { AddToCartParams } from '../types';
import posthog from 'posthog-js';

type Props = Types.ProductInfoProps & {
  children?: React.ReactNode;
  currentVariant: ProductVariant;
  isLoading: boolean;
  onChangeVariant: (color: ColorProps) => void;
  product: Exclude<Product, ContactLensProduct>;
  recommendedClipons?: CliponProduct[];
  ctaRef?: React.MutableRefObject<HTMLInputElement>;
  colorPickerRef?: React.MutableRefObject<any>;
};

export default function ProductInfo(props: Props) {
  const { children, product, currentVariant, onChangeVariant, addToCart, webStore, ctaRef, colorPickerRef } = props;

  const [isGiftcardDrawerOpen, setIsGiftcardDrawerOpen] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [isAddingNonConfigurableProduct, setIsAddingNonConfigurableProduct] = useState(false);
  const [showCliponModal, setShowCliponModal] = useState(false);
  const { FrameLimitModal, checkFrameLimit } = useFrameLimitModal();
  const cartQuery = useCartState();
  const { cart } = cartQuery.data;
  const frameConfiguratorState = useConfiguratorStore();

  function openFrameConfigurator() {
    const isFrameLimited = checkFrameLimit(cart, { product: { ...product, currentVariant } });
    if (isFrameLimited) return;

    frameConfiguratorState.methods.setOpen(true);

    posthog.capture('open_frame_configurator', {
      sku: currentVariant.sku
    });
  }

  async function addNonConfigurableProduct(options: AddToCartParams) {
    const isFrameLimited = checkFrameLimit(cart, { product: { ...product, currentVariant } });
    if (isFrameLimited) return;

    setIsAddingNonConfigurableProduct(true);
    await addToCart(options);
  }

  const productIsNotAvailable =
    !currentVariant.availability.isAvailableOnline || currentVariant.availability.isTradeInStoreOnly;

  const giftcardConfig = webStore.config.giftcards;

  const isFrame = product.productType === 'frame';
  const isClipon = product.productType === 'clip_on';
  const isServiceItem = product.productType === 'service_item';
  const isVariableGiftcard = product.currentVariant.sku === 'giftcard-variable-price';
  const isRecycledHardCase = currentVariant.sku.startsWith('hardcase-rpmma-');
  const canConfigureFrame = !currentVariant.availability.isOffTheShelf;

  const hasNosepadVariants =
    isFrame && product.variants.some(variant => variant.sku.includes('-nose-pad')) && !isSunny(currentVariant);
  const isNosepadVariant = currentVariant?.sku.includes('-nose-pad');

  const hasPolarisedVariantsClipon = isClipon && product.variants.map(v => v.filterOptions.polarisedLens).some(x => x);
  const isPolarisedClipon = isClipon && currentVariant.filterOptions.polarisedLens;

  const allColors = getProductColors(product);
  const colors = getProductColors(product).filter(color => {
    if (isFrame) {
      return isNosepadVariant ? color.sku.includes('-nose-pad') : !color.sku.includes('-nose-pad');
    }
    if (isClipon) {
      return isPolarisedClipon ? color.href.includes('polarised') : !color.href.includes('polarised');
    }
    return color;
  });

  /* Finds the symmetric color but with the inverse nosepad (if it's a nosepad variant, looks for a non-nosepad variant and viceversa) */
  const symmetricNosepadColor = allColors.find(c => {
    const hasSameColor = currentVariant.displayAttributes.color.includes(c.name);
    const hasNosepad = c.sku.includes('-nose-pad');
    return hasSameColor && (isNosepadVariant ? !hasNosepad : hasNosepad);
  });

  const currentColor = colors.find(color => color.id === currentVariant.id);

  function setSelectedCliponLensTypeVariant(clipOnLensType: ClipOnLensType) {
    const firstColor = getProductColors(product).find(color =>
      clipOnLensType === 'polarised' ? color.href.includes('polarised') : !color.href.includes('polarised')
    );

    onChangeVariant(firstColor);
  }

  function setSelectedNosepadVariant(hasNosepads: boolean) {
    const firstColor = getProductColors(product).find(color =>
      hasNosepads ? color.sku.includes('-nose-pad') : !color.sku.includes('-nose-pad')
    );

    onChangeVariant(firstColor);
  }

  const intl = useIntl();

  const productName = () => {
    if (isServiceItem) {
      return currentVariant.displayAttributes.color;
    } else if (isVariableGiftcard) {
      return intl.formatMessage(giftcardMessages.productTitle);
    }

    return product.name;
  };

  const getBundleDealTag = (currentVariant: ProductVariant) => {
    if (!currentVariant.availability.isBundleDealEligible) return;

    return (
      <Styles.InfoTag>
        <Typography variant='bodyS' fontWeight='regular'>
          <FormattedMessage {...bundleDealMessages.callout} values={{ percentage: `${BUNDLE_DEAL_PERCENTAGE}%` }} />
        </Typography>
      </Styles.InfoTag>
    );
  };

  return (
    <Styles.ProductInfoWrapper data-testid='pdp.container'>
      <FrameLimitModal />
      {/* General Info on name and price */}
      <Styles.FlexWrapper flexDirection='column' gap={grid[4]}>
        <Typography serif variant='h2' as='h1'>
          {productName()}
        </Typography>
        {!isVariableGiftcard && (
          <Styles.PriceDisplayContainer data-testid='product-price'>
            {isFrame ? (
              <ProductPrice
                fromPrice={currentVariant.price.display}
                salePrice={currentVariant.salePrice?.display}
                includingPrescription={!(isSunny(currentVariant) || isSkiGoggles(currentVariant))}
                isAccent={isArchiveSale(currentVariant)}
              />
            ) : (
              <ProductPrice
                fromPrice={currentVariant.price.display}
                salePrice={currentVariant.salePrice?.display}
                priceOnly
              />
            )}
          </Styles.PriceDisplayContainer>
        )}
        {getBundleDealTag(currentVariant)}
        {currentVariant.sku === 'giftcard-variable-price' && (
          <Typography variant='bodyM'>
            <FormattedMessage
              {...giftcardMessages.alreadyHaveGiftcard}
              values={{
                checkBalanceChunk: (
                  <Link href={paths.checkYourBalance} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                    <FormattedMessage {...giftcardMessages.alreadyHaveGiftcardCheckBalanceChunk} />
                  </Link>
                )
              }}
            />
          </Typography>
        )}
        {product.productType === 'clip_on' && product.incompatibleFrameVariants?.length > 0 && (
          <Typography variant='bodyM'>
            <FormattedMessage
              {...cliponCompatibilityMessages.prompt}
              values={{
                link: (
                  <Button variant='link' onClick={() => setShowCliponModal(true)}>
                    <Typography variant='bodyM' as='span' fontWeight='bold'>
                      <FormattedMessage {...cliponCompatibilityMessages.promptLink} />
                    </Typography>
                  </Button>
                )
              }}
            />
            {showCliponModal && (
              <CliponDisclaimer onClose={() => setShowCliponModal(false)} variant={product.currentVariant} />
            )}
          </Typography>
        )}
      </Styles.FlexWrapper>
      {/* End General Info */}
      {/* Product Options */}
      <Styles.FlexWrapper flexDirection='column' gap={grid[12]}>
        {hasNosepadVariants && (
          <div>
            <NosepadsToggle
              product={product}
              isDisabled={!symmetricNosepadColor}
              isNosepadVariant={isNosepadVariant}
              setSelectedNosepadVariant={setSelectedNosepadVariant}
            />
          </div>
        )}
        {hasPolarisedVariantsClipon && (
          <div>
            <ClipOnOptions
              setLensType={setSelectedCliponLensTypeVariant}
              lensType={isPolarisedClipon ? 'polarised' : 'normal'}
            />
          </div>
        )}
        {isRecycledHardCase && (
          <div>
            <ProductSizeToggle product={product} tooltip={messages.hardCaseSizeTooltip} />
          </div>
        )}
        {colors.length > 0 && (
          <div>
            <ColorPicker
              colors={colors}
              onColorClick={onChangeVariant}
              currentColor={currentColor}
              colorPickerRef={colorPickerRef}
            />
          </div>
        )}
        {isVariableGiftcard && (
          <div>
            <VariablePricePicker
              prices={{
                currency: giftcardConfig.currency,
                limits: { max: giftcardConfig.max, min: giftcardConfig.min },
                presets: giftcardConfig.presets
              }}
              selectedPrice={selectedPrice}
              setSelectedPrice={setSelectedPrice}
            />
          </div>
        )}
      </Styles.FlexWrapper>
      {/* End Product Options */}
      {/* Product ATC/Stock/Config */}
      <ShopErrorBoundary>
        <Styles.FlexWrapper flexDirection='column' gap={grid[12]}>
          <Styles.ButtonsRow style={{ flexWrap: isVariableGiftcard ? 'wrap' : undefined }} ref={ctaRef}>
            {productIsNotAvailable ? (
              <NotifyMe currentVariant={currentVariant}>
                <FormattedMessage {...stockMessages.colorNotInStockButton} />
              </NotifyMe>
            ) : (
              <>
                {isVariableGiftcard && (
                  <>
                    <GiftcardConfigurator
                      onSubmit={data => addToCart({ prescriptionType: null, ...data })}
                      selectedPrice={selectedPrice}
                      drawerProps={{ onClose: () => setIsGiftcardDrawerOpen(false), open: isGiftcardDrawerOpen }}
                    />
                    <Button
                      disabled={
                        !selectedPrice || selectedPrice > giftcardConfig.max || selectedPrice < giftcardConfig.min
                      }
                      color='accent'
                      fullWidth
                      onClick={() => setIsGiftcardDrawerOpen(true)}
                      data-testid='configure-giftcard-button'
                    >
                      <FormattedMessage {...productConfiguratorMessages.personaliseProduct} />
                    </Button>
                    <Button
                      data-testid='add-to-cart-button'
                      color={'dark'}
                      style={{ flexGrow: 1 }}
                      variant={'outlined'}
                      onClick={() =>
                        addNonConfigurableProduct({ prescriptionType: null, user_entered_price: selectedPrice })
                      }
                      disabled={
                        !selectedPrice || selectedPrice > giftcardConfig.max || selectedPrice < giftcardConfig.min
                      }
                      loading={isAddingNonConfigurableProduct}
                    >
                      <FormattedMessage {...productConfiguratorMessages.addToCartButton} />
                    </Button>
                  </>
                )}
                {isFrame && (
                  <>
                    {!canConfigureFrame && (
                      <Button
                        color='accent'
                        fullWidth
                        data-testid='add-to-cart-button'
                        onClick={() => {
                          addNonConfigurableProduct({ prescriptionType: 'plano' });
                        }}
                        disabled={productIsNotAvailable}
                        loading={isAddingNonConfigurableProduct}
                      >
                        <FormattedMessage {...productConfiguratorMessages.addToCartButton} />
                      </Button>
                    )}
                    {canConfigureFrame && (
                      <>
                        <FrameConfigurator variant={currentVariant} />
                        <Button
                          color='accent'
                          fullWidth
                          data-testid='configure-glasses-button'
                          onClick={openFrameConfigurator}
                          disabled={productIsNotAvailable}
                          loading={frameConfiguratorState.data.isOpen}
                        >
                          <FormattedMessage {...productConfiguratorMessages.selectLenses} />
                        </Button>
                      </>
                    )}
                  </>
                )}
                {!isFrame && !isVariableGiftcard && (
                  <Button
                    data-testid='add-to-cart-button'
                    color='accent'
                    fullWidth
                    onClick={() => {
                      addNonConfigurableProduct({ prescriptionType: null });
                    }}
                    disabled={
                      productIsNotAvailable ||
                      (isVariableGiftcard &&
                        (!selectedPrice || selectedPrice > giftcardConfig.max || selectedPrice < giftcardConfig.min))
                    }
                    loading={isAddingNonConfigurableProduct}
                  >
                    <FormattedMessage {...productConfiguratorMessages.addToCartButton} />
                  </Button>
                )}
              </>
            )}
            <WishlistAction item={currentVariant} view='pdp' productType={product.productType}>
              {actionProps => <WishlistButton {...actionProps} />}
            </WishlistAction>
          </Styles.ButtonsRow>
          {children}
        </Styles.FlexWrapper>
      </ShopErrorBoundary>
      {/* End Product ATC/Stock/Config */}
      <Styles.FlexWrapper flexDirection='column' gap={grid[8]}>
        {/* Product Features Highlights */}
        <ProductHighlights product={product} currentVariant={currentVariant} webStore={webStore} />
        {/* End Product Features Highlights */}
        {/* Supporting Product Info */}
        <Styles.FlexWrapper flexDirection='column' alignItems='flex-start'>
          {isSkiGoggles(currentVariant) && <ProductInformationModal type='skiGogglesWarnings' />}
          {currentVariant.sku === 'cleaning-kit-puik' && <ProductInformationModal type='cleaningKitIngredients' />}
          {currentVariant.sku === 'everyday-eye-drops' && (
            <>
              <ProductInformationModal type='eyeDropsIngredients' />
              <ProductInformationModal type='eyeDropsInstructions' />
            </>
          )}
          {isRecycledHardCase && (
            <>
              <ProductInformationModal type='recycledHardCaseEOL' />
            </>
          )}
          {currentVariant.sku.includes('origami-case') && <ProductInformationModal type='origamiCaseDescription' />}
        </Styles.FlexWrapper>
        {/* End Supporting Product Info */}
      </Styles.FlexWrapper>
      {/* Supporting Feat/Actions Info */}
      <Styles.FlexWrapper flexDirection='column' gap={grid[4]}>
        {irisConfig.storeStockAvailabilityEnabled && webStore.config.hasRetailStores && (isFrame || isClipon) && (
          <StoreStockAvailability variant={currentVariant} productType={product.productType} />
        )}
      </Styles.FlexWrapper>
      {/* End Feat/Actions Info */}
    </Styles.ProductInfoWrapper>
  );
}
