import { Collection } from 'types/generated/contentful';
import { FormatNumberOptions, IntlShape } from 'react-intl';
import { WebStore } from 'types/webStore';
import { pcpMetaMessages } from 'messages/metaContent';
import productTypeMessages from 'messages/productType';
import { ProductType } from 'types/torii';

export const getCollectionMetaDescription = ({
  collection,
  intl,
  webStore
}: {
  collection: Partial<Collection>;
  intl: IntlShape;
  webStore: WebStore;
}) => {
  const { productFilters } = collection;
  const { basePrice, currency } = webStore.config;

  const options = {
    currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'currency'
  } as FormatNumberOptions;

  if (collection.collection) {
    return intl.formatMessage(pcpMetaMessages.pcpSkuCollectionMetaDescriptionMessage, {
      title: collection.title
    });
  }

  if (productFilters?.type?.includes('sunglasses')) {
    return intl.formatMessage(pcpMetaMessages.pcpFilterCollectionFrameMetaDescriptionMessage, {
      collectionProductType: intl.formatList(
        productFilters.type.map((type: ProductType) => productTypeMessages[type]?.defaultMessage).filter(Boolean),
        { style: 'short', type: 'conjunction' }
      ),
      price: intl.formatNumber(basePrice, options).replace(/\xA0/g, ' '),
      title: collection.title
    });
  }

  if (productFilters?.type?.includes('glasses')) {
    return intl.formatMessage(pcpMetaMessages.pcpFilterCollectionGlassesMetaDescriptionMessage, {
      collectionProductType: productFilters.type
        .map((type: ProductType) => productTypeMessages[type]?.defaultMessage)
        .filter(Boolean)
        .join(', '),
      price: intl.formatNumber(basePrice, options).replace(/\xA0/g, ' '),
      title: collection.title
    });
  }

  try {
    return intl.formatMessage(pcpMetaMessages.pcpSkuCollectionMetaDescriptionMessage, {
      title: collection.title
    });
  } catch {
    return '. Shop our collection.';
  }
};

export const getCollectionMetaTitle = ({ collection, intl }: { collection: Partial<Collection>; intl: IntlShape }) => {
  try {
    return intl.formatMessage(pcpMetaMessages.pcpMetaTitleMessage, {
      title: collection.title || 'Ace & Tate'
    });
  } catch {
    return 'Ace & Tate – discover our collection';
  }
};

export default function getCollectionMetaContent(params: {
  collection: Partial<Collection>;
  intl: IntlShape;
  webStore: WebStore;
}) {
  const description = getCollectionMetaDescription(params);
  const title = getCollectionMetaTitle(params);

  return { fallbackMetaDescription: description, fallbackMetaTitle: title };
}
