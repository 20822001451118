import styled, { css } from 'styled-components';
import {
  breakpointRules,
  Grid,
  grid,
  Typography,
  Button,
  Cell,
  brandColors,
  Chip,
  InputControl,
  Collapsible,
  borderRadius,
  Flex
} from '@aceandtate/ds';
import StickyBox from 'components/StickyBox';
import { fadeInAnimation } from 'styles/animations';

export const ESCAPE_MARGIN_INLINE = css`
  margin-inline: calc(var(--ds-grid-padding) * -1);

  @media ${breakpointRules.laptop} {
    margin-inline: calc(var(--ds-grid-padding) * -1) 0;
  }
`;

const BLOCK_PADDING_INLINE = css`
  padding-inline: var(--ds-grid-padding);
`;

export const ProductInfoSidebar = styled(Cell)`
  align-self: stretch;
`;

export const ProductDetailContainer = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  padding: 0 0 ${grid[60]};
  width: 100%;

  @media ${breakpointRules.desktop} {
    flex-flow: row;
    padding: 0 var(--grid-xl) var(--grid-xl);
  }
`;

export const MobileWrapper = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 1680px;
  gap: ${grid[24]};

  @media ${breakpointRules.laptop} {
    display: none;
  }
`;

export const MobileProductViewContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  margin: 0 calc(-1 * var(--ds-grid-padding));
`;

export const MobileImageCarouselWrapper = styled.div`
  display: block;
  height: 50vh;
  width: 100%;
  margin: 0 auto;

  @media ${breakpointRules.tablet} {
    height: 70vw;
    max-height: 70vh;
  }

  @media ${breakpointRules.desktop} {
    display: none;
  }
`;

export const MobileProductViewToogleWrapper = styled.div`
  position: absolute;
  left: 0;
  z-index: 2;
  display: flex;
  margin: ${grid[12]} ${grid[24]};
`;

export const PageGrid = styled(Grid)`
  margin-bottom: ${grid[80]};
  --ds-grid-gutter: 0px;
`;

export const DesktopWrapper = styled.div`
  display: none;
  @media ${breakpointRules.laptop} {
    display: flex;
    flex-flow: column;
    height: 100%;
    margin: 0;
    width: 100%;
    gap: ${grid[32]};
  }
`;

export const DesktopProductViewContainer = styled.div`
  position: relative;
  margin-left: calc(${grid.padding} * -1);
`;

export const StyledStickyBox = styled(StickyBox)`
  display: none;

  @media ${breakpointRules.laptop} {
    display: block;
    height: fit-content;
    z-index: 2;
  }
`;

export const ProductInfoWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  position: relative;
  width: 100%;
  gap: ${grid[16]};

  @media ${breakpointRules.laptop} {
    margin-top: ${grid[60]};
    padding-left: ${grid.padding};
    gap: ${grid[24]};
  }
`;

export const FlexWrapper = styled(Flex)`
  :empty {
    display: none;
  }
`;

export const PositionDraftModeTag = styled.div`
  position: absolute;
  right: ${grid[8]};
  top: ${grid[8]};
`;

export const ProductDescription = styled(Typography)`
  display: block;

  ${ESCAPE_MARGIN_INLINE}
  ${BLOCK_PADDING_INLINE}
`;

export const PriceDisplayContainer = styled.div<{ withPadding?: boolean }>`
  margin-bottom: 0;

  @media ${breakpointRules.laptop} {
    padding-bottom: ${({ withPadding }) => (withPadding ? `${grid[24]}` : 0)};
  }
`;

export const PriceChipWrapper = styled.div`
  display: flex;
  gap: ${grid[12]};
  flex-wrap: wrap;
`;

export const VariablePriceChip = styled(Chip)`
  flex-grow: 1;
  padding: ${grid[8]} ${grid[12]};
  justify-content: center;
`;

export const InputControlDense = styled(InputControl)`
  margin-bottom: ${grid[8]};
`;

export const ProductOptionWrapper = styled.span`
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  gap: ${grid[12]};
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-flow: row;
  gap: ${grid[12]};
`;

export const RecommendedProductsContainer = styled.div`
  max-width: 100%;
  width: 100%;
`;

export const RecommendedProductsCarousel = styled.div`
  margin-right: calc(${grid.padding} * -1);

  @media ${breakpointRules.laptop} {
    margin-right: 0;
  }
`;

export const RecommendedProductsGrid = styled(Grid)`
  flex-wrap: nowrap;
  overflow: auto;
  width: 100%;
  gap: ${grid.gutter};
  padding-left: ${grid.padding};
  padding-right: ${grid.padding};

  @media ${breakpointRules.laptop} {
    padding-right: 0;
  }
`;

export const CardContainer = styled(Cell)`
  position: relative;
`;

export const QuickAddToCart = styled(Button)`
  align-items: center;
  cursor: pointer;
  color: '#fff';
  display: flex;
  right: ${grid[12]};
  top: ${grid[12]};
  white-space: nowrap;

  && {
    position: absolute;
    z-index: 2;
  }
`;

export const CliponHint = styled(Typography)`
  && {
    margin-top: ${grid[24]};
  }
`;

export const ModalBodyWrapper = styled.div`
  padding: ${grid[24]};
`;

export const ProductModalList = styled.ul`
  margin: 0;
  padding-left: ${grid[24]};
`;

export const BenefitsContainer = styled.div``;

export const NosepadsTitle = styled.div`
  display: flex;
  gap: ${grid[8]};
  padding-top: ${grid[24]};
  margin-top: ${grid[12]};
  border-top: 1px solid ${brandColors.dark10};

  @media ${breakpointRules.laptop} {
    margin-top: 0;
  }
`;

export const BenefitsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${grid[12]};
`;

export const BenefitsCardContent = styled.div`
  display: flex;
  flex-flow: row;
  align-items: flex-start;
`;

export const BenefitsCardColumn = styled.div`
  display: flex;
  flex-flow: column;
  margin-left: ${grid[12]};
`;

export const IconWrapper = styled.div`
  margin-right: ${grid[12]};

  > * {
    top: -${grid[8]};
    left: ${grid[8]};
  }
`;

export const CollapsibleRoot = styled(Collapsible.Root)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${grid[24]};
`;

export const CollapsibleTrigger = styled(Collapsible.Trigger)`
  display: flex;
  gap: ${grid[8]};
  align-items: center;
  justify-content: flex-start;
`;

export const CollapsibleContent = styled(Collapsible.Content)`
  margin: ${grid[8]} 0;
`;

export const CollapsibleIconWrapper = styled.div`
  display: flex;
  flex: 1;
  padding-right: 12px;
  justify-content: flex-end;
`;

export const TagContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin-top: ${grid[12]};
  margin-left: ${grid[12]};
  z-index: 2;

  @media ${breakpointRules.laptop} {
    margin: ${grid[24]};
  }
`;

export const InfoTag = styled.div`
  width: fit-content;
  padding: ${grid[4]} ${grid[8]};

  background: ${brandColors.accent10};
  color: ${brandColors.accent75};

  border: solid 1px ${brandColors.accent30};
  border-radius: ${borderRadius.small};
`;

export const SectionHeading = styled(Typography).attrs({ variant: 'h4' })`
  margin-bottom: ${grid[12]};

  @media ${breakpointRules.laptop} {
    margin-bottom: ${grid[24]};
  }
`;

export const SectionNavigation = styled.div`
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid ${brandColors.dark10};
  * {
    padding: 0;
  }
`;
export const SectionNavigationDesktop = styled(SectionNavigation)`
  gap: ${grid[32]};
  padding-bottom: ${grid[12]};
`;

export const SectionNavigationMobile = styled(SectionNavigation)`
  gap: ${grid[16]};
  padding-bottom: ${grid[8]};
`;

export const SectionNavigationButton = styled(Typography).attrs({
  forwardedAs: 'button',
  variant: 'bodyM',
  fontWeight: 'regular'
})`
  text-align: left;
  animation: 0.2s ease-out 0.5s 1 ${fadeInAnimation};
  animation-fill-mode: both;

  @media ${breakpointRules.laptop} {
    :hover {
      color: ${brandColors.dark75};
    }
  }
`;
