import { brandColors, Typography, uiColors } from '@aceandtate/ds';
import ecommerceMessages from 'messages/ecommerce';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Styles from './styles';
import messages from './messages';
import getTimezoneForCountry from 'utils/helpers/getTimezone';
import { isBefore, isAfter, parse, format, differenceInDays } from 'date-fns';
import { Store } from 'types/solidus';
import { ServicesContext } from 'services/context';
import getWeekDayFromIndex from 'utils/helpers/getWeekDayFromIndex';
import { getHoursWithExceptions } from './utils/getHoursWithHoliday';

function dateWithTimeZone(timeZone: string, timestamp: string) {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const day = now.getDate();
  const hour = parseInt(timestamp.slice(0, 2));
  const minute = parseInt(timestamp.slice(3, 5));

  const date = new Date(Date.UTC(year, month, day, hour, minute));

  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone: timeZone }));
  const offset = utcDate.getTime() - tzDate.getTime();

  date.setTime(date.getTime() + offset);

  return date;
}

type Props = {
  store: Pick<Store, 'opening_hours' | 'holiday_hours' | 'country_code'> & Partial<Pick<Store, 'temporarily_closed'>>;
};

function parseTime(time: string, weekDay: number) {
  const now = new Date();

  return parse(`${getWeekDayFromIndex(weekDay, `en-GB`)} ${time}`, 'E HH:mm:ss', new Date(), {
    weekStartsOn: now.getDay() as any
  });
}

export default function StoreOpenChip({ store }: Props) {
  const { locale } = useContext(ServicesContext);

  const timeZone = getTimezoneForCountry(store.country_code || locale.country);
  const now = new Date();
  const hoursWithExceptions = getHoursWithExceptions(store.opening_hours, store.holiday_hours);

  const storeDay = hoursWithExceptions.find(s => now.getDay() === s.day_of_the_week);
  const isStoreClosedToday = storeDay.closed > 0 || !storeDay.opening_time || !storeDay.closing_time;
  const closingTime =
    !isStoreClosedToday && format(parseTime(storeDay.closing_time, storeDay.day_of_the_week), 'HH:mm');

  const isStoreOpen =
    !isStoreClosedToday &&
    isAfter(now, dateWithTimeZone(timeZone, storeDay.opening_time)) &&
    isBefore(now, dateWithTimeZone(timeZone, storeDay.closing_time));

  function reorderDays(index: number) {
    const [daysBeforeToday, daysAfterToday] = [
      hoursWithExceptions.slice(0, index - 1),
      hoursWithExceptions.slice(index - 1)
    ];
    return [...daysAfterToday, ...daysBeforeToday];
  }

  const reorderedOpeningHours = reorderDays(now.getDay());

  const nextOpenDay = reorderedOpeningHours.find((day, i) => {
    const isToday = i === 0;
    return day.closed === 0 && (isBefore(now, dateWithTimeZone(timeZone, day.closing_time)) || !isToday);
  });

  const isPermanentlyClosed = !nextOpenDay?.opening_time || !nextOpenDay?.day_of_the_week || !!store.temporarily_closed;

  const nextOpenTime =
    !isPermanentlyClosed && format(parseTime(nextOpenDay.opening_time, nextOpenDay.day_of_the_week), 'HH:mm');
  const daysToOpen =
    !isPermanentlyClosed && differenceInDays(parseTime(nextOpenDay.opening_time, nextOpenDay.day_of_the_week), now);
  const nextOpenDayString =
    !isPermanentlyClosed &&
    getWeekDayFromIndex(nextOpenDay.day_of_the_week, `${locale.lang}-${locale.country}`, {
      weekday: 'long'
    });
  return (
    <Styles.OpeningHoursIndicator>
      <Styles.OpeningHoursIndicatorIcon style={{ background: isStoreOpen ? uiColors.success : undefined }} />
      {isStoreOpen ? (
        <Typography variant='h6' color={brandColors.dark}>
          <FormattedMessage {...ecommerceMessages.openNow} />
        </Typography>
      ) : (
        <Typography variant='h6' color={brandColors.dark}>
          <FormattedMessage {...ecommerceMessages.closedNow} />
        </Typography>
      )}

      <Typography variant='bodyM' color={brandColors.dark}>
        {isStoreOpen && (
          <>
            – <FormattedMessage {...messages.closesAt} values={{ time: closingTime }} />
          </>
        )}

        {!isStoreOpen && !isPermanentlyClosed && (
          <>
            {daysToOpen >= 1 ? (
              <>
                –{' '}
                <FormattedMessage
                  {...messages.opensAtOn}
                  values={{
                    day: nextOpenDayString,
                    time: nextOpenTime
                  }}
                />
              </>
            ) : (
              <>
                –{' '}
                <FormattedMessage
                  {...messages.opensAt}
                  values={{
                    time: nextOpenTime
                  }}
                />
              </>
            )}
          </>
        )}
      </Typography>
    </Styles.OpeningHoursIndicator>
  );
}
