import { IconButton, IconClose, IconZoom, useBodyScrollLock } from '@aceandtate/ds';
import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/keyboard';
import ZoomFooter from './ZoomFooter';
import { Background, ZoomButton } from './styles';
import { Keyboard, Zoom } from 'swiper/modules';

const DesktopZoom = ({ images, activeIndex = 0, setActiveIndex, isInZoomView, setIsInZoomView }) => {
  useBodyScrollLock(isInZoomView);

  useEffect(() => {
    const keyHandler = event => {
      if (event.key === 'Escape') {
        setIsInZoomView(false);
      }
    };
    document.addEventListener('keydown', keyHandler);

    return () => {
      document.removeEventListener('keydown', keyHandler);
    };
  }, []);

  return (
    <>
      <ZoomButton
        onClick={() => {
          setIsInZoomView(true);
        }}
        variant='outlined'
      >
        <IconZoom fontSize={24} />
      </ZoomButton>

      {isInZoomView && (
        <Background role='dialog'>
          <div style={{ height: '100%' }}>
            <IconButton
              style={{ backgroundColor: 'white', position: 'absolute', right: 10, top: 10, zIndex: 5 }}
              variant='outlined'
              onClick={() => {
                setIsInZoomView(false);
              }}
            >
              <IconClose fontSize='24px' />
            </IconButton>
            <Swiper
              spaceBetween={24}
              centeredSlides={true}
              style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
              keyboard
              initialSlide={activeIndex}
              onSlideChange={e => {
                setActiveIndex(e.activeIndex);
              }}
              zoom
              modules={[Keyboard, Zoom]}
              loop
            >
              {images.map((img, i) => (
                <SwiperSlide data-hash={`slide${i}`} key={i} zoom>
                  <img src={img.src} style={{ position: 'absolute' }} />
                </SwiperSlide>
              ))}

              <ZoomFooter images={images} initialIndex={activeIndex} />
            </Swiper>
          </div>
        </Background>
      )}
    </>
  );
};

export default DesktopZoom;
