import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ProductCard from 'components/ProductCard';
import { Product } from 'types/torii';
import { pdpEvents } from 'tracking';
import messages from '../messages';
import * as Styles from '../styles';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Keyboard, Mousewheel, Navigation } from 'swiper/modules';
import {
  IconButton,
  IconChevronLeft,
  IconChevronRight,
  brandColors,
  breakpointRules,
  useMediaQuery
} from '@aceandtate/ds';

type Props = {
  gender: string;
  parentSku: string;
  products: Product[];
  recommendedProductsRef?: React.MutableRefObject<any> | null;
};

export default function RecommendedProducts(props: Props) {
  const { gender, products, parentSku, recommendedProductsRef } = props;
  const isTablet = useMediaQuery(breakpointRules.tablet);
  const isLaptop = useMediaQuery(breakpointRules.laptop);

  if (!products || products.length < 1) {
    return null;
  }

  const hasSufficientSlides = products.length > 2;

  return (
    <Styles.RecommendedProductsContainer ref={recommendedProductsRef} data-testid='pdp-recommended-products'>
      <Styles.SectionHeading>
        <FormattedMessage {...messages.recommendationsHeading} />
      </Styles.SectionHeading>
      <Styles.RecommendedProductsCarousel>
        <Swiper
          key={parentSku}
          direction={'horizontal'}
          mousewheel={{ invert: true, releaseOnEdges: false, forceToAxis: true }}
          keyboard={{ enabled: true }}
          modules={[Mousewheel, Keyboard, Navigation]}
          slidesPerView={isTablet ? 2.5 : 2.2}
          spaceBetween={isTablet ? 24 : 12}
        >
          {hasSufficientSlides && isLaptop && <PreviousButton />}
          {products.map((product, i) => (
            <SwiperSlide key={i}>
              <ProductCard
                key={product.currentVariant.sku}
                hasPrice={false}
                hasColorPicker
                product={product.currentVariant}
                productType={product.productType}
                variants={product.variants}
                hasWishlist
                gender={gender}
                onClick={() =>
                  pdpEvents.productSuggestion({
                    from: parentSku,
                    interactionType: 'clicked',
                    to: product.currentVariant.sku
                  })
                }
              />
            </SwiperSlide>
          ))}
          {hasSufficientSlides && isLaptop && <NextButton />}
        </Swiper>
      </Styles.RecommendedProductsCarousel>
    </Styles.RecommendedProductsContainer>
  );
}

function PreviousButton() {
  const swiper = useSwiper();
  const [isBeginning, setIsBeginning] = useState(true);

  useEffect(() => {
    swiper?.on('slideChange', swiper => {
      swiper.isBeginning ? setIsBeginning(true) : setIsBeginning(false);
    });
    return () => {
      swiper?.off('slideChange');
    };
  }, [swiper]);

  return (
    <div style={{ position: 'absolute', zIndex: 50, top: '30%', left: 0 }}>
      <IconButton
        disabled={isBeginning}
        variant='outlined'
        onClick={() => swiper.slidePrev()}
        style={{ backgroundColor: 'white', marginLeft: '10px', boxShadow: `0 0 10px ${brandColors.dark10}` }}
      >
        <IconChevronLeft fontSize='24px' />
      </IconButton>
    </div>
  );
}

function NextButton() {
  const swiper = useSwiper();
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    swiper?.on('slideChange', swiper => {
      swiper.isEnd ? setIsEnd(true) : setIsEnd(false);
    });
    return () => {
      swiper?.off('slideChange');
    };
  }, [swiper]);

  return (
    <div style={{ position: 'absolute', zIndex: 50, top: '30%', right: 0 }}>
      <IconButton
        disabled={isEnd}
        variant='outlined'
        onClick={() => swiper.slideNext()}
        style={{ backgroundColor: 'white', marginRight: '10px', boxShadow: `0 0 10px ${brandColors.dark10}` }}
      >
        <IconChevronRight fontSize='24px' />
      </IconButton>
    </div>
  );
}
