import styled from 'styled-components';
import { breakpointRules, grid } from '@aceandtate/ds';

export const ModalContent = styled.div`
  padding: ${grid[24]};

  @media ${breakpointRules.laptop} {
    padding: ${grid[24]} ${grid[40]} ${grid[40]} ${grid[40]};
  }
`;
