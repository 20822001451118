import React from 'react';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';

export const createBenefitsConfigurations = (isSportsGlasses: boolean, isSkiGoggles: boolean) => {
  if (isSportsGlasses) {
    return [
      {
        caption: <FormattedMessage {...messages.lightFitCaption} />,
        icon: (
          <Image
            src='/static/icons/light-weight.svg'
            width={40}
            height={40}
            unoptimized
            alt='Ace & Tate | Sport Glasses - Light Fit Icon'
          />
        ),
        key: 'lightFit',
        title: <FormattedMessage {...messages.lightFit} />
      },
      {
        caption: <FormattedMessage {...messages.performanceCaption} />,
        icon: (
          <Image
            src='/static/icons/performance.svg'
            width={40}
            height={40}
            unoptimized
            alt='Ace & Tate | Sport Glasses - Performance Icon'
          />
        ),
        key: 'performance',
        title: <FormattedMessage {...messages.performance} />
      },
      {
        caption: <FormattedMessage {...messages.sophisticationCaption} />,
        icon: (
          <Image
            src='/static/icons/styled.svg'
            width={40}
            height={40}
            unoptimized
            alt='Ace & Tate | Sport Glasses - Sophistication Icon'
          />
        ),
        key: 'sophistication',
        title: <FormattedMessage {...messages.sophistication} />
      }
    ];
  } else if (isSkiGoggles) {
    return [
      {
        caption: <FormattedMessage {...messages.clearOpticsCaption} />,
        icon: (
          <Image
            src='/static/icons/ski-track.svg'
            width={40}
            height={40}
            unoptimized
            alt='Ace & Tate | Ski Goggles - Clear Optics Icon'
          />
        ),
        key: 'clearOptics',
        title: <FormattedMessage {...messages.clearOptics} />
      },
      {
        caption: <FormattedMessage {...messages.interchangableLensesCaption} />,
        icon: (
          <Image
            src='/static/icons/ski-lenses.svg'
            width={40}
            height={40}
            unoptimized
            alt='Ace & Tate | Ski Goggles - Interchangable Lenses Icon'
          />
        ),
        key: 'interchangableLenses',
        title: <FormattedMessage {...messages.interchangableLenses} />
      },
      {
        caption: <FormattedMessage {...messages.peakStyleCaption} />,
        icon: (
          <Image
            src='/static/icons/ski-masks.svg'
            width={40}
            height={40}
            unoptimized
            alt='Ace & Tate | Ski Goggles - Peak Style Icon'
          />
        ),
        key: 'peakStyle',
        title: <FormattedMessage {...messages.peakStyle} />
      },
      {
        caption: <FormattedMessage {...messages.comfortableFitCaption} />,
        icon: (
          <Image
            src='/static/icons/ski-goggles.svg'
            width={40}
            height={40}
            unoptimized
            alt='Ace & Tate | Ski Goggles - Comfortable Fit Icon'
          />
        ),
        key: 'comfortableFit',
        title: <FormattedMessage {...messages.comfortableFit} />
      }
    ];
  } else {
    return [];
  }
};
