import styled from 'styled-components';
import { grid, Typography, uiColors } from '@aceandtate/ds';

export const OpeningHoursIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: ${grid[8]};
`;

export const DayOfTheWeek = styled(Typography)`
  text-transform: capitalize;
`;

export const OpeningHoursIndicatorIcon = styled.span`
  width: ${grid[12]};
  height: ${grid[12]};
  background: ${uiColors.error};
  border-radius: 50%;
  text-align: center;
`;

export const OpeningHoursWrapper = styled.div`
  display: flex;
  gap: ${grid[24]};
`;

export const OpeningHoursColumn = styled.div``;
