import { Locale } from 'types/locale';
import { graphql } from '../generated/graphql';
import client from 'utils/graphql-request';
const isDev = process.env.NEXT_PUBLIC_IS_PRODUCTION !== 'true';

// eslint-disable-next-line
const UrlRouteFragment = graphql(`
  fragment urlRoute on UrlRoute {
    sys {
      id
    }
    path
  }
`);

// eslint-disable-next-line
const AssetFragment = graphql(`
  fragment asset on Asset {
    sys {
      id
    }
    title
    description
    width
    height
    contentType
    url
    size
    fileName
  }
`);

// eslint-disable-next-line
const TextFragment = graphql(`
  fragment text on ComponentText {
    __typename
    internalName
    sys {
      id
    }
    editor
    viewport
    referencesCollection(limit: 3) {
      items {
        sys {
          id
        }
        ...urlRoute
      }
    }
  }
`);

// eslint-disable-next-line
const ImageFragment = graphql(`
  fragment image on ComponentImage {
    sys {
      id
    }
    name
    laptop {
      url
    }
    mobile {
      url
    }
  }
`);

// eslint-disable-next-line
// const VideoFragment = graphql(`
//   fragment video on ComponentVideo {
//     name
//     laptopVideo: laptop
//     mobileVideo: mobile
//   }
// `);

// eslint-disable-next-line
const FaqQuestionFragment = graphql(`
  fragment faqQuestion on FaqQuestion {
    sys {
      id
    }
    slug
    question
    body {
      json
      links {
        entries {
          inline {
            sys {
              id
            }
          }
          block {
            sys {
              id
            }
          }
          hyperlink {
            sys {
              id
            }
            ... on UrlRoute {
              path
            }
          }
        }
        assets {
          hyperlink {
            sys {
              id
            }
          }
          block {
            sys {
              id
            }
            url
          }
        }
      }
    }
  }
`);

// eslint-disable-next-line
const FaqBlockFragment = graphql(`
  fragment faqBlock on LandingPageFaqBlockV2 {
    sys {
      id
    }
    __typename
    title
    questionsCollection(limit: 10) {
      items {
        ...faqQuestion
      }
    }
  }
`);

// eslint-disable-next-line
const ContentBlockLevel3Fragment = graphql(`
  fragment contentBlockLevel3 on ContentBlockLevel3 {
    __typename
    sys {
      id
    }
    internalName
    layout
    viewport
    action
    referencesCollection(limit: 3) {
      items {
        sys {
          id
        }
        ...urlRoute
        ...image
      }
    }
    assetsCollection(limit: 3) {
      items {
        ...asset
      }
    }
    blocksCollection(limit: 5) {
      items {
        ...text
        ...image
      }
    }
  }
`);

// eslint-disable-next-line
const ContentBlockLevel2Fragment = graphql(`
  fragment contentBlockLevel2 on ContentBlockLevel2 {
    __typename
    sys {
      id
    }
    layout
    internalName
    viewport
    action
    referencesCollection(limit: 3) {
      items {
        sys {
          id
        }
        ...urlRoute
        ...image
      }
    }
    assetsCollection(limit: 3) {
      items {
        ...asset
      }
    }
    blocksCollection(limit: 5) {
      items {
        ...text
        ...image
        ...contentBlockLevel3
      }
    }
  }
`);

// eslint-disable-next-line
const ContentBlockLevel1Fragment = graphql(`
  fragment contentBlockLevel1 on ContentBlockLevel1 {
    __typename
    sys {
      id
    }
    layout
    internalName
    viewport
    action
    referencesCollection(limit: 3) {
      items {
        sys {
          id
        }
        ...urlRoute
        ...image
      }
    }
    assetsCollection(limit: 3) {
      items {
        ...asset
      }
    }
    blocksCollection(limit: 5) {
      items {
        ...text
        ...image
        ...faqBlock
        ...contentBlockLevel2
      }
    }
  }
`);

// eslint-disable-next-line
const BasePageFragment = graphql(`
  fragment basePage on BasePage {
    __typename
    sys {
      id
    }
    contentfulMetadata {
      tags {
        id
        name
      }
    }
    internalName
    metaTitle
    metaDescription
    metaImage {
      ...asset
    }
    blocksCollection(limit: 10) {
      items {
        ...contentBlockLevel1
      }
    }
  }
`);

export const EntryTagsQuery = graphql(`
  query GetEntryTags($entryId: String!, $isPreview: Boolean) {
    entryCollection(preview: $isPreview, where: { sys: { id: $entryId } }, limit: 1) {
      items {
        sys {
          id
        }
        contentfulMetadata {
          tags {
            id
            name
          }
        }
      }
    }
  }
`);

export async function getEntryTags(id: string, preview: boolean) {
  try {
    const res = await client.request(EntryTagsQuery, { entryId: id, isPreview: isDev || preview });
    return res.entryCollection.items[0].contentfulMetadata.tags;
  } catch (err) {
    console.error(err);
    return [];
  }
}

// eslint-disable-next-line
const TagsFragment = graphql(`
  fragment basePageTags on BasePage {
    sys {
      id
    }
    contentfulMetadata {
      tags {
        id
        name
      }
    }
  }
`);

export const UrlRouteBasePageInstances = graphql(`
  query UrlRouteCollectionBasePageInstances($entryId: String!, $isPreview: Boolean) {
    urlRouteCollection(preview: $isPreview, where: { sys: { id: $entryId } }) {
      items {
        __typename
        sys {
          id
        }
        reference {
          ...basePageTags
        }
        countryOverrideReferencesCollection {
          items {
            ...basePageTags
          }
        }
      }
    }
  }
`);

export async function getUrlRouteBasePageInstances(id: string, preview: boolean) {
  try {
    const res = await client.request(UrlRouteBasePageInstances, { entryId: id, isPreview: isDev || preview });
    return [
      ...res.urlRouteCollection.items[0].countryOverrideReferencesCollection.items,
      res.urlRouteCollection.items[0].reference
    ].filter(Boolean);
  } catch (err) {
    console.error(err);
    return undefined;
  }
}

// eslint-disable-next-line
export const EntryTypeQuery = graphql(`
  query UrlRouteCollection($isPreview: Boolean, $path: [String], $lang: String, $country: [String]) {
    urlRouteCollection(
      preview: $isPreview
      locale: $lang
      where: {
        AND: [
          { OR: [{ reference_exists: true }, { countryOverrideReferencesCollection_exists: true }] }
          { intlPaths_contains_some: $path }
        ]
      }
      limit: 1
    ) {
      items {
        __typename
        sys {
          id
        }
        path
        intlPaths
        noindex
        redirectType
        reference {
          __typename
          ...basePage
        }
        countryOverrideReferencesCollection(
          limit: 1
          where: { contentfulMetadata: { tags: { id_contains_some: $country } } }
        ) {
          items {
            ...basePage
          }
        }
      }
    }
  }
`);

export async function getUrlEntryByUrlRoute(path: string, locale: Locale, preview: boolean) {
  try {
    const res = await client.request(EntryTypeQuery, {
      path: path,
      lang: locale.lang,
      country: `country${locale.country.toUpperCase()}`,
      isPreview: isDev || preview
    });

    return res;
  } catch (err) {
    console.error(err);

    return null;
  }
}
