import React from 'react';
import { Typography } from '@aceandtate/ds';
import { FormattedMessage } from 'react-intl';
import messages from '../../../messages';

export default function OrigamiCaseDescriptionModal() {
  return (
    <>
      <Typography variant='h5' gutterBottom>
        <FormattedMessage {...messages.materialDescriptionTitle} />
      </Typography>
      <Typography variant='bodyM' gutterBottom>
        <FormattedMessage {...messages.origamiCaseMaterialDescription} values={{ br: <br /> }} />
      </Typography>
    </>
  );
}

OrigamiCaseDescriptionModal.buttonText = messages.materialDescriptionLink;
