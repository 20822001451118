import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Typography } from '@aceandtate/ds';

import * as Styles from '../styles';
import messages from '../messages';

type LoadMoreSpacerProps = {
  onLoadMore: () => void;
  remainingCount: number;
};

const LoadMoreSpacer = (props: LoadMoreSpacerProps) => {
  const { onLoadMore, remainingCount } = props;

  return (
    <Styles.LoadMoreContainer>
      <Styles.LoadMoreIndicator>
        <Typography variant='bodyS' color='inherit'>
          <FormattedMessage {...messages.remainingProductCount} values={{ productCount: remainingCount }} />
        </Typography>
      </Styles.LoadMoreIndicator>
      <Button onClick={onLoadMore} variant='outlined' data-testid='load-more-button'>
        <FormattedMessage {...messages['pcp.loadMoreFrames']} />
      </Button>
    </Styles.LoadMoreContainer>
  );
};

export default LoadMoreSpacer;
