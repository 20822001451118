import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ProductOptions from '.';
import messages from '../../messages';
import { Typography } from '@aceandtate/ds';

export type ClipOnLensType = 'normal' | 'polarised';

type Props = {
  lensType: ClipOnLensType;
  setLensType: (lensType: ClipOnLensType) => void;
};

export default function ClipOnOptions({ setLensType, lensType }: Props) {
  const intl = useIntl();

  return (
    <>
      <Typography variant='bodyM' gutterBottom>
        <FormattedMessage {...messages.lensType} />
      </Typography>

      <ProductOptions
        options={[
          {
            label: (
              <Typography variant='bodyM' as='span' fontWeight='regular'>
                {intl.formatMessage(messages.normal)}
              </Typography>
            ),
            value: 'normal'
          },
          {
            label: (
              <Typography variant='bodyM' as='span' fontWeight='regular'>
                {intl.formatMessage(messages.polarised)}
              </Typography>
            ),
            value: 'polarised'
          }
        ]}
        setSelectedOption={setLensType}
        selectedOption={lensType}
      />
    </>
  );
}
