import { defineMessages } from 'react-intl';

const pdpMetaTitleMessages = defineMessages({
  accessory: {
    defaultMessage: '{name} | Buy online',
    id: 'ProductDetailPage.metaTitleMessage.accessory'
  },
  clip_on: {
    defaultMessage: '{name} {color} | {productCategory}',
    id: 'ProductDetailPage.metaTitleMessage.clip_on'
  },
  frame: {
    defaultMessage: '{name} {color} | {shape} {material} {productCategory}',
    id: 'ProductDetailPage.metaTitleMessage.frame'
  },
  giftcard: {
    defaultMessage: 'Our Giftcards, available from {price}',
    id: 'ProductDetailPage.metaTitleMessage.giftcard'
  }
});

const pdpMetaDescriptionMessages = defineMessages({
  accessory: {
    defaultMessage:
      'Shop our {name} easily online or browse our other Ace & Tate accessories. 30 days free returns, no questions asked.',
    id: 'ProductDetailPage.metaDescriptionMessage.accessory'
  },
  clip_on: {
    defaultMessage: 'Shop our {name} in {color}, handmade {productCategory} from {price}.',
    id: 'ProductDetailPage.metaDescriptionMessage.clip-on'
  },
  frame: {
    defaultMessage:
      'Shop our {name} frames in {color}, handmade {shape} {material} {productCategory} from {price} including prescription lenses.',
    id: 'ProductDetailPage.metaDescriptionMessage.frame'
  },
  giftcard: {
    defaultMessage:
      "Ace & Tate Gift Card's from {price}. It’s valid forever and can be spent online and in-store. Order it easily online.",
    id: 'ProductDetailPage.metaDescriptionMessage.giftcard'
  }
});

const pdpMetaMessages = {
  description: pdpMetaDescriptionMessages,
  title: pdpMetaTitleMessages
};

export default pdpMetaMessages;

export const pcpMetaMessages = defineMessages({
  pcpFilterCollectionFrameMetaDescriptionMessage: {
    defaultMessage:
      'Amsterdam designed {title}, handmade quality {collectionProductType} from {price}. Shop our collection.',
    id: 'pcpFilterCollectionFrameMetaDescriptionMessage'
  },
  pcpFilterCollectionGlassesMetaDescriptionMessage: {
    defaultMessage:
      'Amsterdam designed {title}, handmade quality {collectionProductType} from {price} including prescription lenses. Shop our collection.',
    id: 'pcpFilterCollectionGlassesMetaDescriptionMessage'
  },
  pcpMetaTitleMessage: {
    defaultMessage: '{title} – discover our collection',
    id: 'pcpMetaTitleMessage'
  },
  pcpSkuCollectionMetaDescriptionMessage: {
    defaultMessage: '{title}. Shop our collection.',
    id: 'pcpSkuCollectionMetaDescriptionMessage'
  }
});
