import React, { ReactElement, useContext, useMemo } from 'react';
import { differenceInDays, parseISO } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import { Product } from 'types/torii';
import ecommerceMessages from 'messages/ecommerce';
import { calculateRoundedPercentageDiscount } from 'utils/helpers/cart';
import { DEAL_LABEL_COUNTRIES } from 'globalConstants';
import productMessages from 'components/ProductCard/messages';
import { isArchiveSale, isKidsGlasses } from 'utils/product/productTaxonomies';
import Tag from 'components/Tag';
import * as Styles from '../styles';
import { webColors } from 'styles';
import { ServicesContext } from 'services/context';
import { brandColors } from '@aceandtate/ds';

type TagProps = {
  currentVariant?: Product['currentVariant'];
};

export default function ProductTag(props: TagProps) {
  const { currentVariant } = props;
  const intl = useIntl();
  const { locale } = useContext(ServicesContext);

  const getLabel = useMemo((): ReactElement => {
    const daysSinceRelease = differenceInDays(new Date(), parseISO(currentVariant.availability.availableFromDate));

    if (currentVariant.salePrice) {
      const percentOff =
        calculateRoundedPercentageDiscount(currentVariant.price.value, currentVariant.salePrice.value) + '%';

      return (
        <Tag color={webColors.sale} fill>
          {DEAL_LABEL_COUNTRIES.includes(locale.country) ? (
            <span>
              <FormattedMessage {...ecommerceMessages.deal} />
              &nbsp;
              {percentOff}
            </span>
          ) : (
            <span>
              <FormattedMessage {...ecommerceMessages.sale} />
              &nbsp;
              {percentOff}
            </span>
          )}
        </Tag>
      );
    }

    // TODO: Restore temporarily disabled online only flags while we launch v2 frames
    // if (!currentVariant.visibility.includes('pos')) {
    //   return <Tag>{intl.formatMessage(ecommerceMessages.onlineOnly)}</Tag>;
    // }
    if (!currentVariant.availability.isAvailableOnline || currentVariant.availability.isTradeInStoreOnly) {
      return <Tag>{intl.formatMessage(productMessages.outOfStock)}</Tag>;
    }
    if (isArchiveSale(currentVariant)) {
      return (
        <Tag color={brandColors.accent} fill>
          {intl.formatMessage(ecommerceMessages.archive)}
        </Tag>
      );
    }
    if (isKidsGlasses(currentVariant)) {
      return <Tag>{intl.formatMessage(ecommerceMessages.kids)}</Tag>;
    } else if (currentVariant.displayAttributes.isLimitedEdition) {
      return <Tag>{intl.formatMessage(ecommerceMessages.limitedEdition)}</Tag>;
    } else if (daysSinceRelease < 30) {
      return <Tag>{intl.formatMessage(ecommerceMessages.isNew)}</Tag>;
    } else {
      return null;
    }
  }, [currentVariant]);

  return getLabel && <Styles.TagContainer>{getLabel}</Styles.TagContainer>;
}
