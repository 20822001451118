import React, { useEffect, useMemo, useRef, useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Switch, TextInput, Typography, uiColors, IconSearch, IconClose, IconBack } from '@aceandtate/ds';
import { ServicesContext } from 'services/context';
import { StoreWithStockAvailabilityProps } from 'services/storeService';
import { trackArtificialPageView, trackGaEvent } from 'tracking/helpers';
import { DRAWER_STATE } from '../index';
import { savePreferredLocalStore } from 'utils/helpers/preferredStore';
import messages from '../messages';
import * as Styles from '../styles';
import StoreListItem from './StoreListItem';

type Props = {
  onClose: () => void;
  setCurrentStore: (store: StoreWithStockAvailabilityProps) => void;
  setDrawerState: (state: DRAWER_STATE) => void;
  storesWithStockAvailability: StoreWithStockAvailabilityProps[];
  preferredStore: StoreWithStockAvailabilityProps;
  drawerIndex: number;
  setDrawerIndex: (index: number) => void;
  backButtonVisible: boolean;
  setBackButtonVisible: (visible: boolean) => void;
};

export default function StoresList(props: Props) {
  const {
    onClose,
    storesWithStockAvailability,
    setCurrentStore,
    setDrawerState,
    preferredStore,
    drawerIndex,
    setDrawerIndex,
    backButtonVisible,
    setBackButtonVisible
  } = props;
  const [storesFilterChecked, setStoresFilterChecked] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [chosenStore, setChosenStore] = useState<StoreWithStockAvailabilityProps>(null);

  const {
    locale: { country }
  } = useContext(ServicesContext);
  const intl = useIntl();
  const chosenStoreRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (preferredStore) {
      setChosenStore(preferredStore);

      drawerIndex === 0 && chosenStoreRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [preferredStore, chosenStoreRef.current, drawerIndex]);

  useEffect(() => {
    if (drawerIndex === 0) {
      trackArtificialPageView('storeStockLocator');
    }
  }, [drawerIndex]);

  const filteredStores = useMemo(
    () =>
      storesWithStockAvailability?.filter((store: StoreWithStockAvailabilityProps) => {
        if (storesFilterChecked) {
          return searchQuery
            ? store.stockCount > 0 && store.city.toLowerCase().includes(searchQuery.toLowerCase())
            : store.stockCount > 0;
        } else {
          return searchQuery
            ? store.city.toLowerCase().includes(searchQuery.toLowerCase())
            : storesWithStockAvailability;
        }
      }),
    [searchQuery, storesFilterChecked, storesWithStockAvailability]
  );

  const displayedStores = filteredStores?.length > 0 ? filteredStores : storesWithStockAvailability;

  function openStoreDetails(selectedStore: StoreWithStockAvailabilityProps) {
    const storeToOpen = storesWithStockAvailability?.find(
      (store: StoreWithStockAvailabilityProps) => store.universal_store_id === selectedStore.universal_store_id
    );
    if (storeToOpen) {
      setCurrentStore(storeToOpen);
      setDrawerState(DRAWER_STATE.STORE_DETAILS);
    }
  }

  function setPreferredStore() {
    savePreferredLocalStore(chosenStore.universal_store_id, country);

    trackGaEvent({
      event: 'storeStockSelected',
      storeName: chosenStore.street
    });
  }

  function handleFloatingButtonClick() {
    setPreferredStore();
    openStoreDetails(chosenStore);
    setDrawerIndex(1);
    setBackButtonVisible(true);
  }

  return (
    <>
      {preferredStore && backButtonVisible ? (
        <Styles.IconsWrapper>
          <Styles.StyledIconButton
            onClick={() => {
              setDrawerIndex(1);
              setBackButtonVisible(false);
            }}
          >
            <IconBack fontSize={24} />
          </Styles.StyledIconButton>
          <Styles.StyledIconButton onClick={onClose}>
            <IconClose fontSize={24} />
          </Styles.StyledIconButton>
        </Styles.IconsWrapper>
      ) : (
        <Styles.IconsWrapper style={{ justifyContent: 'flex-end' }}>
          <Styles.StyledIconButton onClick={onClose}>
            <IconClose fontSize={24} />
          </Styles.StyledIconButton>
        </Styles.IconsWrapper>
      )}

      <Styles.ContentContainer data-testid='storeStock.drawer'>
        <Styles.HeaderWrapper hasError={filteredStores?.length === 0}>
          <div>
            <Typography variant='h4' gutterBottom={8}>
              <FormattedMessage {...messages.storeAvailability} />
            </Typography>
            <Typography variant='bodyM'>
              <FormattedMessage {...messages.subHeader} />
            </Typography>
          </div>

          <TextInput
            id='search-stores'
            placeholder={intl.formatMessage(messages.searchStoresPlaceholder)}
            endAdornment={
              searchQuery.length > 0 ? (
                <IconClose fontSize={24} onClick={() => setSearchQuery('')} style={{ cursor: 'pointer' }} />
              ) : (
                <IconSearch fontSize={24} />
              )
            }
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            fullWidth
            data-testid='storeStock.searchInput'
          />
          <Styles.ToggleGroup>
            <Typography variant='bodyM'>
              <FormattedMessage {...messages.onlyShowStoresWithStock} />
            </Typography>

            <Switch
              testId='storeStock.storesFilter'
              id='store-stock-availabilty-switch'
              isChecked={storesFilterChecked}
              onChange={() => setStoresFilterChecked(!storesFilterChecked)}
            />
          </Styles.ToggleGroup>
          {filteredStores?.length === 0 && (
            <Styles.searchErrorBox>
              <Typography color={uiColors.info}>
                <FormattedMessage {...messages.searchErrorMessage} />
              </Typography>
            </Styles.searchErrorBox>
          )}
        </Styles.HeaderWrapper>
        <Styles.Body>
          <Styles.StoreListContainer
            name='stores-list'
            defaultValue={preferredStore?.universal_store_id}
            value={chosenStore?.universal_store_id}
            onValueChange={val => {
              setChosenStore(displayedStores.find(store => store.universal_store_id === val));
              setBackButtonVisible(false);
            }}
          >
            {displayedStores?.map((store: StoreWithStockAvailabilityProps) => (
              <StoreListItem
                store={store}
                key={store.universal_store_id}
                chosenStoreRef={preferredStore?.universal_store_id === store.universal_store_id ? chosenStoreRef : null}
              />
            ))}
          </Styles.StoreListContainer>
        </Styles.Body>

        {chosenStore && (
          <Styles.FloatingButtonWrapper>
            <Button fullWidth size='medium' onClick={() => handleFloatingButtonClick()}>
              <FormattedMessage {...messages.selectStoreCta} />
            </Button>
          </Styles.FloatingButtonWrapper>
        )}
      </Styles.ContentContainer>
    </>
  );
}
