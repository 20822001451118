import { defineMessages } from 'react-intl';

const tableHeaders = defineMessages({
  age: {
    defaultMessage: 'Age',
    id: 'product.specifications.table.age'
  },
  carbonImpact: {
    defaultMessage: 'Carbon impact',
    id: 'product.specifications.table.carbonImpact'
  },
  color: {
    defaultMessage: 'Colour',
    id: 'product.specifications.table.color'
  },
  includes: {
    defaultMessage: 'Includes',
    id: 'product.specifications.table.includes'
  },
  lens: {
    defaultMessage: 'Lens',
    id: 'product.specifications.table.lens'
  },
  lensWidth: {
    defaultMessage: 'Lens width',
    id: 'product.specifications.table.lensWidth'
  },
  bridgeWidth: {
    defaultMessage: 'Bridge width',
    id: 'product.specifications.table.bridgeWidth'
  },
  templeLength: {
    defaultMessage: 'Temple length',
    id: 'product.specifications.table.templeLength'
  },
  material: {
    defaultMessage: 'Material',
    id: 'product.specifications.table.material'
  },
  prescription: {
    defaultMessage: 'Prescription',
    id: 'product.specifications.table.prescription'
  },
  shape: {
    defaultMessage: 'Shape',
    id: 'product.specifications.table.shape'
  },
  size: {
    defaultMessage: 'Size',
    id: 'product.specifications.table.size'
  }
});

const tableData = defineMessages({
  frameWidth: {
    defaultMessage: '{width} frame',
    id: 'product.specifications.table.frameWidth',
    description: 'e.g. Narrow frame, Wide frame'
  },
  available: {
    defaultMessage: 'Available',
    id: 'product.specifications.table.available'
  },
  notAvailable: {
    defaultMessage: 'Not available',
    id: 'product.specifications.table.notAvailable'
  }
});

export default { ...tableHeaders, ...tableData };
