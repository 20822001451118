import { brandColors, Flex, grid, InputControl, TextInput, Typography } from '@aceandtate/ds';
import FormattedPrice from 'components/DisplayLabels/FormattedPrice';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Currency } from 'types/currency';
import messages from '../messages';
import * as Styles from '../styles';

type Props = {
  prices: {
    presets?: number[];
    currency: Currency;
    limits?: {
      min?: number;
      max?: number;
    };
  };
  /** If custom value entry is allowed (default: true) */
  hasCustomPrice?: boolean;
  selectedPrice: number;
  setSelectedPrice: (value: number) => void;
};

const matchNumbers = /^[0-9]*$/;

export default function VariablePricePicker({ selectedPrice, setSelectedPrice, prices, hasCustomPrice = true }: Props) {
  const { presets, limits, currency } = prices;
  const intl = useIntl();
  const [isCustomPrice, setIsCustomPrice] = useState(false);
  const [isDirty, setIsDirty] = useState(true);

  function formatPrice(price: number, currency: Currency) {
    return intl.formatNumber(price, {
      currency,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: 'currency'
    });
  }

  function onChangePrice(value: string) {
    setIsDirty(true);
    if (!value) {
      setSelectedPrice(null);
      return;
    }

    if (!matchNumbers.test(value)) {
      return;
    }

    const valueParsed = parseInt(value, 10);
    if (valueParsed) {
      setSelectedPrice(valueParsed);
    }
  }

  return (
    <>
      <Typography variant='bodyM' gutterBottom>
        <FormattedMessage {...messages.selectAnAmount} />
      </Typography>

      <Flex flexDirection='column' gap={grid[12]}>
        <Styles.PriceChipWrapper>
          {presets?.length > 0 &&
            presets.map(price => (
              <Styles.VariablePriceChip
                key={price}
                data-testid={`variable-price-chip-${price}`}
                onClick={() => {
                  setIsCustomPrice(false);
                  setSelectedPrice(price);
                }}
                active={!isCustomPrice && price === selectedPrice}
              >
                <Typography variant='bodyM' as='span' fontWeight='regular'>
                  {formatPrice(price, currency)}
                </Typography>
              </Styles.VariablePriceChip>
            ))}
          {hasCustomPrice && (
            <Styles.VariablePriceChip
              data-testid='custom-price-chip'
              active={isCustomPrice}
              onClick={() => setIsCustomPrice(true)}
            >
              <Typography variant='bodyM' as='span' fontWeight='regular'>
                {intl.formatMessage(messages.variablePriceCustomLabel)}
              </Typography>
            </Styles.VariablePriceChip>
          )}
        </Styles.PriceChipWrapper>
        {isCustomPrice && (
          <div>
            <Styles.InputControlDense id='custom-price-value'>
              <InputControl.Label>
                <FormattedMessage {...messages.otherAmount} />{' '}
                {limits?.max && (
                  <FormattedMessage
                    {...messages.upToAmount}
                    values={{ amount: <FormattedPrice price={limits.max} currency={currency} /> }}
                  />
                )}
              </InputControl.Label>
              <TextInput
                min={limits?.min}
                max={limits?.max}
                defaultValue={selectedPrice?.toString() || prices[0]?.toString()}
                value={selectedPrice?.toString()}
                onChange={e => onChangePrice(e.target.value)}
                onBlur={() => setIsDirty(false)}
                data-testid='variable-price-value-input'
                startAdornment={formatPrice(0, currency).replace('0', '')}
                fullWidth
              />
              {!isDirty && selectedPrice && selectedPrice < limits.min && (
                <InputControl.Validation status='error'>
                  <FormattedMessage
                    {...messages.variablePriceMinValueError}
                    values={{
                      value: formatPrice(limits.min, prices.currency)
                    }}
                  />
                </InputControl.Validation>
              )}
              {!isDirty && selectedPrice && selectedPrice > limits.max && (
                <InputControl.Validation status='error'>
                  <FormattedMessage
                    {...messages.variablePriceMaxValueError}
                    values={{
                      value: formatPrice(limits.max, prices.currency)
                    }}
                  />
                </InputControl.Validation>
              )}
            </Styles.InputControlDense>
            <Typography variant='bodyXS' color={brandColors.dark50}>
              <FormattedMessage {...messages.onlyRoundNumbersCaption} />
            </Typography>
          </div>
        )}
      </Flex>
    </>
  );
}
