import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { IconShop, Typography, Button, useMediaQuery, breakpointRules } from '@aceandtate/ds';
import { ServicesContext } from 'services/context';
import { TooltipWrapper as Tooltip } from '../index';
import { filterMessages } from '../../messages';
import styled from 'styled-components';

const tooltipHtoConfig = {
  id: 'hto-toggle',
  label: <FormattedMessage {...filterMessages.htoFilter} />,
  text: <FormattedMessage {...filterMessages.htoTooltipText} />
} as const;

type Props = {
  handleHtoFilter: (isActive: boolean) => void;
  htoFilterState: React.SetStateAction<boolean>;
};

const FiltersButton = styled(Button)`
  * {
    line-height: inherit;
  }

  @media ${breakpointRules.laptop} {
    padding-inline: 32px;
  }
`;

export default function ServiceFilters(props: Props) {
  const { handleHtoFilter, htoFilterState } = props;

  const isLaptop = useMediaQuery(breakpointRules.laptop);
  const { hasHTO } = useContext(ServicesContext).webStore.config;
  const showHtoToggle = hasHTO;

  return (
    <>
      {showHtoToggle && handleHtoFilter && (
        <Tooltip config={tooltipHtoConfig}>
          <FiltersButton
            data-testid={tooltipHtoConfig.id}
            onClick={() => handleHtoFilter(!htoFilterState)}
            variant={htoFilterState ? 'default' : 'outlined'}
            size={isLaptop ? 'medium' : 'small'}
          >
            <IconShop fontSize={18} />
            <Typography fontWeight='light' as='span'>
              {tooltipHtoConfig.label}
            </Typography>
          </FiltersButton>
        </Tooltip>
      )}
    </>
  );
}
