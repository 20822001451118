import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Link from 'next/link';
import { brandColors, Button, grid, Typography, uiColors } from '@aceandtate/ds';
import {
  MdOutlineInfo,
  MdOutlineStore,
  MdOutlineDirections,
  MdExpandLess,
  MdExpandMore,
  MdClose,
  MdArrowBack
} from 'react-icons/md';
import { IoAlertCircleOutline } from 'react-icons/io5';
import { paths } from 'paths';
import { StoreWithStockAvailabilityProps } from 'services/storeService';
import ecommerceMessages from 'messages/ecommerce';
import { scaleFromUrl } from 'utils/cloudinary';

import OptimizedCloudinaryImage from 'components/Images/OptimizedCloudinaryImage';
import StockIndicator from './StockIndicator';
import messages from '../messages';
import * as Styles from '../styles';
import { trackArtificialPageView } from 'tracking/helpers';
import { ProductType } from 'types/torii';
import StoreOpenChip from 'components/OpeningHours/StoreOpenChip';
import StoreOpeningHours from 'components/OpeningHours/StoreOpeningHours';

type Props = {
  productType: ProductType;
  currentStore: StoreWithStockAvailabilityProps;
  onClose: () => void;
  drawerIndex: number;
  setDrawerIndex: (index: number) => void;
  backButtonVisible: boolean;
  setBackButtonVisible: (visible: boolean) => void;
};

export default function StoreDetails(props: Props) {
  const { onClose, currentStore, productType, drawerIndex, setDrawerIndex, backButtonVisible, setBackButtonVisible } =
    props;

  const [openingHoursExpanded, setOpeningHoursExpanded] = useState(false);

  const viewOnMap = `https://maps.google.com/?q=${encodeURIComponent(
    `Ace & Tate ${currentStore?.display_address_line1},
    ${currentStore?.city} ${currentStore?.country_code}`
  )}`;

  useEffect(() => {
    if (drawerIndex === 1) {
      trackArtificialPageView('storeStockDetail');
    }
  }, [drawerIndex]);

  return (
    currentStore && (
      <>
        {backButtonVisible ? (
          <Styles.IconsWrapper>
            <Styles.StyledIconButton
              onClick={() => {
                setDrawerIndex(0);
                setBackButtonVisible(false);
              }}
            >
              <MdArrowBack size={24} />
            </Styles.StyledIconButton>
            <Styles.StyledIconButton onClick={onClose}>
              <MdClose size={24} />
            </Styles.StyledIconButton>
          </Styles.IconsWrapper>
        ) : (
          <Styles.IconsWrapper style={{ justifyContent: 'flex-end' }}>
            <Styles.StyledIconButton onClick={onClose}>
              <MdClose size={24} />
            </Styles.StyledIconButton>
          </Styles.IconsWrapper>
        )}
        <Styles.ContentContainer data-testid={`storeStock.storeDetails.${currentStore.universal_store_id}`}>
          <Typography variant='h4'>
            <StockIndicator
              withUnderline={false}
              isAvailable={currentStore.stockCount > 0}
              streetName={currentStore.street}
              storeCode={currentStore.universal_store_id}
              size={grid[12]}
            />
          </Typography>
          {currentStore.opening_hours?.length > 0 && (
            <Styles.OpeningHours
              open={openingHoursExpanded}
              onOpenChange={() => setOpeningHoursExpanded(!openingHoursExpanded)}
            >
              <Styles.OpeningHoursTitle>
                <StoreOpenChip store={currentStore} />
                {openingHoursExpanded ? (
                  <MdExpandLess size={24} color={brandColors.dark} />
                ) : (
                  <MdExpandMore size={24} color={brandColors.dark} />
                )}
              </Styles.OpeningHoursTitle>
              <Styles.OpeningHoursContent>
                <StoreOpeningHours store={currentStore} />
              </Styles.OpeningHoursContent>
            </Styles.OpeningHours>
          )}
          {currentStore.stockCount === 0 && (
            <Styles.Disclaimer>
              <Styles.InfoIcon>
                <IoAlertCircleOutline size={24} color={uiColors.warning} />
              </Styles.InfoIcon>
              <Typography color={uiColors.warning}>
                <FormattedMessage {...messages.noStockInfo} />
              </Typography>
            </Styles.Disclaimer>
          )}
          <Styles.Body withGap={true}>
            <Styles.StoreAddress>
              <Styles.StoreLink href={`/stores/${currentStore.slug_frontend}`} target='blank' rel='noopener noreferrer'>
                <MdOutlineStore size={24} style={{ color: brandColors.accent }} />
                <FormattedMessage {...messages.seeStoreDetails} />
              </Styles.StoreLink>
              <Styles.StoreLink href={viewOnMap} target='blank' rel='noopener noreferrer'>
                <MdOutlineDirections size={24} />
                {currentStore.display_address_line1}
              </Styles.StoreLink>
            </Styles.StoreAddress>
            <Styles.StyledButton>
              <Link
                href={`${paths.bookAppointment}/${currentStore.slug_frontend}`}
                target='blank'
                rel='noopener noreferrer'
              >
                <Button fullWidth variant='default' size='small'>
                  <FormattedMessage {...messages.bookAFreeEyeTest} />
                </Button>
              </Link>
            </Styles.StyledButton>

            <Link href={`/stores/${currentStore.slug_frontend}`} target='blank' rel='noopener noreferrer'>
              <OptimizedCloudinaryImage
                src={scaleFromUrl(currentStore.image, 'w_800,q_85/')}
                alt={currentStore.street}
                unoptimized
                width={500}
                height={200}
                style={{
                  objectFit: 'cover'
                }}
              />
            </Link>

            {currentStore.stockCount > 0 && productType === 'frame' && (
              <Styles.SDSDisclaimer>
                <Styles.InfoIcon>
                  <MdOutlineInfo size={24} color={uiColors.info} />
                </Styles.InfoIcon>
                <Typography color={uiColors.info}>
                  <FormattedMessage
                    {...messages.sameDayServiceInfo}
                    values={{
                      link: (
                        <Link href={paths.sameDayGlasses}>
                          <FormattedMessage {...ecommerceMessages.sameDayGlasses} />
                        </Link>
                      ),
                      u: chunks => <u>{chunks}</u>
                    }}
                  />
                </Typography>
              </Styles.SDSDisclaimer>
            )}

            <Styles.StyledButton>
              <Button
                variant='outlined'
                size='small'
                fullWidth
                onClick={() => {
                  setDrawerIndex(0);
                  setBackButtonVisible(true);
                }}
              >
                <FormattedMessage {...messages.checkOtherStores} />
              </Button>
            </Styles.StyledButton>
          </Styles.Body>
          {currentStore.stockCount > 0 && productType === 'frame' && (
            <Styles.Footnote>
              <Typography variant='bodyS'>
                <FormattedMessage {...messages.sameDayServiceFootnote} />
              </Typography>
            </Styles.Footnote>
          )}
        </Styles.ContentContainer>
      </>
    )
  );
}
