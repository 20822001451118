import React from 'react';
import { FormattedMessage } from 'react-intl';
import Image from 'next/image';
import { breakpointRules, Modal, Typography, useMediaQuery } from '@aceandtate/ds';
import messages from './messages';
import * as Styles from './styles';

export function MetalFrameModal({ open, onClose, width }) {
  const isLaptop = useMediaQuery(breakpointRules.laptop);

  return (
    <Modal open={open} onClose={onClose} width={width}>
      <>
        <div
          style={{
            position: 'relative',
            width: '100%',
            paddingBottom: isLaptop ? '45%' : '57%'
          }}
        >
          <Image
            src={'/static/images/clipons_demo.gif'}
            fill
            objectFit='cover'
            sizes='100vw'
            alt='Ace & Tate | Clip-on sunglasses demo'
          />
        </div>
        <Styles.ModalContent>
          <Typography variant='h4' gutterBottom>
            <FormattedMessage {...messages.cliponModalTitle} />
          </Typography>
          <Typography variant='bodyM'>
            <FormattedMessage {...messages.cliponMetalModal} values={{ br: <br /> }} />
          </Typography>
        </Styles.ModalContent>
      </>
    </Modal>
  );
}

export function AcetateFrameModal({ open, onClose, width }) {
  const isLaptop = useMediaQuery(breakpointRules.laptop);

  return (
    <Modal open={open} onClose={onClose} width={width}>
      <div
        style={{
          position: 'relative',
          width: '100%',
          paddingBottom: isLaptop ? '45%' : '57%'
        }}
      >
        <Image
          src={'/static/images/clipons_demo.gif'}
          fill
          objectFit='cover'
          sizes='100vw'
          alt='Ace & Tate | Clip-on sunglasses demo'
        />
      </div>
      <Styles.ModalContent>
        <Typography variant='h4' gutterBottom>
          <FormattedMessage {...messages.cliponModalTitle} />
        </Typography>
        <Typography variant='bodyM'>
          <FormattedMessage {...messages.cliponAcetateModal} values={{ br: <br /> }} />
        </Typography>
      </Styles.ModalContent>
    </Modal>
  );
}
