import React, { CSSProperties, useContext } from 'react';
import { TextFragment, UrlRouteFragment } from 'services/generated/graphql/graphql';
import { FontVariant, Typography, Button } from '@aceandtate/ds';
import styled, { css } from 'styled-components';
import { Asset } from 'contentful';
import Link from 'next/link';
import ErrorBoundary from 'components/ErrorBoundary';
import RenderContext from '../RenderContext';
import { DebugLabel } from '../styles';
import { ButtonProps } from '@aceandtate/ds/dist/components/Button/Button';
import Visibility from 'components/Visibility';

type Extentions = {
  variant: FontVariant;
  fontWeight?: 'light' | 'regular' | 'bold';
};

type TextBlockProps = {
  detail: number;
  format: number;
  mode: string;
  style: string;
  text: string;
  type: 'at-text';
  version: number;
} & Extentions;

type SideEffectImage = {
  component: 'image';
  id: Asset['sys']['id'];
};

export type SideEffect = {
  uuid?: string;
  trigger: 'hover';
} & Partial<SideEffectImage>;

export type SubType = 'QuickLink' | 'UrlRouteLink' | 'ProductLink';

type LinkBlockProps = {
  children: TextBlockProps[];
  direction: string;
  format: number;
  indent: number;
  rel: string;
  target: string | null;
  title: string | null;
  url: '';
  type: 'at-link';
  subType: SubType;
  linksTo: string;
  sideEffects?: null | SideEffect[];
  version: number;
};

export type Spread<T1, T2> = Omit<T2, keyof T1> & T1;

export type SerializedLexicalNode = {
  type: string;
  version: number;
};

export type Variant = ButtonProps['variant'];
export type Color = ButtonProps['color'];
export type Size = ButtonProps['size'];
export type CustomTextColor = ButtonProps['customTextColor'];
export type CustomBackgroundColor = ButtonProps['customBackgroundColor'];
export type fullWidth = boolean;

export type ButtonCustomAttributes = {
  sideEffects?: null | SideEffect[];
};

export type ButtonStyleAttributes = {
  variant?: Variant;
  color?: Color;
  customTextColor?: CustomTextColor;
  customBackgroundColor?: CustomBackgroundColor;
  size?: Size;
  fullWidth?: fullWidth;
};

export type ButtonAnchorAttributes = {
  rel?: null | string;
  target?: null | string;
  title?: null | string;
};

export type ButtonBaseAttributes = {
  subType: SubType;
  linksTo: string; // "url" | "string_id" | "product_id" | ...
  text: string;
};

export type SerializedButtonNode = Spread<
  {
    type: 'at-button';
  },
  Spread<
    Spread<ButtonBaseAttributes, Spread<ButtonAnchorAttributes, Spread<ButtonStyleAttributes, ButtonCustomAttributes>>>,
    SerializedLexicalNode
  >
>;

type ParagraphBlockProps = {
  children: TextBlockProps[] | LinkBlockProps[];
  direction: string;
  format: CSSProperties['textAlign'];
  indent: number;
  type: 'paragraph';
  version: number;
  textFormat: number;
  textStyle: string;
};

type HeadingBlockProps = {
  children: TextBlockProps[];
  direction: string;
  format: CSSProperties['textAlign'];
  indent: number;
  type: 'heading';
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  version: number;
};

type ListItemProps = {
  children: TextBlockProps[];
  direction: string;
  format: CSSProperties['textAlign'];
  indent: number;
  type: 'listitem';
  version: number;
  value: number;
};

type ListBlockProps = {
  children: ListItemProps[];
  direction: string;
  format: '';
  indent: number;
  type: 'list';
  version: number;
  listType: 'number' | 'bullet';
  start: number;
  tag: 'ol' | 'ul';
};

type RootComponent = {
  children: ParagraphBlockProps[] | HeadingBlockProps[] | ListBlockProps[];
  direction: string;
  format: string;
  indent: number;
  type: string;
  version: number;
};

type StyleProps = {
  layout?: string;
};

function renderElementLayout(layout: string) {
  return css`
    ${layout as string}
  `;
}

export const TypographyElement = styled(Typography)<StyleProps>`
  ${({ layout }) => renderElementLayout(layout)}
`;

export const StyledSpanElement = styled(Typography)<StyleProps>`
  white-space: pre-wrap;
  ${({ layout }) => renderElementLayout(layout)}
`;

export const StyledAnchor = styled.a`
  span {
    text-decoration: underline;
  }
  :hover {
    opacity: 0.75;
    span {
      text-decoration: underline;
    }
  }
`;

function isParagraphBlock(block: any | null | undefined): block is ParagraphBlockProps {
  return block && block.type === 'paragraph';
}
function isHeadingBlock(block: any | null | undefined): block is HeadingBlockProps {
  return block && block.type === 'heading';
}

/** TODO: since variant prop does not live at the element level anymore, we need to figure out how to assert line height */
/** passing the block is necessary so styles like line-height are reflected */
function LineBreak({ block }: { block: ParagraphBlockProps | HeadingBlockProps }) {
  return (
    <TypographyElement
      style={{ textAlign: block.format }}
      layout={isParagraphBlock(block) && block.textStyle}
      forwardedAs='span'
    >
      <br />
    </TypographyElement>
  );
}

function TypographyBlock({
  block,
  references
}: {
  block: ParagraphBlockProps | HeadingBlockProps;
  references: TextBlockComponentProps['referencesCollection']['items'];
}) {
  return (
    <>
      {block.children.length > 0 ? (
        <TypographyElement
          style={{ textAlign: block.format }}
          layout={isParagraphBlock(block) && block.textStyle}
          forwardedAs={isHeadingBlock(block) ? block.tag : 'p'}
        >
          {block.children.map((child: TextBlockProps | LinkBlockProps | SerializedButtonNode, index: number) => {
            if (child.type === 'at-text') {
              return (
                <StyledSpanElement
                  key={index}
                  variant={child.variant}
                  layout={child.style}
                  fontWeight={child.fontWeight}
                  forwardedAs='span'
                >
                  {child.text}
                </StyledSpanElement>
              );
            }

            if (child.type === 'at-link') {
              let href = child.linksTo || '#';
              if (child.subType === 'UrlRouteLink') {
                const reference = references.find(item => item.sys.id === child.linksTo) as UrlRouteFragment;
                href = reference?.path || '#';
              }

              return (
                <Link
                  key={index}
                  href={href}
                  target={child.target}
                  rel={child.target === '_blank' ? 'noreferrer noopener' : child.rel}
                  passHref
                  legacyBehavior
                >
                  <StyledAnchor>
                    {child.children.map((child, index) => {
                      return (
                        <StyledSpanElement
                          key={index}
                          variant={child.variant}
                          layout={child.style}
                          fontWeight={child.fontWeight}
                          forwardedAs='span'
                        >
                          {child.text}
                        </StyledSpanElement>
                      );
                    })}
                  </StyledAnchor>
                </Link>
              );
            }

            if (child.type === 'at-button') {
              let href = child.linksTo || '#';
              if (child.subType === 'UrlRouteLink') {
                const reference = references.find(item => item.sys.id === child.linksTo) as UrlRouteFragment;
                href = reference?.path || '#';
              }

              return (
                <Link
                  key={index}
                  href={href}
                  target={child.target}
                  rel={child.target === '_blank' ? 'noreferrer noopener' : child.rel}
                  passHref
                  legacyBehavior
                >
                  <Button
                    size={child.size}
                    variant={child.variant}
                    color={child.color}
                    customTextColor={child.customTextColor}
                    customBackgroundColor={child.customBackgroundColor}
                    fullWidth={child.fullWidth}
                  >
                    {child.text}
                  </Button>
                </Link>
              );
            }

            return <></>;
          })}
        </TypographyElement>
      ) : (
        // adds line break
        <LineBreak block={block} />
      )}
    </>
  );
}

function ListItem({ block }: { block: ListItemProps }) {
  return (
    <TypographyElement variant='bodyM' style={{ textAlign: block.format }} forwardedAs='li'>
      {block.children.map((child, index) => {
        return (
          <StyledSpanElement key={index} layout={child.style} as='span'>
            {child.text}
          </StyledSpanElement>
        );
      })}
    </TypographyElement>
  );
}

function ListBlock({ block }: { block: ListBlockProps }) {
  const Tag = block.tag;
  return (
    <Tag>
      {block.children.map((listItem, index) => {
        return <ListItem key={index} block={listItem} />;
      })}
    </Tag>
  );
}

const BlockRenderers = {
  paragraph: TypographyBlock,
  heading: TypographyBlock,
  list: ListBlock
};

type Editor = {
  desktop: { root: RootComponent } | undefined;
  mobile: { root: RootComponent } | undefined;
};

type TextBlockComponentProps = Omit<TextFragment, 'editor'> & { editor?: Editor };

export default function TextBlockComponent({ block }: { block: TextBlockComponentProps }) {
  const { isInspectorActive } = useContext(RenderContext);
  const textblocksDesktop = block.editor?.desktop?.root.children || [];
  const textblocksMobile = block.editor?.mobile?.root.children || textblocksDesktop;

  return (
    <ErrorBoundary>
      <Visibility
        layout='auto'
        visibleOn={['laptop', 'desktop']}
        style={{ position: 'relative', width: '100%' }}
        data-contentful-field-id='internalName'
        data-contentful-entry-id={block.sys.id}
      >
        {isInspectorActive && <DebugLabel>{block.internalName}</DebugLabel>}
        {textblocksDesktop.map((blockItem, index) => {
          const BlockElm = BlockRenderers[blockItem.type];

          return (
            BlockElm && <BlockElm block={blockItem} references={block.referencesCollection?.items || []} key={index} />
          );
        })}
      </Visibility>
      <Visibility
        layout='auto'
        visibleOn={['mobile', 'tablet']}
        style={{ position: 'relative', width: '100%' }}
        data-contentful-field-id='internalName'
        data-contentful-entry-id={block.sys.id}
      >
        {isInspectorActive && <DebugLabel>{block.internalName}</DebugLabel>}
        {textblocksMobile.map((blockItem, index) => {
          const BlockElm = BlockRenderers[blockItem.type];

          return (
            BlockElm && <BlockElm block={blockItem} references={block.referencesCollection?.items || []} key={index} />
          );
        })}
      </Visibility>
    </ErrorBoundary>
  );
}
