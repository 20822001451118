import { Button, Typography, brandColors, grid } from '@aceandtate/ds';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Link from 'next/link';
import styled from 'styled-components';
import { CliponProduct, FamilyVariant, FrameProduct, GenericProduct, Product, ProductVariant } from 'types/torii';
import messages from '../../messages';
import globalMessages from 'messages/ecommerce';
import { materialMessages, shapeMessages, widthMessages } from 'messages/pcpFilters';
import carbonFootprintMessages from 'messages/carbonFootprint';
import productUspsMessages from 'components/ProductUsps/messages';
import tableMessages from './messages';
import { getAvailableSizes, isSunny } from 'utils/product/productHelpers';
import { isKidsGlasses, isRawCollection, isSkiGoggles, isSportsGlasses } from 'utils/product/productTaxonomies';
import { getMeasurements } from 'components/ProductUsps/utils';
import * as Styles from '../../styles';
import CaseModal from 'components/ProductUsps/partials/CaseModal';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  tbody {
    tr {
      :not(:last-child) {
        border-bottom: 1px solid ${brandColors.dark10};
      }

      td,
      th {
        padding-block: ${grid[16]};
        text-align: left;
        vertical-align: top;
        width: 50%;
      }

      :first-child {
        td,
        th {
          padding-top: initial;
        }
      }
    }
  }
`;

const ShowMoreContainer = styled.div`
  display: flex;
  align-items: center;

  :before {
    content: '';
    width: 100px;
    flex-grow: 1;
    background: ${brandColors.dark10};
    height: 1px;
  }

  :after {
    content: '';
    width: 100px;
    flex-grow: 1;
    background: ${brandColors.dark10};
    height: 1px;
  }
`;

export type SpecProps = {
  currentVariant: ProductVariant;
  product: FrameProduct | GenericProduct | CliponProduct;
  specificationsRef?: React.MutableRefObject<any> | null;
};

// helper functions
function getProductMaterials(currentVariant: SpecProps['currentVariant']) {
  const { materials = [] } = currentVariant.filterOptions;

  return materials.map(material => {
    return materialMessages[material] ? <FormattedMessage {...materialMessages[material]} /> : material;
  });
}

function getProductWidth(variant: SpecProps['currentVariant']) {
  const { widths } = variant.filterOptions;
  return widthMessages[widths[0]] && <FormattedMessage {...widthMessages[widths[0]]} />;
}

function getCarbonFootprint(variant: SpecProps['currentVariant'], productType: Product['productType']) {
  if (productType !== 'frame') return null; // we currently only have data for frames

  const carbonImpact = variant.carbon_impact;
  if (carbonImpact) {
    return <FormattedMessage {...carbonFootprintMessages.carbonImpact} values={{ carbonImpact }} />;
  }
  return <FormattedMessage {...carbonFootprintMessages.carbonImpactNoData} />;
}

function isPrescriptionAvailable(currentVariant: SpecProps['currentVariant'], productType: Product['productType']) {
  return productType === 'frame' && !isKidsGlasses(currentVariant) && !currentVariant.availability.isOffTheShelf;
}
// row config data
function getPrescriptionRow(
  currentVariant: SpecProps['currentVariant'],
  productType: SpecProps['product']['productType']
) {
  // show prescription row only for frame product type (incl. kids and ski googles) and sports glasses (accessories)
  if (productType !== 'frame' && !isSportsGlasses(currentVariant)) return null;

  return {
    title: <FormattedMessage {...tableMessages.prescription} />,
    content: isPrescriptionAvailable(currentVariant, productType) ? (
      <FormattedMessage {...tableMessages.available} />
    ) : (
      <FormattedMessage {...tableMessages.notAvailable} />
    )
  };
}

function getLensRow(currentVariant: SpecProps['currentVariant'], productType: SpecProps['product']['productType']) {
  const hasAntiScratch = productType === 'frame' && !isRawCollection(currentVariant) && !isSkiGoggles(currentVariant);
  const includeThinLenses = productType === 'frame' && !isSunny(currentVariant);

  if (!includeThinLenses && !hasAntiScratch) return null;

  return {
    title: <FormattedMessage {...tableMessages.lens} />,
    content: (
      <>
        {includeThinLenses && (
          <Typography>
            <FormattedMessage {...productUspsMessages.superThinLenses} />
          </Typography>
        )}
        {hasAntiScratch && (
          <Typography>
            <FormattedMessage {...productUspsMessages.antiScratch} />
          </Typography>
        )}
      </>
    )
  };
}

function useIncludesRow(currentVariant: SpecProps['currentVariant'], productType: SpecProps['product']['productType']) {
  const hasCaseAndCloth = productType === 'frame' && !isKidsGlasses(currentVariant) && !isSkiGoggles(currentVariant);
  const hasFlexibleHinges = isKidsGlasses(currentVariant);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  if (!hasCaseAndCloth && !hasFlexibleHinges) return null;

  return {
    title: <FormattedMessage {...tableMessages.includes} />,
    content: (
      <>
        {hasCaseAndCloth && (
          <Typography>
            <FormattedMessage
              {...productUspsMessages.caseAndCloth}
              values={{
                caseAndClothLink: (
                  <Button onClick={() => setIsModalOpen(true)} variant='link' style={{ textAlign: 'left' }}>
                    <Typography variant='bodyM' as='span'>
                      <FormattedMessage {...productUspsMessages.caseAndClothLink} />
                    </Typography>
                  </Button>
                )
              }}
            />
          </Typography>
        )}
        {hasFlexibleHinges && (
          <Typography>
            <FormattedMessage {...productUspsMessages.flexHingesKids} />
          </Typography>
        )}
      </>
    ),
    modal: hasCaseAndCloth && isModalOpen && <CaseModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
  };
}

function getAgeRow(currentVariant: SpecProps['currentVariant']) {
  if (!isKidsGlasses(currentVariant)) return null;

  return {
    title: <FormattedMessage {...tableMessages.age} />,
    content: <FormattedMessage {...productUspsMessages.kidsAgeBracketTag} />
  };
}

function getMaterialRow(currentVariant: SpecProps['currentVariant']) {
  let hardCodedMaterial: string;

  if (isSkiGoggles(currentVariant)) {
    const bioSkus = ['eddie-kalamata-sun', 'eddie-lavender-sun', 'eddie-silver-sun'];
    if (bioSkus.includes(currentVariant.sku)) {
      hardCodedMaterial = 'materialListBioSkiGoggles';
    } else {
      hardCodedMaterial = 'materialListSkiGoggles';
    }
  }

  if (isSportsGlasses(currentVariant)) {
    hardCodedMaterial = 'materialListSportsGlasses';
  }

  if (hardCodedMaterial) {
    return {
      title: <FormattedMessage {...tableMessages.material} />,
      content: (
        <FormattedMessage
          key={hardCodedMaterial}
          values={{
            li: chunks => (
              <Typography variant='bodyM' as='li' style={{ listStyleType: 'none' }}>
                {chunks}
              </Typography>
            )
          }}
          {...productUspsMessages[hardCodedMaterial]}
        />
      )
    };
  }

  const isRecycledHardCase = currentVariant.sku.startsWith('hardcase-rpmma-');

  if (isRecycledHardCase) {
    return {
      title: <FormattedMessage {...tableMessages.material} />,
      content: (
        <>
          <FormattedMessage {...messages.hardCaseMaterialsDemoLensesDescription} />
          <br />
          <FormattedMessage
            key={hardCodedMaterial}
            values={{
              li: chunks => <li style={{ marginLeft: '20px' }}>{chunks}</li>,
              ul: chunks => <ul style={{ margin: '0px', padding: '0px' }}>{chunks}</ul>
            }}
            {...messages.hardCaseMaterialsList}
          />
        </>
      )
    };
  }

  const materials = getProductMaterials(currentVariant);

  if (materials.length === 0) return null;

  return {
    title: <FormattedMessage {...tableMessages.material} />,
    content: materials.map((material, index) => {
      return (
        <span key={index}>
          {material}
          {index === materials.length - 2 ? ' & ' : !(index === materials.length - 1) && ', '}
        </span>
      );
    })
  };
}

function getColorwayRow(currentVariant: SpecProps['currentVariant']) {
  if (!currentVariant.displayAttributes.color) return null;

  return {
    title: <FormattedMessage {...tableMessages.color} />,
    content: currentVariant.displayAttributes.color
  };
}

function getShapeRow(currentVariant: SpecProps['currentVariant']) {
  const { shapes } = currentVariant.filterOptions;

  if (shapes?.length === 0) return null;

  return {
    title: <FormattedMessage {...tableMessages.shape} />,
    content: currentVariant.filterOptions.shapes.map((shape, index) => {
      const { shapes } = currentVariant.filterOptions;
      return (
        <span key={shape}>
          {shapeMessages[shape] ? <FormattedMessage {...shapeMessages[shape]} /> : shape}
          {index === shapes.length - 2 ? ' & ' : !(index === shapes.length - 1) && ', '}
        </span>
      );
    })
  };
}

function getSizeRow(currentVariant: SpecProps['currentVariant'], product: SpecProps['product']) {
  const frameWidth = product.productType === 'frame' && getProductWidth(currentVariant);
  const availableSizes = getAvailableSizes(product, currentVariant);

  if (!frameWidth && availableSizes?.length <= 0) return null;

  return {
    title: <FormattedMessage {...tableMessages.size} />,
    content: (
      <>
        {frameWidth && (
          <Typography>
            <FormattedMessage {...tableMessages.frameWidth} values={{ width: frameWidth }} />
          </Typography>
        )}
        {availableSizes?.length > 0 && (
          <Typography>
            <FormattedMessage
              {...productUspsMessages.alsoAvailable}
              values={{
                availableSizes: availableSizes.map((sizeMap: FamilyVariant, index) => (
                  <>
                    <Link href={sizeMap.sku} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                      {sizeMap.size} <FormattedMessage {...productUspsMessages.fit} />
                    </Link>
                    {index === availableSizes.length - 2 ? ' & ' : !(index === availableSizes.length - 1) && ', '}
                  </>
                ))
              }}
            />
          </Typography>
        )}
      </>
    )
  };
}

function getMeasurementsRows(currentVariant: SpecProps['currentVariant']) {
  if (!currentVariant.displayAttributes.dimensions) return null;

  const measurements = getMeasurements(currentVariant.displayAttributes.dimensions);

  const lensWidthRow = measurements.lensWidth && {
    title: <FormattedMessage {...tableMessages.lensWidth} />,
    content: measurements.lensWidth
  };
  const bridgeWidthRow = measurements.bridgeWidth && {
    title: <FormattedMessage {...tableMessages.bridgeWidth} />,
    content: measurements.bridgeWidth
  };
  const templeLengthRow = measurements.templeLength && {
    title: <FormattedMessage {...tableMessages.templeLength} />,
    content: measurements.templeLength
  };

  return [lensWidthRow, bridgeWidthRow, templeLengthRow].filter(Boolean);
}

function getCarbonFootprintRow(
  currentVariant: SpecProps['currentVariant'],
  productType: SpecProps['product']['productType']
) {
  const carbonFootprint = getCarbonFootprint(currentVariant, productType);

  if (carbonFootprint)
    return {
      title: <FormattedMessage {...tableMessages.carbonImpact} />,
      content: carbonFootprint
    };
}

function useGetSpecs(currentVariant: SpecProps['currentVariant'], product: SpecProps['product']) {
  return [
    getPrescriptionRow(currentVariant, product.productType),
    getLensRow(currentVariant, product.productType),
    getAgeRow(currentVariant),
    useIncludesRow(currentVariant, product.productType),
    getMaterialRow(currentVariant),
    getColorwayRow(currentVariant),
    getShapeRow(currentVariant),
    getSizeRow(currentVariant, product),
    getMeasurementsRows(currentVariant),
    getCarbonFootprintRow(currentVariant, product.productType)
  ]
    .filter(Boolean)
    .flatMap(spec => spec);
}

function ProductSpecificationsTableRow(spec: {
  title: React.JSX.Element;
  content: string | React.JSX.Element | React.JSX.Element[];
  modal?: React.JSX.Element;
}) {
  return (
    <tr>
      <th>
        <Typography variant='bodyM' fontWeight='regular'>
          {spec.title}
        </Typography>
      </th>
      <td>
        <Typography variant='bodyM' as='div'>
          {spec.content}
          {spec.modal}
        </Typography>
      </td>
    </tr>
  );
}

const INITIAL_DISPLAY_ROWS = 4;

export default function ProductSpecificationsTable({ currentVariant, product, specificationsRef }: SpecProps) {
  const specs = useGetSpecs(currentVariant, product);
  const [toggle, setToggle] = useState<'collapsed' | 'expanded'>('collapsed');

  function handleShowToggle() {
    setToggle(toggle => {
      return toggle === 'collapsed' ? 'expanded' : 'collapsed';
    });

    specificationsRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  if (specs.length === 0) return null;

  return (
    <div ref={specificationsRef}>
      <Styles.SectionHeading>
        <FormattedMessage {...messages.productSpecifications} />
      </Styles.SectionHeading>
      <Table>
        <tbody>
          {specs.map((spec, index) => {
            if (toggle === 'collapsed' && specs.indexOf(spec) >= INITIAL_DISPLAY_ROWS) return null;
            return <React.Fragment key={index}>{ProductSpecificationsTableRow(spec)}</React.Fragment>;
          })}
        </tbody>
      </Table>
      {specs.length > INITIAL_DISPLAY_ROWS && (
        <ShowMoreContainer>
          <Button variant='outlined' size='small' onClick={handleShowToggle}>
            {toggle === 'collapsed' ? (
              <FormattedMessage {...globalMessages.showAll} />
            ) : (
              <FormattedMessage {...globalMessages.showLess} />
            )}
          </Button>
        </ShowMoreContainer>
      )}
    </div>
  );
}
